import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Container, Img, Typography } from 'components'
import { IMAGEN_UBICACION } from '../assets'

// Marker component
const Marker = ({ address }) => {
  //CAMBIAR IMAGEN A ICON (POR ALGUNA RAZON EL COMPONENTE ICON NO FUNCIONA, NO SE VE)
  let addressText = null
  if (address && typeof address === `string`) {
    addressText = address.split(`, `)[0]
  }
  return (
    <Container width="100px">
      <Img
        maxHeight="2rem"
        src={IMAGEN_UBICACION}
        maxWidth={{ _: `20px`, md: `10%` }}
        marginLeft="-15px"
        marginTop="-20px"
      />
      {addressText ? <Typography>{addressText}</Typography> : null}
    </Container>
  )
}

function LocationPicker({
  height,
  width,
  margin,
  defaultCenter,
  center,
  defaultZoom,
  handleClick,
  handleChange,
  ...props
}) {
  return (
    // Important! Always set the container height explicitly
    <Container height="50vh" width="100%">
      <GoogleMapReact
        bootstrapURLKeys={{ key: `AIzaSyApxhYkZmGn46PVevQ7DKEKvfzWp9bXsNo` }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={handleClick}
        onChange={handleChange}
        {...props}
      >
        <Marker
          lat={center.lat}
          lng={center.lng}
          text="Mi ubicacion"
          address={center.address}
        />
      </GoogleMapReact>
    </Container>
  )
}

export default LocationPicker
