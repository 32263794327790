import React from 'react'
import moment from 'moment-timezone'
import { Container, Typography, Img } from 'components'
import { useCorporation } from 'context'
import { clean as cleanRut, format as formatRut } from 'rut.js'
import styled from 'styled-components'

const TicketContainer = styled.div`
  position: relative;
  max-width: 400px;
  min-width: 312px;
  min-height: 366px;
  max-height: 500px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 180px;
  border: 1px #ededed solid;
  border-bottom: 0;
  background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="none" stroke="%23ededed" stroke-width="4" vector-effect="non-scaling-stroke"/></svg>');
  background-position: bottom left;
  background-size: 10% auto;
  background-repeat: repeat-x;
`

function IssuedTicket({ ticket }) {
  const {
    lineName,
    ticket: { identifier, ticketNumber, patientRut, patientRutDv, createdAt },
    waitingPeople,
  } = ticket
  const { colorLogoUrl } = useCorporation()
  const rut = formatRut(cleanRut(`${patientRut}${patientRutDv}`))

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginTop="5"
    >
      <Container
        width="100%"
        paddingBottom="2"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          color="primary.1"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
        >
          Hora validada exitosamente
        </Typography>
        <Typography
          color="primary.1"
          fontSize={{ _: 5, lg: 8 }}
          marginBottom={{ _: 3, lg: 4 }}
        >
          Su número de atención es
        </Typography>
      </Container>
      <Container>
        <TicketContainer>
          <Container
            alignItems="center"
            justifyContent="center"
            marginBottom="3"
          >
            <Img maxHeight="4rem" src={colorLogoUrl} />
          </Container>
          <Container border="1px solid #000000" width="87%" marginBottom="2" />
          <Typography fontWeight="bold" fontSize="4" marginTop="2">
            {lineName}
          </Typography>
          <Typography fontWeight="bold" fontSize="80px">
            {identifier}
            {ticketNumber}
          </Typography>
          <Typography fontWeight="bold" fontSize="4" paddingBottom="3">
            {rut}
          </Typography>
          <Container border="1px solid #000000" width="87%" marginBottom="2" />
          <Typography fontSize="0" marginBottom="3" marginTop="1">
            Emisión: {moment(createdAt).format(`HH:mm DD/MM/YYYY`)}
          </Typography>
        </TicketContainer>
      </Container>
      <Typography textAlign="center" width="50%" fontSize="0" marginTop="9">
        {waitingPeople !== 0
          ? `Hay ${waitingPeople} personas antes que ti en la fila `
          : null}
        {` `}
      </Typography>
    </Container>
  )
}

export default IssuedTicket
