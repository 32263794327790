import { format, validate, clean } from 'rut.js'
import API from 'config/apiAgenda'

export const formatRut = rut => {
  if (rut.length !== 0) {
    return format(rut)
  }
  return rut
}

export const isValid = rut => {
  const cleanRut = clean(rut)
  return validate(cleanRut) && cleanRut.length > 7 && cleanRut.length < 10
}

export const cleanRut = rut => {
  const cleanedRut = clean(rut)
  const mainRut = [...cleanedRut].splice(0, cleanedRut.length - 1).join(``)
  const dv = [...clean(rut)].pop()
  return [parseInt(mainRut, 10), dv]
}

export async function validationByCoporation({
  corporationId,
  wholeRut,
  unitId,
  lineId,
  raw = false,
  isReservation = true,
}) {
  const [rut, dv] = format(wholeRut).split(`-`)
  const payload = {
    rut: parseInt(clean(rut), 10),
    dv,
    corporationId,
  }
  switch (corporationId) {
    case 1: {
      const neighbor = await API.getNeighborData({
        unitId,
        lineId,
        isReservation,
        rut: clean(wholeRut),
      })
      if (raw) {
        return neighbor
      }
      const {
        patientApartment,
        patientFirstName,
        patientLastName,
        patientMail,
        patientStreet,
        patientStreetNumber,
      } = neighbor
      return {
        rut,
        dv,
        firstName: patientFirstName,
        lastName: patientLastName,
        email: patientMail,
        street: patientStreet,
        streetNumber: patientStreetNumber,
        apartment: patientApartment,
      }
    }

    default: {
      const data = await API.validatePatient(payload)
      return data
    }
  }
}
