import React from 'react'
import { Container } from 'components'
import { useStateWithMerge } from 'hooks'
import ValidateReservation from './ValidateReservation'
import ListOfReservations from './ListOfReservations'
import IssuedTicket from './IssuedTicket'

function getInitialState() {
  return {
    reservations: [],
    rut: ``,
    reservationDate: null,
    ticket: null,
    isLoading: false,
  }
}

function GenerateTicketWithRut({
  match: {
    params: { unitId },
  },
}) {
  const [state, setState] = useStateWithMerge(getInitialState)

  return (
    <Container
      flexDirection="column"
      width="100%"
      alignItems="center"
      paddingY="1em"
      isLoading={state.isLoading}
    >
      {state.ticket == null ? (
        state.reservations.length > 0 ? (
          <ListOfReservations {...state} setState={setState} />
        ) : (
          <ValidateReservation {...state} setState={setState} unitId={unitId} />
        )
      ) : (
        <IssuedTicket ticket={state.ticket} />
      )}
    </Container>
  )
}

export default GenerateTicketWithRut
