import { useState, useEffect } from 'react'
import { getGeocode } from 'use-places-autocomplete'

const defaultLocation = { lat: -33.4513, lng: -70.6653, address: null }

const useCurrentLocation = (
  options = {},
  defaultLocationOnError = defaultLocation,
) => {
  const [location, setLocation] = useState(defaultLocationOnError)
  const [error, setError] = useState()

  // Success handler
  const handleSuccess = pos => {
    const { latitude: lat, longitude: lng } = pos.coords
    setLocation({
      lat,
      lng,
    })
  }

  // Error handler
  const handleError = error => {
    setError(error.message)
  }

  useEffect(() => {
    async function findDefaultAddress() {
      const { lat, lng } = location
      const matchAddress = await getGeocode({
        location: { lat, lng },
      })
      setLocation({
        lat,
        lng,
        address: matchAddress[0].formatted_address,
      })
    }
    findDefaultAddress()
  }, [location.lat, location.lng])

  useEffect(() => {
    const { geolocation } = navigator
    // If the geolocation is not defined in the used browser we handle it as an error
    if (!geolocation) {
      setError(`Geolocalizacion no soportada.`)
      return
    }

    // Call Geolocation HTML API
    geolocation.getCurrentPosition(handleSuccess, handleError, options)
  }, [options])

  return { location, error }
}

export default useCurrentLocation
