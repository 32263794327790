/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { useState, useEffect, useCallback } from 'react'
import { validate as regexValidator } from 'utils'
import { clean, validate as rutValidator } from 'rut.js'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import useOnClickOutside from 'react-cool-onclickoutside'
// eslint-disable-next-line import/no-cycle
import { usePrevious } from 'hooks'

/**
 * stateSchema = {
 *  field: defaultValue,
 * ...
 * }
 *
 * validationSchema = {
 *  field: {
 *    isActive: boolean,
 *    isRequired: boolean,
 *    error: string,
 *    validator?: {
 *      regEx: expresion regular para evaluar campo,
 *      error: string,
 *    }
 *  }
 * }
 *
 * callback: Función de confirmación del formulario
 */

function getInitialState(stateSchema) {
  const errors = {}
  Object.keys(stateSchema).forEach(key => (errors[key] = null))
  return { values: stateSchema, errors }
}

function useForm({
  stateSchema,
  validationSchema = {},
  callback,
  sideEffects = [],
  extraProps,
}) {
  const [state, setState] = useState(getInitialState(stateSchema))
  const [canSubmit, setCanSubmit] = useState(false)
  const { values, errors } = state
  const prevValues = usePrevious(values)
  // Autocompletado
  const {
    suggestions: addressSuggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { componentRestrictions: { country: [`cl`, `mx`] } },
    debounce: 300,
  })

  const addressRef = useOnClickOutside(() => {
    setState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        street: prevState.values.address,
      },
    }))
    clearSuggestions()
  })

  async function addressSelect({ description, structured_formatting: data }) {
    setValue(description, false)
    clearSuggestions()
    try {
      const geoCode = await getGeocode({ address: description })
      const { lat, lng } = await getLatLng(geoCode[0])
      const streetNumber = data.main_text.match(/\d+/g)
      const communeString = data.secondary_text.split(`, `)
      const streetData = {}
      if (streetNumber) {
        streetData.street = data.main_text.replace(` ${streetNumber[0]}`, ``)
        streetData.streetNumber = streetNumber[0]
      }
      setState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          googleAddress: description,
          address: description,
          ...streetData,
          communeString,
          latitude: lat,
          longitude: lng,
        },
      }))
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: `Se ha producido un error al ingresar la dirección`,
        },
      }))
    }
  }

  //selecciona la dirección guardada en la DB
  useEffect(() => {
    async function getDefaultAddress({ lat, lng }) {
      try {
        const data = await getGeocode({ location: { lat, lng } })
        const streetNumber = data[0].formatted_address.match(/\d+/g)
        const addressData = data[0].formatted_address.split(`, `)
        const street = addressData.splice(0, 1)[0]
        const communeString = addressData
        const streetData = {}
        if (streetNumber) {
          streetData.street = street.replace(` ${streetNumber[0]}`, ``)
          streetData.streetNumber = streetNumber[0]
        }
        setState(prevState => ({
          ...prevState,
          values: {
            ...prevState.values,
            googleAddress: data[0].formatted_address,
            address: data[0].formatted_address,
            ...streetData,
            communeString,
            latitude: lat,
            longitude: lng,
          },
        }))
      } catch (e) {
        setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            address: `Se ha producido un error al ingresar la dirección`,
          },
        }))
      }
    }
    if (stateSchema.latitude && stateSchema.longitude) {
      getDefaultAddress({
        lat: stateSchema.latitude,
        lng: stateSchema.longitude,
      })
    }
  }, [])

  // useEffect custom
  const conditions = sideEffects.map(e => e.condition)
  useEffect(() => {
    if (prevValues) {
      sideEffects.forEach(e => {
        if (prevValues[e.condition] != values[e.condition]) {
          e.fn(values[e.condition])
        }
      })
    }
  }, [conditions])

  // effect especifico para validacion de comunas
  const communes = extraProps ? extraProps.communes : undefined
  useEffect(() => {
    if (
      communes &&
      validationSchema.communeId &&
      validationSchema.communeId.isRequired &&
      values.communeId
    ) {
      const belongsToCommunes = extraProps.communes.some(
        commune => commune.id === values.communeId,
      )
      setState(prevState => ({
        values: {
          ...prevState.values,
          communeId: belongsToCommunes ? values.communeId : null,
        },
        errors: {
          ...prevState.errors,
          communeId: belongsToCommunes
            ? null
            : validationSchema.communeId.error,
        },
      }))
    }
  }, [communes])

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cache the function and avoid intensive
  // memory leak in every component re-render
  const hasErrorInState = useCallback(() => {
    let input = null
    const hasError = Object.keys(validationSchema).some(key => {
      const { isRequired, isActive = true } = validationSchema[key]
      const value = values[key]
      const error = errors[key]
      input = key
      return (
        (isActive &&
          isRequired &&
          (value === null || value === undefined || value === ``)) ||
        error
      )
    })
    return hasError && input
  }, [values, errors, validationSchema])

  // Evalua si se puede enviar el formulario
  useEffect(() => {
    setCanSubmit(!hasErrorInState())
  }, [values, errors, validationSchema])

  useEffect(() => {
    if (values.address === `` || !values.googleAddress) {
      setState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          latitude: null,
          longitude: null,
        },
      }))
    }
  }, [values.address])

  // Used to handle changes in all inputs
  const handleChange = useCallback(
    (value, name) => {
      let error = ``
      if (validationSchema[name]) {
        if (validationSchema[name].type === `address`) {
          setValue(value)
        }
        if (
          validationSchema[name].isRequired &&
          (value === null || value === undefined || value === ``)
        ) {
          error = validationSchema[name].error
        }

        if (
          validationSchema[name].validator !== null &&
          typeof validationSchema[name].validator === `object`
        ) {
          if (value && !validationSchema[name].validator.regEx.test(value)) {
            error = validationSchema[name].validator.error
          }
        } else if (value) {
          //validadores por defecto
          if (
            [`email`, `patientMail`].includes(name) &&
            !regexValidator(value, `email`)
          ) {
            error = validationSchema[name].error
          }

          if (
            [`phone`, `patientPhone`].includes(name) &&
            value !== `` &&
            !regexValidator(value, `phone`)
          ) {
            error = validationSchema[name].error
          }
          if (
            [`rut`, `patientRut`, `aditionalRut`].includes(name) &&
            value !== `` &&
            !rutValidator(clean(value))
          ) {
            error = validationSchema[name].error
          }
        }
      }
      if (error) {
        setCanSubmit(false)
      } else {
        setCanSubmit(true)
      }
      setState(prevState => ({
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          [name]: error,
        },
      }))
    },
    [validationSchema],
  )

  // Capa de validacion adicional cuando el checkbox esta presente
  // en el formulario
  useEffect(() => {
    const checkboxValue = state?.values?.customCheckbox
    if (checkboxValue != null) {
      if (checkboxValue === true && !hasErrorInState()) {
        // Cuando es true, y no hay errores, habilita el submit
        setCanSubmit(true)
      } else {
        // Cuando es false, deshabilita el submit
        setCanSubmit(false)
      }
    }
  }, [validationSchema])

  const handleSubmit = useCallback(() => {
    const inputWithError = hasErrorInState()
    if (inputWithError) {
      const { error } = validationSchema[inputWithError]
      setState(prevState => ({
        values: prevState.values,
        errors: {
          ...prevState.errors,
          [inputWithError]: error,
        },
      }))
    } else if (Object.entries(values).length > 0) {
      callback(values)
    }
  }, [values, errors])

  return {
    values,
    errors,
    canSubmit,
    handleChange,
    handleSubmit,
    addressSuggestions,
    clearSuggestions,
    addressSelect,
    addressRef,
  }
}

export default useForm
