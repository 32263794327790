/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { Container, Typography, Icon, Divider, Button } from 'components'
import { Popover } from 'antd'
import { useIO } from 'hooks'
import API from 'config/apiAgenda'
import moment from 'moment-timezone'
import { useAlert } from 'context'
import 'antd/es/popover/style/index.css'

function isNowWithinLineSchedule(line) {
  const now = moment()
  //Si no tienes configuracion dinamica aplicar misma regla
  if (line.mobileSchedule == null) {
    return now.isBetween(
      moment(line.mobileInitTime, `HH:mm:ss`),
      moment(line.mobileEndTime, `HH:mm:ss`),
    )
  } else {
    //Si tiene buscar la config del dia actual
    const schedule = line.mobileSchedule[now.day()]
    //Y retornar true si el dia actual esta abierto y si NOW esta dentro
    //De al menos uno de los bloques horarios
    return (
      schedule.open &&
      schedule.timeBlocks.some(({ startTime, endTime }) => {
        const start = moment(startTime, `HH:mm`)
        const end = moment(endTime, `HH:mm`)
        return now.isBetween(start, end)
      })
    )
  }
}

function Line({ history, match, location }) {
  const [line, setLine] = useState({})
  const { setAlert } = useAlert()

  useIO({
    emitPayload: match.params.lineId,
    subscribeEvent: `lineUpdated`,
    subscribeEventHandler: updateLine,
  })
  async function updateLine() {
    try {
      const line = await API.getLine(match.params.lineId)
      setLine(line)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    async function getLine() {
      try {
        const line = await API.getLine(match.params.lineId)
        if (line.publicConfig) {
          setAlert(line.publicConfig.alert)
        }
        const { lastSync } = await API.getUnit(line.unitId)
        setLine({ ...line, lastSync })
      } catch (e) {
        console.log(e)
      }
    }
    getLine()
  }, [])

  const {
    id,
    hasSchedule,
    hasPickup,
    mobile,
    waitingPeople,
    lastSync,
    lineTypeId,
    concurrentCapacity,
    concurrentValidAttentions,
  } = line
  const isAttending = mobile && isNowWithinLineSchedule(line)
  const isOffline = moment().diff(moment(lastSync), `minutes`) > 10
  const isOverCapacity =
    lineTypeId === 4 ? concurrentValidAttentions >= concurrentCapacity : false
  const isAvailable = isAttending && !isOffline && !isOverCapacity
  return (
    <Container width="100%" padding="0" justifyContent="center">
      <Container
        width="100%"
        flexDirection={[`column`, `column`, `row`, `row`]}
        paddingX={[`4`, `4`, `0`, `0`]}
        justifyContent="center"
      >
        <Typography
          marginTop={3}
          fontWeight="bold"
          fontSize={{ _: 5, lg: 8, xl: 8 }}
          color="primary.0"
        >
          Seleccione una opción:
        </Typography>
      </Container>
      {mobile ? (
        
        <>
          <Container
            width="100%"
            marginBottom={3}
            flexDirection="column"
            alignItems="center"
            maxWidth="700px"
            position="relative"
            padding={[`4`, `4`, `2`, `2`]}
            paddingTop={[`4`, `4`, `4`, `4`]}
          >
            <Button
              position="relative"
              onClick={() => history.push(`/fila/${id}/obtener-ticket`)}
              padding="3"
              borderRadius="5px"
              hoverProps={
                isAvailable && {
                  backgroundColor: `primary.2@0.9`,
                }
              }
              backgroundColor={!isAvailable ? `grey.2` : `primary.2`}
              withShadow={!isAvailable}
              disabled={!isAvailable}
              width={[`100%`, `100%`, `80%`, `80%`]}
              textDecoration="none !important"
              marginBottom={[`3`, `3`, `3`, `3`]}
            >
              {!isAvailable && (
                <Popover
                  content={
                    <Typography>
                      {isOffline
                        ? `El servicio se encuentra sin conexión`
                        : `El servicio está fuera de horario`}
                    </Typography>
                  }
                  trigger="click"
                >
                  <Icon
                    fontSize="4"
                    icon="info"
                    position="absolute"
                    top="3"
                    right="4"
                  />
                </Popover>
              )}
              <Typography
                fontSize={{ _: 2, lg: 4 }}
                color={isOffline ? `grey.3` : `white`}
              >
                Pedir ticket AHORA
              </Typography>
            </Button>

            <Container flexDirection="column" alignItems="center">
              <Typography
                color="primary.2"
                fontSize={{ _: 2, lg: 4 }}
                textAlign="center"
                margin={2}
              >
                <Icon icon="users" marginX={1} />
                {lineTypeId !== 4
                  ? `${
                      waitingPeople == null ? `Calculando...` : waitingPeople
                    } personas esperando`
                  : `Fila control de aforo: ${concurrentValidAttentions}/${concurrentCapacity} tickets activos`}
              </Typography>
            </Container>
          </Container>
          <Container width="100%" marginY={2}>
            <Divider width="90%" />
          </Container>
        </>
      ) : null}
      {hasSchedule ? (
        <Container
          width="100%"
          marginTop={3}
          flexDirection="column"
          alignItems="center"
          maxWidth="700px"
          position="relative"
          padding={[`4`, `4`, `0`, `0`]}
          paddingTop="0! important"
          fontSize={[`2`, `3`, `5`, `5`]}
        >
          <Button
            onClick={() =>
              history.push({
                pathname: `/fila/${id}/reserva`,
                state: { line },
              })
            }
            borderRadius="5px"
            backgroundColor="primary.2"
            hoverProps={{ backgroundColor: `primary.2@0.9` }}
            withShadow
            width={[`100%`, `100%`, `80%`, `80%`]}
            padding={[`3`, `3`, `3`, `3`]}
            marginBottom={[`3`, `3`, `3`, `3`]}
            textDecoration="none !important"
          >
            <Typography fontSize={[`2`, `3`, `5`, `5`]} color="white">
              {hasPickup ? `Solicitar retiro programado` : `Agendar atención`}
            </Typography>
          </Button>
        </Container>
      ) : null}
    </Container>
  )
}

export default Line
