import React from 'react'

import { Container, Typography, Divider, Input } from 'components'

const CustomComment = ({ customComment, setCustomComment }) => {
  return (
    <Container width="100%">
      <Divider border="1px solid" borderColor="grey.7" />
      <Typography marginTop="4" width="100%" color="rgba(0, 0, 0, 0.80)">
        Su opinión es es muy importante para nosotros.
      </Typography>
      <Typography marginBottom="4" width="100%" color="rgba(0, 0, 0, 0.80)">
        A continuación si desea puede dejarnos un cometario.
      </Typography>
      <Input
        type="textArea"
        width="100%"
        placeholder="Escribir un comentario"
        rows={4}
        maxLength={200}
        value={customComment}
        onChange={setCustomComment}
      />
    </Container>
  )
}

export default CustomComment
