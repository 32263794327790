import React from 'react'
import moment from 'moment-timezone'
import {
  CustomInput,
  CustomSelect,
  InputPassword,
  CustomRadio,
  Checkbox,
  InputNumber,
  DatePicker,
  TextAreaInput,
} from './styles'
import Container from './Container'
import Typography from './Typography'
import Anchor from './Anchor'
import Button from './Button'

const inputType = {
  text({ value, onChange, placeholder, name, disabled, inputType, ...props }) {
    return (
      <CustomInput
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
        type={inputType}
      />
    )
  },
  textArea({
    value,
    onChange,
    placeholder,
    name,
    disabled,
    inputType,
    ...props
  }) {
    return (
      <TextAreaInput
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
        type={inputType}
      />
    )
  },
  password({ value, onChange, placeholder, name, disabled, ...props }) {
    return (
      <InputPassword
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
      />
    )
  },
  select({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    labelKey = `label`,
    valueKey = `value`,
    ...props
  }) {
    return (
      <CustomSelect
        onChange={value => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value || undefined}
        key={name}
        {...props}
      >
        {options.map(option => (
          <CustomSelect.Option key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </CustomSelect.Option>
        ))}
      </CustomSelect>
    )
  },
  number({ value, name, onChange, addonBefore = null, ...props }) {
    return (
      <Container alignItems="stretch" width="100%">
        {addonBefore ? (
          <Container
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray.0"
            borderRadius="4px 0px 0px 4px"
            borderY="1px solid"
            borderLeft="1px solid"
            borderColor="gray.1"
            width="20%"
          >
            <Typography color="gray.4">{addonBefore}</Typography>
          </Container>
        ) : null}
        <InputNumber
          value={value}
          onChange={value => onChange(value, name)}
          name={name}
          width={addonBefore ? `80% !important` : null}
          borderRadius={addonBefore ? `0px 4px 4px 0px` : null}
          {...props}
        />
      </Container>
    )
  },
  radio({ value, onChange, placeholder, options = [], name, ...props }) {
    return (
      <CustomRadio.Group
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.map(option => (
          <CustomRadio key={option.value} value={option.value}>
            {option.label}
          </CustomRadio>
        ))}
      </CustomRadio.Group>
    )
  },
  radioButton({ value, onChange, placeholder, options = [], name, ...props }) {
    return (
      <CustomRadio.Group
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.map(option => (
          <CustomRadio.Button
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </CustomRadio.Button>
        ))}
      </CustomRadio.Group>
    )
  },
  checkbox({ value, onChange, name, ...props }) {
    return (
      <Checkbox
        checked={value}
        name={name}
        onChange={({ target: { checked } }) => onChange(checked, name)}
        {...props}
      />
    )
  },
  address({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    addressSelect,
    addressRef,
    ...props
  }) {
    const { status, data } = options
    return (
      <>
        <CustomInput
          name={name}
          onChange={({ target: { name, value } }) => onChange(value, name)}
          placeholder={placeholder || undefined}
          value={value}
          key={name}
          {...props}
        />
        {status === `OK` && (
          <Container ref={addressRef} position="relative" width="100%">
            <Container
              position="absolute"
              top={-12}
              backgroundColor="white"
              zIndex="999"
              border="1px solid"
              borderColor="grey.2"
              width="100%"
            >
              {data.map(suggestion => {
                const {
                  id,
                  structured_formatting: {
                    main_text: mainText,
                    secondary_text: secondaryText,
                  },
                } = suggestion
                return (
                  <Container
                    width="100%"
                    paddingX="4"
                    paddingY="3"
                    key={id}
                    onClick={() => addressSelect(suggestion)}
                    alignItems="center"
                    hoverProps={{ backgroundColor: `grey.3` }}
                  >
                    <Typography fontSize="5" marginRight="1">
                      {mainText}
                    </Typography>
                    <Typography color="grey.1" fontSize="3">
                      {secondaryText}
                    </Typography>
                  </Container>
                )
              })}
            </Container>
          </Container>
        )}
      </>
    )
  },
  date({ value, onChange, placeholder, name, format, ...props }) {
    const dateFormatList = [`DD/MM/YYYY`, `DD/MM/YY`]
    return (
      <DatePicker
        value={value ? moment(value, format) : null}
        key={name}
        onChange={date => onChange(date, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        format={format || dateFormatList}
        {...props}
      />
    )
  },
  file({ isDragActive, getRootProps, getInputProps, value, onChange }) {
    return (
      <Container flexDirection="column" width="100%">
        {value ? (
          <Container
            borderRadius="5px"
            width="100%"
            border="1px dashed"
            borderColor="grey.2"
            padding="3"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color="primary.2" fontSize="large" fontWeight="bold">
              {value?.name}
            </Typography>
            <Button
              onClick={() => onChange(null, `file`)}
              backgroundColor="transparent"
              color="primary.2"
              textDecoration="underline"
              marginRight="2"
              cursor="pointer"
            >
              descartar
            </Button>
          </Container>
        ) : (
          <Container
            {...getRootProps()}
            border="1px dashed"
            borderColor="grey.1"
            borderRadius="5px"
            padding="3"
            height="85%"
            justifyContent="center"
            alignItems="center"
            width="100% !important"
            backgroundColor="grey.4"
          >
            <input {...getInputProps()} />
            <Typography
              color="primary.2"
              fontSize="large"
              fontWeight="semi-bold"
            >
              {isDragActive
                ? `Suelta el archivo aquí`
                : `Haz click o arrastra el archivo`}
            </Typography>
          </Container>
        )}
      </Container>
    )
  },
}

const Input = React.forwardRef(
  (
    {
      type = `text`,
      label = null,
      theme,
      error,
      margin = null,
      color,
      width = null,
      onChange = () => null,
      hasLink,
      url,
      onClickOutside,
      ...props
    },
    ref,
  ) => {
    const Component = inputType[type]
    const isCheckbox = type === `checkbox`
    const orderToVisualizate = isCheckbox
      ? [`input`, `label`]
      : [`label`, `input`]

    const components = {
      input: (
        <Component
          error={error}
          onChange={onChange}
          color={color}
          type={type}
          {...props}
        />
      ),
      label: (
        <Label
          url={hasLink ? url : null}
          marginLeft={isCheckbox ? `2` : `0`}
          fontSize={isCheckbox ? `4` : `2`}
          label={label}
          color={color}
        />
      ),
    }

    return (
      <Container width={width} margin={margin} marginY={error ? `1` : `0`}>
        <Container flexDirection={isCheckbox ? `row` : `column`} width="100%">
          {orderToVisualizate.map(c => components[c])}
        </Container>
        {error && (
          <Typography fontSize="1" color="error">
            {error}
          </Typography>
        )}
      </Container>
    )
  },
)

// Para evitar que el inter pida displayName en Input
// Se necesita que el componente Input esté declarado de esa forma
// para utlizar forwardRef
Input.displayName = `Input`

const Label = ({ label, color, url, ...props }) => {
  if (!label) {
    return null
  }
  return (
    <Typography marginBottom="2" color={color} {...props}>
      {url ? (
        <Anchor
          fontSize="1"
          color="primary.2"
          textDecoration="underline"
          href={url}
          target="_blank"
          rel="nofollow noopener noreferrer"
          marginTop="1"
        >
          {label}
        </Anchor>
      ) : (
        label
      )}
    </Typography>
  )
}

export default Input
