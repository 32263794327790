import styled from 'styled-components'
import {
  compose,
  system,
  layout,
  space,
  color,
  border,
  flexbox,
  typography,
  buttonStyle,
} from 'styled-system'
import { Link } from 'react-router-dom'
import { colorsWithOpacity } from '../utils'

const LinkStyled = styled(Link)`
  ${compose(
    layout,
    space,
    flexbox,
    color,
    border,
    buttonStyle,
    colorsWithOpacity,
    system({ boxShadow: true }),
  )}
  ${({ withShadow = false, background = null }) => `
  ${
    withShadow
      ? `box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.275623) !important;`
      : ``
  }
  ${background ? `background: ${background} !important` : ``}
  `}
  & > * {
    ${compose(
      color,
      typography,
      system({ textDecoration: true }),
    )}
  }

  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
    & > * {
      ${({ hoverProps, ...props }) =>
        compose(
          colorsWithOpacity,
          typography,
          system({ textDecoration: true }),
        )({ ...props, ...hoverProps })}
    }
  }
`
LinkStyled.defaultProps = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: ` center`,
  marginBottom: `1em`,
  hoverProps: null,
  maxWidth: `100%`,
  textDecoration: `underline`,
}

export default LinkStyled
