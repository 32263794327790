const colors = {
  primary: [`#005483`, `#066CB5`, `#066CB5`, `#FFF`],
  secundary: [`#005483`, `#00C1D0`, `#066CB5`, `#E9ECF5`, `#193B59`],
  tertiary: [`#2E4664`, `#892680`, `#E9CADF`, `#666988`, `#15385A`],
  grey: [
    `#4A4A4A`,
    `#93C6EA`,
    `#C0BFC0`,
    `#E3E3E3`,
    `#EEEDEC`,
    `#868484`,
    `#DDD`,
    `#DEDCDC`,
  ],
  success: `#328A0E`,
  warning: `#F5A723`,
  error: `#D0021B`,
  info: `#129DC8`,
}

const gradients = [
  `linear-gradient(to right, ${colors.secundary[0]}, ${colors.secundary[2]})`,
]

//                  0, 1,  2,  3,  4,  5,  6,  7,  8,  9,  10
const fontSizes = [13, 14, 16, 17, 18, 19, 20, 22, 24, 32, 42]

//             0, 1, 2,  3,  4 , 5 , 6,   7   ,   9    ,   10     , 11
const space = [
  0,
  4,
  8,
  16,
  24,
  32,
  64,
  `1rem`,
  `1.5rem`,
  `2rem`,
  `2.5rem`,
  `3rem`,
]

const breakpoints = [`319px`, `767px`, `1023px`, `1919px`]

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export default {
  colors,
  gradients,
  space,
  fontSizes,
  breakpoints,
}
