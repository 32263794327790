import React, { useContext, useState } from 'react'

const AlertContext = React.createContext()

const initialState = {
  text: null,
  color: null,
  isClosable: null,
  isActive: null,
}

function AlertProvider({ children }) {
  const [alert, setAlert] = useState(initialState)
  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
    </AlertContext.Provider>
  )
}

function useAlert() {
  const context = useContext(AlertContext)
  if (!context) {
    throw new Error(`useAlert must be used within a AlertProvider`)
  }
  return context
}

export { AlertProvider, useAlert }
