import styled from 'styled-components'
import { color, border, layout, space } from 'styled-system'

const Divider = styled.hr`
  ${color}
  ${border}
  ${layout}
  ${space}
`

Divider.defaultProps = {
  color: `none`,
  border: `1px solid #2A4D69`,
  width: `100%`,
  opacity: `none`,
  margin: `7px 0`,
}

export default Divider
