import 'antd/es/modal/style/index.css'

import Modal from 'antd/es/modal'
import styled from 'styled-components'
import { compose, layout } from 'styled-system'

export const ModalContainer = styled(Modal)`

  ${({
    theme,
    backgroundColor = `white`,
    titleColor = theme.colors.primary[2],
    closeColor = `white`,
    height = `auto`,
    overflowY = `visible`,
  }) => `
  }
  &  {
    position: fixed !important;
    top: 1 !important;
    right: 0 !important;
    bottom 0 !important;
    left: 0 !important;
  }
  & .ant-modal-header {
    background-color: ${backgroundColor};
    border-radius: 0;
    padding: 30px 24px;
    & .ant-modal-title {
      color: ${titleColor} !important;
    }
  }
  & .ant-modal-body {
    height: ${height};
    overflow-y: ${overflowY};
  }
  & .ant-modal-close-x {
    color: ${closeColor} !important;
  }
  `}
  ${({ modalProps, ...props }) => compose(layout)({ ...props, ...modalProps })}
`
