import React, { useEffect, useState } from 'react'
import { Container, Alert } from 'components'
import Header from './Header'
import BackButton from './BackButton'

const urlsWithoutBackButton = [`/servicios/home`]

const npsEmailBasePath = `/nps/emailResponse`

function Layout({ children, ...props }) {
  const [hasBackButton, setHasBackButton] = useState(false)

  useEffect(() => {
    const [, pathname] = window.location.hash.split(`#`)
    if (
      urlsWithoutBackButton.includes(pathname) ||
      pathname?.includes(npsEmailBasePath)
    ) {
      setHasBackButton(false)
    } else {
      setHasBackButton(true)
    }
  }, [window.location.hash])

  return (
    <Container
      alignContent="space-between"
      height="100vh"
      width="100%"
      {...props}
    >
      <Container width="100%" maxHeight="90%" overflowY="auto">
        <Header />
        {hasBackButton ? <BackButton /> : null}
        {children}
      </Container>
      <Alert />
    </Container>
  )
}

export default Layout
