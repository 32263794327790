/* eslint-disable max-lines-per-function */
import React, { useRef, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStateWithMerge, useIO } from 'hooks'
import { Container, Typography, Icon, Button } from 'components'
import API from 'config/apiAgenda'
import moment from 'moment-timezone'
import { navigateToUrl } from 'utils'

function Alert({ description, message, type = `info`, onClick }) {
  const colorsByType = {
    info: { backgroundColor: `info`, icon: `info`, color: `white` },
    warning: { backgroundColor: `tertiary.1`, icon: `warning`, color: `white` },
  }
  const { backgroundColor, icon, color } = colorsByType[type]
  return (
    <Container
      padding="3"
      width="100%"
      backgroundColor={backgroundColor}
      display="grid"
      gridTemplateColumn="10% 90%"
      onClick={onClick}
    >
      <Icon
        gridColumn={1}
        fontSize="5"
        color={color}
        marginRight="2"
        icon={icon}
      />
      <Container gridColumn={2} flexDirection="column">
        <Typography marginBottom="1" fontSize="5" color={color}>
          {message}
        </Typography>
        <Typography fontSize="1" color={color}>
          {description}
        </Typography>
      </Container>
    </Container>
  )
}

const getInitialState = _ => ({
  wasAttended: false,
  nowAttending: false,
  lineName: null,
  attention: null,
  ticket: null,
  peopleAhead: null,
  estimatedWait: null,
  alert: false,
  moduleInfo: null,
  loading: false,
  error: null,
})

function TicketVirtual() {
  const history = useHistory()
  const queueRef = useRef(null)
  const { lineId, ticket: ticketIdentifier } = useParams()
  const [state, setState] = useStateWithMerge(getInitialState)
  const {
    peopleAhead,
    attention,
    ticket,
    lineName,
    loading,
    wasAttended,
    nowAttending,
    error,
  } = state

  function queueManager({ attentionsQueued: attentions, currentAttentionId }) {
    const queuePosition = attentions.findIndex(
      att => att.id == currentAttentionId,
    )
    queueRef.current = queuePosition
    return {
      peopleAhead: queuePosition,
    }
  }
  function handleQueue(queue) {
    return setState(prevState =>
      queueManager({ ...queue, currentAttentionId: prevState.attention.id }),
    )
  }
  useIO({
    emitPayload: lineId,
    subscribeEvent: `lineUpdated`,
    subscribeEventHandler: handleQueue,
  })

  const itsTime = nowAttending || peopleAhead < 0
  useEffect(() => {
    async function getTicket() {
      setState({ loading: true })
      try {
        const { attention, ...ticket } = await API.findTicket({
          lineId,
          ticketIdentifier,
        })
        setState({
          attention,
          ...ticket,
        })
      } catch (e) {
        const error =
          typeof e === `string`
            ? e
            : `No se pudo obtener la información del ticket`
        setState({ error })
      } finally {
        setState({ loading: false })
      }
    }
    getTicket()
  }, [itsTime])
  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems={{ md: `center` }}
      isLoading={loading}
    >
      {itsTime && attention.module ? (
        <Alert
          message="Es su turno"
          description={`Diríjase a ${attention.module.description} ${attention.module.identifier}`}
        />
      ) : null}
      {wasAttended || error ? (
        <Alert
          type="warning"
          message={`Este ticket ${
            wasAttended ? `ya fue atendido` : `no existe`
          }`}
          description="Presione este mensaje para obtener un nuevo ticket"
          onClick={_ => history.push(`/fila/${lineId}/obtener-ticket`)}
        />
      ) : null}
      <Container
        width="100%"
        padding="4"
        flexDirection="column"
        alignItems="center"
      >
        <Container
          width={{ _: `100%`, md: `35%` }}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="3"
        >
          <Container padding="2" width="100%" justifyContent="center">
            {/* NÚMERO DE ATENCIÓN */}
            <Typography
              width="100%"
              textAlign="center"
              color="primary.2"
              fontSize="4"
              fontWeight="bold"
              paddingBottom="2"
            >
              {`NÚMERO DE ATENCIÓN`}
            </Typography>
            <Typography
              width="100%"
              textAlign="center"
              color="primary.2"
              fontSize="10"
              fontWeight="bold"
              paddingBottom="4"
            >
              {ticketIdentifier}
            </Typography>

            {error ? null : (
              <Container
                flexDirection="column"
                borderTop="1px solid"
                borderBottom="1px solid"
                borderColor="grey.0"
                width="100%"
                paddingY="4"
              >
                {/* SERVICIO */}
                <Container justifyContent="flex-start" marginBottom="2">
                  <Typography fontWeight="bold" fontSize="1" marginRight="2">
                    {`Servicio:`}
                  </Typography>

                  <Typography fontSize="1">{`${lineName}`}</Typography>
                </Container>

                {/* FECHA */}
                <Container justifyContent="flex-start">
                  <Typography
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="1"
                    marginRight="2"
                  >
                    Fecha:
                  </Typography>

                  <Typography>{moment().format(`LL`)}</Typography>
                </Container>
              </Container>
            )}

            {!itsTime && !error && (
              <>
                {/* ESTADO DE FILA */}
                <Typography
                  marginTop="4"
                  paddingBottom="2"
                  fontSize="2"
                  fontWeight="bold"
                  color="primary.2"
                >
                  Estado de fila actual
                </Typography>
                <Container
                  width="100%"
                  marginY="2"
                  padding="4"
                  backgroundColor="grey.4"
                  border="0.5px solid"
                  borderColor="grey.2"
                  borderRadius="6px"
                  flexDirection="column"
                  alignItems="start"
                >
                  <Typography
                    color="grey.0"
                    fontSize="4"
                    // Se oculta divider
                    // borderBottom="0.5px solid"
                    // borderColor="grey.2"
                  >
                    <Icon marginRight="4" icon="users" />
                    {peopleAhead} persona(s) esperando antes que tú
                  </Typography>
                  {/* Se oculta tiemoi de espera */}
                  {/* <Typography color="grey.0" fontSize="4" paddingTop="3">
                  <Icon marginRight="4" icon="clock" /> {estimatedWait} min de
                  espera
                </Typography> */}
                </Container>
              </>
            )}
          </Container>
        </Container>
        <Button
          backgroundColor="primary.2"
          borderBottom="3px solid"
          borderColor="tertiary.2"
          width={{ _: `100%`, lg: `35%` }}
          padding="4"
          borderRadius={6}
          marginTop="4"
          marginBottom="4"
          onClick={() => navigateToUrl(`/unit-${ticket.unitId}/visor`)}
        >
          <Typography fontSize={2} fontWeight="500" letterSpacing={0.5}>
            Ver todas las filas
          </Typography>
        </Button>
      </Container>
    </Container>
  )
}

export default TicketVirtual
