import React from 'react'

import { Container, Icon, Typography } from 'components'

const SurveyHeader = ({ surveyStatus }) => {
  return (
    <Container
      height="232px"
      width="100%"
      backgroundColor="secundary.4"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      textAlign="center"
    >
      <Container width="100%" justifyContent="center">
        <Container className="fa-stack fa-3x survey-header-circle-icon">
          <Icon
            color="rgba(70, 106, 134, 0.77)"
            icon="circle"
            className="fa-stack-2x"
          />
          <Icon
            icon={surveyStatus === `expired` ? `regularClock` : `thumbsUp`}
            className="fa-stack-1x"
            color="primary.3"
          />
        </Container>
      </Container>
      <Typography fontSize="8" color="primary.3">
        Encuesta de Satisfacción
      </Typography>
    </Container>
  )
}

export default SurveyHeader
