import React from 'react'

import { Container, Typography } from 'components'
import { questionCaptionColor, options } from './utils'

const Question = ({ question, index = 0, answerQuestion, currentAnswer }) => {
  return (
    <Container width="100%" justifyContent="center" marginBottom="4">
      <Container width="100%" justifyContent="center" marginBottom="10px">
        <Typography textAlign="center">
          ¿Recomendaría usted a un pariente o amigo {question}?
        </Typography>
      </Container>
      <Container width={{ _: `100%`, md: `80%` }} justifyContent="center">
        <Container
          width="100%"
          justifyContent="space-between"
          marginBottom="10px"
        >
          {options.map(option => (
            <Container
              key={`question-${index}-survey-option-${option}`}
              width="28px"
              height="28px"
              borderRadius="5px"
              border="1px solid"
              borderColor={currentAnswer === option ? `tertiary.3` : `grey.6`}
              backgroundColor={
                currentAnswer === option ? `secundary.3` : `primary.3`
              }
              justifyContent="center"
              alignContent="center"
              style={{ cursor: `pointer` }}
              onClick={() => answerQuestion(index, option)}
              hoverProps={{
                backgroundColor: `secundary.3`,
                borderColor: `grey.6`,
              }}
            >
              <Typography fontSize="1" color="tertiary.3">
                {option}
              </Typography>
            </Container>
          ))}
        </Container>
        <Container width="100%" justifyContent="space-between">
          <Typography color={questionCaptionColor} fontSize="10px">
            Poco probable
          </Typography>
          <Typography color={questionCaptionColor} fontSize="10px">
            Muy probable
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}

export default Question
