import React from 'react'
import { ModalContainer } from './styles'

const WindowModal = ({
  onClose,
  onConfirm,
  isVisible,
  title,
  height,
  children,
  overflowY,
  color,
  ...props
}) => {
  return isVisible ? (
    <ModalContainer
      visible
      onOk={onConfirm}
      onCancel={onClose}
      maskClosable={false}
      title={title}
      footer={null}
      height={height}
      closeColor={color}
      overflowY={overflowY}
      {...props}
    >
      {children}
    </ModalContainer>
  ) : null
}

export default WindowModal
