import React from 'react'
import moment from 'moment-timezone'
import message from 'antd/es/message'
import { Container, Typography, Button, Input } from 'components'
import API from 'config/apiAgenda'
import { useCorporation } from 'context'
import { isValid, formatRut } from 'utils'

function formatReservations({ rawReservations }) {
  const { reservations, reservationsById } = rawReservations
  reservations.forEach(reservationId => {
    const {
      line: {
        id: lineId,
        name: lineName,
        unit: {
          name: unitName,
          division: { name: divisionName, address },
        },
      },
    } = reservationsById[reservationId]
    reservationsById[reservationId] = {
      ...reservationsById[reservationId],
      lineId,
      address: `${divisionName} ${address}`,
      attention: `${unitName} | ${lineName}`,
      isReservation: true,
    }
  })
  return { reservations, reservationsById }
}

function ReservationsFinder({ rut, unitId, setState }) {
  const { id: corporationId } = useCorporation()
  async function searchReservations() {
    try {
      const rawReservations = await API.findReservations({
        rut,
        corporationId,
        unitId,
        statuses: [2, 3, 4, 5],
        startDate: moment()
          .startOf(`day`)
          .format(`YYYY-MM-DD HH:mm`),
      })
      if (rawReservations.reservations.length === 0) {
        message.error(`No se encontraron horas futuras asociadas a este rut.`)
      } else {
        setState(formatReservations({ rawReservations }))
      }
    } catch (e) {
      const error = typeof e == `string` ? e : `No se encontraron reservas`
      message.error(error)
    }
  }
  const canSubmit = rut != null && isValid(rut)
  return (
    <Container
      width="100%"
      maxWidth="700px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography
        color="primary.0"
        width="100%"
        fontSize={{ _: 5, lg: 8 }}
        fontWeight="bold"
      >
        Buscador de reservas:
      </Typography>
      <Container width="100%" margin="2rem 0">
        <Typography fontSize="3" color="grey.0">
          Ingrese su rut:
        </Typography>
        <Input
          name="rut"
          value={rut}
          width="100%"
          padding="1.3rem"
          borderRadius="5px"
          fontSize="2"
          margin="1% 0"
          onChange={value => setState({ rut: formatRut(value) })}
        />
        <Typography color="primary.2" fontSize="0" fontStyle="italic">
          * Escriba rut con el que agendó hora
        </Typography>
      </Container>
      <Button
        padding={3}
        borderRadius="5px"
        width={{ _: `90%`, lg: `50%` }}
        onClick={searchReservations}
        disabled={!canSubmit}
        backgroundColor={canSubmit ? `primary.2` : `grey.2`}
        hoverProps={canSubmit ? { backgroundColor: `secundary.0` } : null}
        withShadow={canSubmit}
      >
        <Typography
          fontSize="3"
          color={canSubmit ? `white` : `grey.1`}
          textDecoration="underline"
        >
          Buscar
        </Typography>
      </Button>
    </Container>
  )
}
export default ReservationsFinder
