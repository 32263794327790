/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { format, clean } from 'rut.js'
import { useStateWithMerge, useCurrentLocation } from 'hooks'
import { useCorporation, useTheme, useAlert } from 'context'
import { Container, Typography, Icon, Button, Form } from 'components'
import { Modal, notification } from 'antd'
import API from 'config/apiAgenda'
import message from 'antd/es/message'
import 'antd/es/modal/style/index.css'
import LocationConfirmationScreen from '../DeliveryLocationValidation'
import SuccessScreen from './SuccessScreen'
import AttachRecipe from './AttachRecipe'

const getInitialState = defaultValues => ({
  validPatientId: null,
  wholeRut: ``,
  firstName: ``,
  lastName: ``,
  phone: ``,
  email: ``,
  street: ``,
  streetNumber: ``,
  apartment: ``,
  communes: [],
  communeId: defaultValues ? defaultValues.communeId : undefined,
  regionId: defaultValues ? defaultValues.regionId : 7, //Por defecto la RM
  regions: [],
  healthInstitutions: [],
  medicalSpecialties: [],
  medicalSpecialtyId: undefined,
  treatingDoctor: undefined,
  healthInstitutionId: undefined,
  isLoading: false,
  success: false,
  error: null,
  prescriptions: [{ imageUrl: null }],
  withoutPrescription: false,
  errors: {},
  formSchema: {},
  unit: null,
  locationConfirmationStep: false,
  patient: null,
})

const geolocationOptions = {
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
}

function DeliveryRequest({ history, match }) {
  const {
    theme: { defaultValues },
  } = useTheme()
  const { setAlert } = useAlert()
  const {
    location: defaultLocation,
    error: geolocalitationError,
  } = useCurrentLocation(geolocationOptions)
  const [state, setState] = useStateWithMerge(getInitialState(defaultValues))
  const { id: corporationId } = useCorporation()
  const {
    validPatientId,
    communeId,
    prescriptions,
    healthInstitutionId,
    withoutPrescription,
    isLoading,
    success,
    error,
    regionId,
    formSchema,
    communes,
    regions,
    healthInstitutions,
    medicalSpecialties,
    medicalSpecialtyId,
    treatingDoctor,
    locationConfirmationStep,
    patient,
  } = state
  function setPatientField(value, field) {
    return setState({ [field]: value })
  }

  async function generateDeliveryRequest() {
    setState({ isLoading: true, error: null })
    try {
      const cleanedPrescriptions = prescriptions.filter(
        ({ imageUrl }) => imageUrl !== null,
      )
      const createdPrescriptions =
        cleanedPrescriptions.length > 0
          ? await Promise.all(
              cleanedPrescriptions.map(({ imageUrl, identifier = null }) => {
                return API.createPrescription({
                  validPatientId,
                  imageUrl,
                  identifier,
                  hasDeliveryIntegrationFlow:
                    state.unit.hasDeliveryIntegrationFlow,
                })
              }),
            )
          : [
              await API.createPrescription({
                validPatientId,
                imageUrl: null,
              }),
            ]
      const payload = {
        unitId: match.params.unitId,
        validPatientId,
        healthInstitutionId: healthInstitutionId || null,
        medicalSpecialtyId,
        treatingDoctor,
        hasDeliveryIntegrationFlow: state.unit.hasDeliveryIntegrationFlow,
        hasPrescriptionValidation:
          state.unit.pharmacyConfig &&
          state.unit.pharmacyConfig.hasPrescriptionValidation
            ? state.unit.pharmacyConfig.hasPrescriptionValidation
            : false,
        validatePatient: state.unit.validatePatient,
        prescriptions: createdPrescriptions,
      }
      await API.generateDeliveryRequest(payload)
      setState({
        success: true,
        isLoading: false,
        error: null,
      })
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `Ha ocurrido un error, asegurese de rellenar todos los datos requeridos e intente nuevamente.`
      message.error(error)
      setState({
        ...state,
        isLoading: false,
        error,
      })
    }
  }
  function validateLocation(patient) {
    if (!patient.latitude || !patient.longitude) {
      setState({
        ...state,
        patient,
        locationConfirmationStep: true,
      })
    } else {
      generatePatient(patient)
    }
  }

  async function generatePatient({
    rut: wholeRut,
    firstName,
    lastName,
    phone,
    email,
    street,
    streetNumber,
    apartment,
    communeId,
    healthInstitutionId,
    medicalSpecialtyId,
    treatingDoctor,
    latitude,
    longitude,
    customField1,
    customField2,
    customCheckbox,
    communeString,
    prescriptionIds,
  }) {
    setState({ isLoading: true, error: null })
    try {
      const [rut, dv] = format(wholeRut).split(`-`)
      const payload = {
        rut: parseInt(clean(rut), 10),
        dv,
        corporationId,
        firstName,
        lastName,
        phone,
        email,
        street,
        streetNumber,
        apartment,
        communeId,
        latitude,
        longitude,
        customField1,
        customField2,
        customCheckbox,
        communeString,
        isValid: false,
      }
      const { validPatient } = await API.createValidPatient(payload)
      await API.verifyDelivery({
        rut: parseInt(clean(rut), 10),
        dv,
        unitId: match.params.unitId,
        medicalSpecialtyId,
      })
      setState(prevState => {
        const prescriptions = prescriptionIds
          ? prescriptionIds.map(identifier => ({
              identifier,
              imageUrl: null,
            }))
          : prevState.prescriptions
        return {
          validPatientId: validPatient.id,
          prescriptions,
          hasPrescriptions: !!(prescriptionIds && prescriptionIds.length > 0),
          healthInstitutionId,
          medicalSpecialtyId,
          treatingDoctor,
          isLoading: false,
          error: null,
          locationConfirmationStep: false,
        }
      })
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `Ha ocurrido un error, asegurese de rellenar todos los datos requeridos e intente nuevamente.`
      message.error(error)
      setState({
        ...state,
        isLoading: false,
        error,
      })
    }
  }

  useEffect(() => {
    async function getCommunes() {
      try {
        setState({ isLoading: true, error: null })
        const communes = await API.getCommunes(regionId)
        const belongsToCommunes = communes.some(
          commune => commune.id === communeId,
        )
        setState({
          communes,
          communeId: belongsToCommunes ? communeId : undefined,
          isLoading: false,
        })
      } catch (e) {
        setState({
          ...state,
          isLoading: false,
          error: `Ha ocurrido un error al obtener las comunas`,
        })
      }
    }
    getCommunes()
  }, [regionId])

  useEffect(() => {
    async function getInitialData() {
      setState({ isLoading: true, error: null })
      try {
        const { medicalSpecialties } = await API.getMedicalSpecialties({
          unitId: match.params.unitId,
        })
        const regions = await API.getRegions()
        const { state } = history.location
        const unit = await API.getUnit(match.params.unitId)
        if (unit.publicConfig) {
          setAlert(unit.publicConfig.alert)
        }
        setState({
          medicalSpecialties,
          formSchema: unit.publicForms.withoutValidatePatient,
          regions,
          isLoading: false,
          unit,
        })
      } catch (e) {
        setState({
          ...state,
          isLoading: false,
          error: `Ha ocurrido un error al obtener las instituciones`,
        })
      }
    }
    getInitialData()
  }, [])

  useEffect(() => {
    setState({ healthInstitutions: [] })
    async function gethealthInstitutions() {
      try {
        setState({ isLoading: true, error: null })
        const healthInstitutions = await API.getHealthInstitutions({
          unitId: match.params.unitId,
          communeId,
        })
        if (healthInstitutions.length == 0) {
          notification.error({
            message: `La comuna seleccionada no cuenta con instituciones`,
          })
        }
        setState({ healthInstitutions, isLoading: false })
      } catch (e) {
        setState({
          ...state,
          isLoading: false,
          error: `Ha ocurrido un error al obtener las instituciones`,
        })
      }
    }
    if (communeId) {
      gethealthInstitutions()
    }
  }, [communeId])

  const canSubmit =
    prescriptions.every(
      ({ imageUrl, isUploaded }) => imageUrl !== null && isUploaded,
    ) ||
    withoutPrescription ||
    locationConfirmationStep

  return success ? (
    <SuccessScreen history={history} {...state} />
  ) : locationConfirmationStep ? (
    <LocationConfirmationScreen
      history={history}
      patient={patient}
      canSubmit={canSubmit}
      defaultLocation={defaultLocation}
      handleSubmit={generatePatient}
      isLoading={isLoading}
    />
  ) : (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="center"
      marginY="3"
      paddingX={4}
    >
      <Container width="100%" flexDirection="column" alignItems="flex-start">
        <Typography
          width="100%"
          color="primary.0"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
          marginBottom={{ _: 3, lg: 4 }}
        >
          Solicitud de Entrega
        </Typography>
        <Typography
          width="100%"
          color="primary.0"
          fontSize={{ _: 3, lg: 5 }}
          fontWeight="200"
          marginBottom={{ _: 2, lg: 4 }}
        >
          Estimado Usuario, para poder realizar la entrega requerimos que nos
          entregue los siguientes datos:
        </Typography>
      </Container>
      <Container
        width="100%"
        maxWidth="700px"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        {validPatientId != null ? (
          <AttachRecipe
            {...state}
            setPatientField={setPatientField}
            setState={setState}
            corporationId={corporationId}
          />
        ) : (
          <Form
            formSchema={formSchema}
            extraProps={{
              communes,
              regions,
              healthInstitutions,
              medicalSpecialties,
            }}
            defaultValues={defaultValues}
            okText="Ir a subir receta"
            icon="chevronRight"
            onConfirm={validateLocation}
            isLoading={isLoading}
            sideEffects={[
              {
                condition: `regionId`,
                fn: regionId => setState({ regionId }),
              },
              {
                condition: `communeId`,
                fn: communeId => setState({ communeId }),
              },
            ]}
          />
        )}
        {error && (
          <Typography
            color="error"
            fontWeight="500"
            lineHeight="20px"
            marginRight="1"
            fontSize="1"
            marginTop="2"
          >
            {error}
          </Typography>
        )}
        {validPatientId && (
          <Button
            width={{ _: `100%`, md: `50%` }}
            hoverProps={canSubmit ? { backgroundColor: `primary.2@0.9` } : null}
            borderRadius="5px"
            margin="0 auto"
            marginY="3"
            padding="3"
            withShadow
            disabled={!canSubmit}
            backgroundColor={canSubmit ? `primary.2` : `grey.2`}
            onClick={canSubmit ? generateDeliveryRequest : null}
          >
            {isLoading ? (
              <Icon icon="loader" spin margin="0 auto" fontSize="8" />
            ) : (
              <Typography
                textDecoration="none"
                color={canSubmit ? `white` : `grey.3`}
                fontSize={[`2`, `3`, `5`, `8`, `8`]}
              >
                Solicitar entrega
                <Icon icon="chevronRight" marginLeft="2" />
              </Typography>
            )}
          </Button>
        )}
      </Container>
    </Container>
  )
}

export default DeliveryRequest
