import React from 'react'

import styled from 'styled-components'
import {
  compose,
  background,
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  grid,
  typography,
  system,
} from 'styled-system'

import { backgroundGradient, colorsWithOpacity } from 'utils'

import Icon from './Icon'
const Container = styled.div`
  ${compose(
    background,
    space,
    color,
    layout,
    flexbox,
    border,
    position,
    grid,
    typography,
    backgroundGradient,
    colorsWithOpacity,
    system({ textDecoration: true, textTransform: true }),
  )}
  ${({ withShadow = false, onClick = null }) => `
  ${withShadow ? `box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;` : ``}
  ${onClick == null ? `` : `  cursor: pointer`};
    & *{
      max-width: 100%;
    }
  `}
  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
  }
`

Container.defaultProps = {
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  maxWidth: `100%`,
}

const ContainerWrapper = React.forwardRef(
  (
    {
      isLoading = false,
      loaderSize = `4`,
      loaderColor = `white`,
      children,
      ...props
    },
    ref,
  ) => {
    const aditionalProps = isLoading
      ? { justifyContent: `center`, flexDirection: `row`, alignItems: `center` }
      : {}
    return (
      <Container ref={ref} {...props} {...aditionalProps}>
        {isLoading ? (
          <Icon icon="spinner" spin color={loaderColor} fontSize={loaderSize} />
        ) : (
          children
        )}
      </Container>
    )
  },
)

export default ContainerWrapper
