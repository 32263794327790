import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import GenerateTicketWithRut from 'screens/GenerateTicketWithRut'
import Layout from './Layout'
import GlobalStyle from './GlobalStyle'
import Services from '././screens/Services'
import HourPickerScreen from './screens/ReservationsPicker/ByTwoCalendars'
import ReservationsPickerByList from './screens/ReservationsPicker/ReservationsPickerByList'
import ReservationRequest from './screens/ReservationRequest'
import DeliveryRequest from './screens/DeliveryRequest'
import DeliveryRequestWithoutValidation from './screens/DeliveryRequestWithoutValidation'
import Line from './screens/Line'
import ScheduleAppointment from './screens/ScheduleAppointment'
import AppointmentHour from './screens/AppointmentHour'
import GenerateTicket from './screens/Ticket'
import TicketVirtual from './screens/Ticket/TicketVirtual'
import AppointmentRequest from './screens/AppointmentRequest'
import ValidateReservation from './screens/ValidateReservation'
import UnitVisor from './screens/UnitVisor'
import Confirmation from './screens/Confirmation'
import EmailSuccessScreen from './screens/EmailSuccessScreen'
import PatientUpdate from './screens/PatientUpdate'
import Nps from './screens/Nps'

const routes = [
  {
    key: 0,
    path: `/admin`,
    title: `Redireccionar admin`,
    component: () => {
      window.location.assign(`http://portal.saltala.com/`)
    },
  },
  {
    key: 1,
    path: `/servicios`,
    title: `Servicios disponibles`,
    component: Services,
    exact: false,
  },
  {
    key: 2,
    path: `/fila/:lineId`,
    title: `Opciones para fila.`,
    component: Line,
  },
  {
    key: 3,
    path: `/fila/:lineId/reserva`,
    title: `Horas Disponibles`,
    component: HourPickerScreen,
  },
  {
    key: 4,
    path: `/fila/:lineId/reserva/:reservationDate`,
    title: `Datos de quien reserva`,
    component: ReservationRequest,
  },
  {
    key: 5,
    path: `/entrega/:unitId`,
    title: `Solicitar entrega`,
    component: DeliveryRequest,
  },
  {
    key: 6,
    path: `/despachar/:unitId`,
    title: `Solicitar entrega`,
    component: DeliveryRequestWithoutValidation,
  },
  {
    key: 7,
    path: `/agenda/:scheduleUnitId/agendar`,
    title: `Agendar hora`,
    component: ScheduleAppointment,
  },
  {
    key: 8,
    path: `/agenda/:scheduleUnitId/agendar/:reservationDate`,
    title: `Confirmación de datos personales`,
    component: AppointmentRequest,
  },
  {
    key: 10,
    path: `/atenciones`,
    title: `Buscar Agenda`,
    component: AppointmentHour,
  },
  {
    key: 11,
    path: `/fila/:lineId/obtener-ticket`,
    title: `Atención AHORA`,
    component: GenerateTicket,
  },
  {
    key: 12,
    path: `/reservas/:unitId`,
    title: `Validar reservas`,
    component: ValidateReservation,
  },
  {
    key: 13,
    path: `/hora/:operation/:token`,
    title: `Confirmación de hora`,
    component: Confirmation,
  },
  {
    key: 14,
    path: `/:serviceKey/visor`,
    title: `Visor`,
    component: UnitVisor,
  },
  {
    key: 15,
    path: `/fila/:lineId/ticket/:ticket`,
    title: `Ticket Virtual`,
    component: TicketVirtual,
  },
  {
    key: 15,
    path: `/mailSuccess/:response/:scheduledAppointmentId/:token/:programProtocolId`,
    title: `Email Success Screen`,
    component: EmailSuccessScreen,
  },
  {
    key: 16,
    path: `/unidad/:unitId/:lineCategoryId`,
    title: `Horas Disponibles en unidad`,
    component: ReservationsPickerByList,
  },
  {
    key: 17,
    path: `/reservas/:unitId/obtener-ticket`,
    title: `Confirmación de reserva`,
    component: GenerateTicketWithRut,
  },
  {
    key: 18,
    path: `/actualizacionDatos`,
    title: `Actualización de datos`,
    component: PatientUpdate,
  },
  {
    key: 19,
    path: `/nps/emailResponse/:response/:npsAppointmentId/:token/:programProtocolId`,
    title: `NPS Screen`,
    component: Nps,
  },
]

function App() {
  return (
    <>
      <GlobalStyle />
      <HashRouter>
        <Switch>
          <Layout>
            {routes.map(({ path, component, exact = true }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                component={component}
              />
            ))}
            <Route exact path="/">
              <Redirect to={routes[1].path} />
            </Route>
          </Layout>
        </Switch>
      </HashRouter>
    </>
  )
}

export default App
