import React, { useState } from 'react'
import { useIO } from 'hooks'
import { Container, Typography, Icon } from 'components'

const getLastTicket = attentions => {
  if (attentions[0]) {
    const { identifier, ticketNumber } = attentions[0].ticket
    return `${identifier}${ticketNumber}`
  }
  return `-`
}

function Line({ id, name, attentions, setLastTicket }) {
  const [currentTicket, setCurrentTicket] = useState(getLastTicket(attentions))
  useIO({
    emitPayload: id,
    subscribeEvent: `lineUpdated`,
    subscribeEventHandler: ({ currentAttention }) => {
      let ticketDisplay = `Sin información`
      if (currentAttention && currentAttention.ticket) {
        const { ticketNumber, identifier } = currentAttention.ticket
        ticketDisplay = `${identifier}${ticketNumber}`
      }
      setCurrentTicket(ticketDisplay)
      setLastTicket(ticketDisplay)
    },
  })
  return (
    <Container
      backgroundColor="grey.4"
      border="1px solid"
      borderColor="grey.1@0.5"
      padding={2}
      width="100%"
      justifyContent="space-between"
      borderRadius={6}
      margin="auto"
    >
      <Icon icon="users" fontSize="2" marginLeft="1" color="grey.0" />
      <Typography
        width={{ _: `70%`, sm: `40%`, md: `60%` }}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontSize={1}
        color="grey.0"
        margin="auto"
        marginLeft="4"
      >
        {name}
      </Typography>
      <Typography fontSize={4} fontWeight="bold" color="grey.0" marginRight="5">
        {currentTicket}
      </Typography>
    </Container>
  )
}

export default Line
