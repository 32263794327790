/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notification } from 'antd'

import { Container } from 'components'
import API from 'config/apiAgenda'
import Survey from './Survey'
import {
  getDefaultAnswersObject,
  surveyStatusByError,
  defaultAnswer,
} from './utils'
import SurveyHeader from './SurveyHeader'

function Confirmation() {
  const [surveyData, setSurveyData] = useState({
    questions: [],
    showCustomQuestion: false,
    isDefaultSurvey: false,
    isLoading: true,
    surveyStatus: `pending`,
  })

  const [answers, setAnswers] = useState({})
  const [customComment, setCustomComment] = useState(``)
  const [npsSuccessMessagesConfig, setNpsSuccessMessagesConfig] = useState(null)

  const {
    questions,
    showCustomQuestion,
    isLoading,
    isDefaultSurvey,
    surveyStatus,
  } = surveyData

  const { response, npsAppointmentId, token, programProtocolId } = useParams()

  const getNpsQuestions = async () => {
    try {
      const {
        questions,
        customQuestion,
        defaultSurvey,
      } = await API.getNpsQuestions({
        token,
        programProtocolId,
        npsAppointmentId,
      })
      const defaultAnswers = getDefaultAnswersObject(questions)

      setAnswers(defaultAnswers)
      setSurveyData({
        ...surveyData,
        questions,
        showCustomQuestion: customQuestion,
        isLoading: false,
        isDefaultSurvey: defaultSurvey,
      })
    } catch (error) {
      const newSurveyStatus = surveyStatusByError[error]
        ? surveyStatusByError[error]
        : `error`

      setSurveyData({
        ...surveyData,
        questions,
        showCustomQuestion,
        isLoading: false,
        surveyStatus: newSurveyStatus,
      })
    }
  }

  const getNpsSuccessMessagesConfig = async () => {
    try {
      const {
        npsSuccessMessagesConfig: successMessages,
      } = await API.getNpsSuccessMessagesConfig({
        token,
        programProtocolId,
      })
      setNpsSuccessMessagesConfig(successMessages)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const answerQuestion = (questionIndex, answer) => {
    const newAnswers = { ...answers }
    newAnswers[questionIndex] = answer
    setAnswers(newAnswers)
  }

  const submitSurvey = async () => {
    try {
      setSurveyData({ ...surveyData, isLoading: true })
      const answersValues = Object.values(answers)

      if (answersValues.includes(defaultAnswer)) {
        throw new Error(`Debes responder todas las preguntas`)
      }

      const answersToSubmit = {}

      if (isDefaultSurvey) {
        /*
          Entre la encuesta dinamica y default se mantiene la misma estructura.
          Aqui como es el survey por default, el objeto answers tendra al menos
          1 entry, el cual se asigna a la propiedad response a enviar
        */
        answersToSubmit.response = answers[`1`]
      } else {
        Object.keys(answers).forEach(answerIndex => {
          answersToSubmit[`response${answerIndex}`] = answers[answerIndex]
        })
      }

      const payload = {
        ...answersToSubmit,
        customComment,
        token,
        npsAppointmentId,
        programProtocolId,
      }

      await API.receivedNpsResponse(token, payload)

      setSurveyData({ ...surveyData, isLoading: false, surveyStatus: `done` })
    } catch (error) {
      notification.error({
        message: error?.message ?? `Ha ocurrido un error inesperado`,
      })
      setSurveyData({ ...surveyData, isLoading: false })
    }
  }

  useEffect(() => {
    if (response === 2) {
      // TODO: esto se tomara en la tarjeta de diferenciar entre:
      // - No contestadas
      // - No quiso responder
      // Si se decidio no responder la encuesta NPS
      // No se deberian hacer mas contactos
    }

    getNpsQuestions()
    getNpsSuccessMessagesConfig()
  }, [])

  return (
    <Container width="100%" justifyContent="center">
      <Container padding="5" width="100%" justifyContent="center">
        <Container
          width={{ _: `100%`, md: `70%`, lg: `50%` }}
          justifyContent="center"
          backgroundColor="primary.3"
          style={{ 'box-shadow': `0px 4px 20px 0px rgba(0, 0, 0, 0.10)` }}
        >
          {isLoading ? (
            <Container
              marginTop="6"
              width="100%"
              isLoading={isLoading}
              loaderColor="primary.0"
              loaderSize="8"
            />
          ) : (
            <>
              <SurveyHeader surveyStatus={surveyStatus} />
              <Survey
                questions={questions}
                answers={answers}
                customComment={customComment}
                showCustomQuestion={showCustomQuestion}
                surveyStatus={surveyStatus}
                npsSuccessMessagesConfig={npsSuccessMessagesConfig}
                answerQuestion={answerQuestion}
                setCustomComment={setCustomComment}
                submitSurvey={submitSurvey}
              />
            </>
          )}
        </Container>
      </Container>
    </Container>
  )
}

export default Confirmation
