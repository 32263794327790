import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment-timezone'
import Modal from 'antd/es/modal'
import 'antd/es/button/style/index.css'
import { Button, Container, Icon, Input, Typography } from 'components'
import API from 'config/apiAgenda'

function AppointmentCancelation({ appointmentId, isReservation }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  async function onConfirm() {
    try {
      setLoading(true)
      if (isReservation) {
        await API.requestLineCancelation(appointmentId)
      } else {
        await API.requestAppointmentCancelation(appointmentId)
      }
      setSuccess(true)
      setLoading(false)
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `Ha ocurrido algo con su solicitud, vuelva a intentarlo.`
      setLoading(false)
      setError(error)
    }
  }

  return (
    <>
      <Button
        backgroundColor="primary.2"
        padding={{ _: 2, md: 3 }}
        borderRadius="6px"
        width={{ _: `100%`, md: `25%` }}
        onClick={() => setModalIsOpen(true)}
        hoverProps={{ backgroundColor: `primary.2@0.9` }}
      >
        <Typography fontSize={{ _: 1, lg: 3 }}>Solicitar anulación</Typography>
      </Button>
      <Modal
        title="Solicitud de anulación"
        centered
        visible={modalIsOpen}
        okType="primary"
        okText="Enviar solicitud"
        cancelText={success ? `Entendido` : `Volver atrás`}
        onOk={onConfirm}
        onCancel={() => setModalIsOpen(false)}
        okButtonProps={{ disabled: success }}
      >
        {loading ? (
          <Container width="100%" justifyContent="center" alignItems="center">
            <Icon icon="spinner" spin fontSize="7" />
          </Container>
        ) : success ? (
          <Typography fontStyle="italic" fontSize="2">
            La solicitud de cancelación ha sido enviada con éxito.
          </Typography>
        ) : (
          <>
            <Typography fontSize="2">
              Para cancelar, enviaremos un link al correo con el cual agendó
              esta cita.
            </Typography>
            <Typography fontSize="2">
              Siga las instrucciones en el correo para cancelar su cita.
            </Typography>
            {error ? <Typography color="error">{error}</Typography> : null}
          </>
        )}
      </Modal>
    </>
  )
}

function Appointment({
  reservationDate,
  address,
  attention,
  patientFirstName,
  patientLastName,
  patientMail,
  status,
  isReservation,
  rut,
  appointmentId,
}) {
  const appointmentStatuses = {
    2: `A cancelar`,
    3: `Agendada`,
    4: `Atendida`,
    5: `Ausente`,
    6: `Arribada`,
    7: `Iniciada`,
  }

  const reservationStatuses = {
    2: `Tomada`,
    3: `Confirmada`,
    4: `Anulada`,
    5: `Ejecutada`,
    6: `Cancelada`,
  }

  return (
    <Container
      width={{ _: 1, lg: `70%` }}
      withShadow
      marginBottom={3}
      padding={{ _: 3, lg: 5 }}
      borderRadius={5}
    >
      <Container justifyContent="space-between" width="100%">
        <Typography
          color="primary.2"
          fontWeight="bold"
          fontSize={{ _: 0, lg: 4 }}
        >
          <Icon icon="calendar" marginRight={1} />
          {moment(reservationDate).format(`LL`)}
        </Typography>
        <Typography
          color="primary.2"
          fontWeight="bold"
          fontSize={{ _: 0, lg: 4 }}
        >
          <Icon icon="clock" marginRight={1} />
          {moment(reservationDate).format(`LT`)}
          hrs.
        </Typography>
      </Container>
      <Container flexDirection="column">
        <Typography
          color="primary.2"
          fontWeight="bold"
          marginTop={3}
          marginBottom={{ _: 1, md: 3 }}
          fontSize={{ _: 1, md: 4 }}
        >
          {attention}
        </Typography>
        <Typography
          marginBottom={3}
          color="grey.0"
          fontWeight="100"
          fontSize={{ _: 1, md: 4 }}
        >
          {address}
        </Typography>
        <Typography
          marginBottom={3}
          color="grey.0"
          fontWeight="100"
          fontSize={{ _: 1, md: 4 }}
        >
          Hora agendada para: {patientFirstName} {patientLastName}, RUT: {rut}
        </Typography>
        <Typography color="grey.0" fontWeight="100" fontSize={{ _: 1, lg: 4 }}>
          {patientMail === `tarjetasincorreo@lascondes.cl`
            ? null
            : `Registro enviado al correo: ${patientMail}`}
        </Typography>
        <Typography
          color="grey.0"
          fontWeight="100"
          fontSize={{ _: 1, lg: 4 }}
          marginY={3}
        >
          Estado de la agenda:{` `}
          {isReservation
            ? reservationStatuses[status]
            : appointmentStatuses[status]}
        </Typography>
      </Container>
      <Container width="100%" justifyContent={{ _: `center`, md: `flex-end` }}>
        {moment(reservationDate).isAfter(moment()) && status !== 4 ? (
          <AppointmentCancelation
            appointmentId={appointmentId}
            isReservation={isReservation}
          />
        ) : null}
      </Container>
    </Container>
  )
}

const optionsByStatus = [
  { value: 2, label: `Futuras` },
  { value: 3, label: `Pasadas` },
  { value: [2, 3], label: `Todas` },
]

function AppointmentHour({ location: { state } }) {
  const [isLoading, setIsLoading] = useState(false)
  const [statusToShow, setStatusToShow] = useState(2)
  if (state) {
    const {
      data: { appointments, appointmentsById },
      rut,
    } = state

    const appointmentsFiltered = appointments.filter(appointmentId => {
      const appointment = appointmentsById[appointmentId]
      let shouldShowAppointment = false
      const currentMoment = moment()
      switch (statusToShow) {
        case 2:
          shouldShowAppointment = moment(appointment.reservationDate).isAfter(
            currentMoment,
          )
          break
        case 3:
          shouldShowAppointment = moment(appointment.reservationDate).isBefore(
            currentMoment,
          )
          break
        default:
          shouldShowAppointment = true
          break
      }
      return shouldShowAppointment
    })

    return (
      <Container
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        padding={{ _: 3, sm: 3, lg: 6 }}
      >
        <Container marginBottom="4" flexDirection="column">
          <Typography
            fontWeight="bold"
            fontSize={{ _: 5, lg: 8 }}
            color="primary.0"
          >
            Mis Agendas
          </Typography>
        </Container>
        <Container justifyContent="center" width="100%" isLoading={isLoading}>
          {appointments.length > 0 ? (
            <>
              <Container
                justifyContent="flex-start"
                width={{ lg: `70%`, sm: `100%`, _: `100%` }}
                marginBottom="3"
              >
                <Input
                  margin="1em 0"
                  type="radioButton"
                  value={statusToShow}
                  onChange={statusToShow => setStatusToShow(statusToShow)}
                  options={optionsByStatus}
                />
              </Container>
              {appointmentsFiltered.length <= 0 &&
              (statusToShow === 2 || statusToShow === 3) ? (
                <Container
                  width={{ _: 1, lg: `70%` }}
                  withShadow
                  marginBottom={3}
                  padding={{ _: 3, lg: 5 }}
                  borderRadius={6}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    color="primary.2"
                    fontSize={{ _: 3, lg: 8, xl: 9 }}
                  >
                    No tienes citas agendadas.
                  </Typography>
                </Container>
              ) : (
                appointmentsFiltered.map(appointmentId => {
                  const appointment = appointmentsById[appointmentId]
                  return (
                    <Appointment
                      key={appointmentId}
                      {...appointment}
                      appointmentId={appointmentId}
                      rut={rut}
                    />
                  )
                })
              )}
            </>
          ) : (
            <Container width="100%" justifyContent="center" alignItems="center">
              <Typography
                color="primary.2"
                fontSize={{ _: 7, lg: 8, xl: 9 }}
                marginTop={6}
              >
                Lo sentimos, no tienes citas agendadas.
              </Typography>
            </Container>
          )}
        </Container>
      </Container>
    )
  } else {
    return <Redirect to="/" />
  }
}

export default AppointmentHour
