/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
import {
  Container,
  Typography,
  Icon,
  HourPicker,
  Input,
  Button,
  Calendar,
} from 'components'
import API from 'config/apiAgenda'
import { useStateWithMerge, useQuery } from 'hooks'
import { useCorporation, useAlert } from 'context'
import { clean, format, validate } from 'rut.js'
import { validationByCoporation } from 'utils'

const hourButtonStyles = {
  active: {
    color: `secundary.1`,
    backgroundColor: `white`,
    border: `1px solid`,
    borderColor: `secundary.1`,
  },
  disabled: {
    backgroundColor: `grey.3`,
    color: `grey.1`,
    border: `1px solid`,
    borderColor: `white`,
    cursor: `not-allowed`,
  },
  selected: {
    backgroundColor: `secundary.2`,
    color: `white`,
    border: `1px solid`,
    borderColor: `transparent`,
    withShadow: true,
  },
}

function getMessage(corporationId) {
  switch (corporationId) {
    case 13:
      return `Lo sentimos, no hay horas disponibles en este momento. Le sugerimos intentar el día de mañana (Nuevos cupos se habilitan automáticamente cada día)`
    default:
      return `Lo sentimos, no hay horas disponibles en este momento.`
  }
}

// Trata la data para pasarselo al picker
function mapDataForLinePicker({ reservations, reservationsById }) {
  return reservations.map(reservation => {
    const { id, reservationDate: hour, statusCount } = reservationsById[
      reservation
    ]

    const active = statusCount.available > 0

    // status 1: disponible
    // status 2: tomada
    // status 3: confirmada
    // status 5; ejecutada
    return {
      id,
      hour,
      statusCount,
      active,
      styles: active ? hourButtonStyles.active : hourButtonStyles.disabled,
    }
  })
}

const initialState = ({ state }) => {
  const line = state ? state.line : {}
  return {
    error: null,
    isLoading: true,
    isCalendarLoading: true,
    line,
    nextAvailableDate: null,
    patient: null,
    reservations: [],
    reservationsById: {},
    selectedDate: null,
    wholeRut: ``,
    availableDays: [],
    startTime: null,
    endTime: null,
    mobileCalendarDisplay: `none`,
  }
}

function HourPickerScreen({ match, location }) {
  const [state, setState] = useStateWithMerge(initialState(location))
  const { id: corporationId } = useCorporation()
  const { setAlert } = useAlert()
  const query = useQuery()

  const {
    error,
    isLoading,
    isCalendarLoading,
    line,
    nextAvailableDate,
    patient,
    reservations,
    reservationsById,
    selectedDate,
    wholeRut,
    availableDays,
    mobileCalendarDisplay,
    startTime,
    endTime,
  } = state

  const hours = mapDataForLinePicker({ reservations, reservationsById })
  const lineId = match.params.lineId

  function getReservations({ date = startTime, isForward = true }) {
    return new Promise(async resolve => {
      try {
        setState({ isLoading: true, isCalendarLoading: true })

        let nextAvailableReservation

        if (isForward) {
          nextAvailableReservation = await API.getNextAvailableReservation({
            startTime: moment(date)
              .add(1, `days`)
              .startOf(`day`)
              .format(),
            endTime,
            lineId,
            corporationId,
            filter: query.get(`filter`),
          })
        }

        const nextDate = isForward
          ? nextAvailableReservation.reservation.reservationDate
          : date

        const {
          reservations = [],
          reservationsById = {},
        } = await API.getAvailableReservations({
          lineId,
          startTime: moment(nextDate).format(),
          endTime,
          statuses: [1, 2, 3, 5],
          groupHours: true,
        })

        const payload = {
          nextAvailableDate: moment(nextDate),
          reservations,
          reservationsById,
          isLoading: false,
          isCalendarLoading: false,
          error: null,
        }

        setState(payload)
        resolve(payload)
      } catch (e) {
        const error =
          line[`unit.publicConfig`]?.hoursMessage || getMessage(corporationId)
        setState({
          isLoading: false,
          error,
        })
      }
    })
  }

  useEffect(() => {
    async function getInitialData() {
      let hoursMessage = getMessage(corporationId)
      try {
        // Se registra visita a Reservas
        API.newVisit(lineId).catch(Sentry.captureException)
        const line = await API.getLine(lineId)
        if (line.publicConfig) {
          setAlert(line.publicConfig.alert)
        }
        if (line[`unit.publicConfig`]?.hoursMessage) {
          hoursMessage = line[`unit.publicConfig`].hoursMessage
        }
        const {
          availableDays,
          startTime,
          endTime,
        } = await API.getAvailableReservationDays({
          lineId,
        })
        const { reservation } = await API.getNextAvailableReservation({
          startTime,
          endTime,
          lineId,
          corporationId,
          filter: query.get(`filter`),
        })

        return setState({
          nextAvailableDate: moment(reservation.reservationDate),
          line,
          isLoading: false,
          error: null,
          availableDays,
          startTime,
          endTime,
        })
      } catch (e) {
        const error = typeof e == `string` ? e : hoursMessage
        return setState({
          isLoading: false,
          error,
        })
      }
    }
    getInitialData()
  }, [])

  function getPath(selectedReservation) {
    // El picker le pasa en selectedReservation el id de reserva
    // Se busca la reservation que tenga ese id para generar el pathname
    // con la hora de reserva
    const selectedHour = reservations.find(
      reservationDate =>
        reservationsById[reservationDate].id === selectedReservation,
    )
    return {
      pathname: `/fila/${match.params.lineId}/reserva/${selectedHour}`,
      state: { line, patient, selectedHour },
    }
  }

  function setRut(wholeRut) {
    if (wholeRut.length === 0) {
      setState({ wholeRut: `` })
    } else {
      setState({ wholeRut: clean(wholeRut) })
    }
  }

  async function validatePatient() {
    setState({ isLoading: true })
    try {
      const validPatient = await validationByCoporation({
        corporationId,
        wholeRut,
        unitId: line.unitId,
        lineId: line.id,
      })
      if (
        line.patientValidationType === 0 &&
        validPatient.prescriptions.length === 0
      ) {
        // eslint-disable-next-line no-throw-literal
        throw `Lo sentimos, no encontramos recetas vigentes asociadas al rut ingresado, asegurese de que esté correcto.`
      }
      setState({
        patient: validPatient,
        isLoading: false,
        error: null,
      })
    } catch (e) {
      const error =
        typeof e == `string`
          ? e
          : `Lo sentimos, no encontramos un beneficiario asociado al rut ingresado, asegurese de que esté correcto.`
      setState({
        ...state,
        isLoading: false,
        error,
      })
    }
  }
  const canSubmit = validate(wholeRut)

  function onSelectedDate(newSelectedDate) {
    setState({
      selectedDate: newSelectedDate,
      mobileCalendarDisplay: `block`,
    })

    const date = moment(newSelectedDate)
    getReservations({ date, isForward: false })
  }

  function shouldDisableTile({ date }) {
    const formattedDate = moment(date).format(`YYYY-MM-DD`)
    return !availableDays.includes(formattedDate)
  }

  const activeStartDate = new Date(availableDays[0])

  return (
    <Container
      width="100%"
      alignItems="center"
      justifyContent="center"
      paddingX={0}
    >
      {line.validatePatient && !patient ? (
        <Container
          width="100%"
          maxWidth="700px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX={4}
        >
          <Typography
            color="primary.0"
            fontSize={{ sm: `2`, md: `3`, lg: `7`, xl: `7` }}
            fontWeight="bold"
            boxSizing="borderbox"
            marginTop={{ sm: `3`, md: `3`, lg: `3`, xl: `3` }}
            marginBottom={{ sm: `3`, md: `3`, lg: `5`, xl: `3` }}
          >
            Ingresa tu rut
          </Typography>
          <Input
            paddingX={4}
            width="700px"
            placeholder="RUN"
            borderRadius="5px"
            color="primary.0"
            name="wholeRut"
            fontSize={{
              _: `1.1rem`,
              sm: `1.1rem`,
              md: `1.5rem`,
              lg: `1.5rem`,
              xl: `1.5rem`,
            }}
            padding="0.5em 1em"
            marginBottom="0.5em"
            fontWeight="500"
            backgroundColor="grey.4"
            borderColor="grey.2"
            value={wholeRut.length ? format(wholeRut) : ``}
            onChange={setRut}
            requiredohh
          />
          <Button
            width="100%"
            maxWidth="700px"
            hoverProps={canSubmit ? { backgroundColor: `primary.2@0.9` } : null}
            borderRadius="5px"
            margin="0 auto"
            marginY="3"
            padding="3"
            withShadow
            backgroundColor={canSubmit ? `primary.2` : `grey.1`}
            onClick={validatePatient}
          >
            {isLoading ? (
              <Icon icon="loader" spin margin="0 auto" fontSize="8" />
            ) : (
              <Typography
                textDecoration="underline"
                color={canSubmit ? `white` : `grey.3`}
                fontSize={{ _: 4, md: 6 }}
              >
                Buscar
              </Typography>
            )}
          </Button>
          {error && (
            <Typography
              color="error"
              fontWeight="500"
              lineHeight="20px"
              marginRight="1"
              fontSize="1"
              marginTop="2"
            >
              {error}
            </Typography>
          )}
        </Container>
      ) : nextAvailableDate != null ? (
        <Container
          width="100%"
          flexDirection="column"
          paddingX={{
            sm: `4`,
            md: `4`,
            lg: `5%`,
          }}
          paddingTop="3"
        >
          <Typography
            color="primary.0"
            fontSize={{ sm: `2`, md: `3`, lg: `7`, xl: `7` }}
            fontWeight="bold"
            boxSizing="borderbox"
            paddingTop="0 !important"
            alignSelf={{
              sm: `flex-start`,
              md: `flex-start`,
              lg: `center`,
              xl: `center`,
            }}
            marginBottom={{ sm: `3`, md: `3`, lg: `3`, xl: `3` }}
          >
            Selecciona hora y fecha para tu cita:
          </Typography>
          <Container
            width="100%"
            justifyContent={{
              sm: `center`,
              md: `center`,
              lg: `center`,
              xl: `space-around`,
            }}
            paddingX={{
              sm: `0`,
              md: `4`,
              lg: `2vh`,
              xl: `17vh`,
            }}
          >
            <Container
              width={{
                sm: `100%`,
                md: `700px`,
                lg: `655px`,
                xl: `700px`,
              }}
              display={{
                sm: mobileCalendarDisplay,
                md: mobileCalendarDisplay,
                lg: `block`,
                xl: `block`,
              }}
              marginRight={4}
            >
              <HourPicker
                availableDays={availableDays}
                hours={hours}
                isLoading={isLoading}
                request={getReservations}
                getPath={getPath}
                nextAvailableDate={nextAvailableDate}
                styles={hourButtonStyles}
                openCalendar={() => setState({ mobileCalendarDisplay: `none` })}
                selectedDayInTheCalendar={selectedDate}
              />
            </Container>
            <Container
              width={{
                sm: `4`,
                md: `4`,
                lg: `30%`,
                xl: `30%`,
              }}
              display={{
                sm: `${mobileCalendarDisplay === `block` ? `none` : `block`}`,
                md: `${mobileCalendarDisplay === `block` ? `none` : `block`}`,
                lg: `block`,
                xl: `block`,
              }}
              position="relative"
            >
              <Calendar
                defaultActiveStartDate={activeStartDate}
                onChange={onSelectedDate}
                value={selectedDate}
                tileDisabled={shouldDisableTile}
                locale={`es-419`}
                view="month"
              />
              {isCalendarLoading ? (
                <Icon
                  width="100%"
                  position="absolute"
                  top="50%"
                  left="45%"
                  icon="spinner"
                  spin
                  color="primary.0"
                  fontSize="25px"
                />
              ) : null}
            </Container>
          </Container>
        </Container>
      ) : (
        <Typography
          color="primary.0"
          fontWeight="500"
          lineHeight="20px"
          marginRight="1"
          fontSize="6"
          marginTop="2"
        >
          {error}
        </Typography>
      )}
    </Container>
  )
}
export default HourPickerScreen
