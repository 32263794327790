import React from 'react'
import { ReactCalendar } from './styles'
import Container from './Container'
import Icon from './Icon'
import { CalendarContainer } from './styles/calendarStyles'
import 'react-calendar/dist/Calendar.css'

const Calendar = ({
  onChange,
  value,
  tileDisabled,
  view,
  onActiveStartDateChange,
  isCalendarLoading,
  mobileCalendarDisplay,
  width,
  height,
  ...props
}) => {
  return (
    <CalendarContainer>
      <Container
        padding="3"
        width={{
          sm: `4`,
          md: `4`,
        }}
        display={{
          sm: `${mobileCalendarDisplay === `block` ? `none` : `block`}`,
          md: `${mobileCalendarDisplay === `block` ? `none` : `block`}`,
          lg: `block`,
          xl: `block`,
        }}
        position="relative"
      >
        <ReactCalendar
          width={width}
          onChange={onChange}
          value={value}
          tileDisabled={tileDisabled}
          onActiveStartDateChange={onActiveStartDateChange}
          locale={`es-419`}
          view={view}
          prev2Label={null}
          next2Label={null}
          {...props}
        />
        {isCalendarLoading ? (
          <Icon
            width="100%"
            position="absolute"
            top="50%"
            left="45%"
            icon="spinner"
            spin
            color="primary.0"
            fontSize="25px"
          />
        ) : null}
      </Container>
    </CalendarContainer>
  )
}

export default Calendar
