/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { format, clean, validate as rutValidator } from 'rut.js'
import { useStateWithMerge, useCurrentLocation } from 'hooks'
import { useCorporation, useTheme, useAlert } from 'context'
import { Container, Typography, Input, Icon, Button, Form } from 'components'
import message from 'antd/es/message'
import API from 'config/apiAgenda'
import SuccessScreen from './DeliveryRequestConfirmation'
import LocationConfirmationScreen from './DeliveryLocationValidation'

const geolocationOptions = {
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
}

const initialState = defaultValues => ({
  wholeRut: ``,
  patient: null,
  regionId: defaultValues ? defaultValues.regionId : 7, //7: RM
  communeId: defaultValues ? defaultValues.communeId : undefined,
  regions: [],
  communes: [],
  healthInstitutions: [],
  medicalSpecialties: [],
  isLoading: false,
  success: false,
  locationConfirmationStep: false,
  error: null,
  errors: {},
  formSchema: {},
  unit: null,
  data: [],
})

function DeliveryRequest({ history, match }) {
  const {
    theme: { defaultValues },
  } = useTheme()
  const { setAlert } = useAlert()

  // TODO PRUEBA
  const {
    location: defaultLocation,
    error: geolocalitationError,
  } = useCurrentLocation(geolocationOptions)
  // TODO END PRUEBA
  const [state, setState] = useStateWithMerge(initialState(defaultValues))
  const { id: corporationId } = useCorporation()
  const {
    wholeRut,
    patient,
    isLoading,
    success,
    locationConfirmationStep,
    error,
    regionId,
    communeId,
    formSchema,
    communes,
    regions,
    healthInstitutions,
    medicalSpecialties,
    data,
  } = state
  useEffect(() => {
    async function getInitialData() {
      setState({ isLoading: true, error: null })
      try {
        const healthInstitutions = await API.getHealthInstitutions({
          unitId: match.params.unitId,
        })
        const { medicalSpecialties } = await API.getMedicalSpecialties({
          unitId: match.params.unitId,
        })
        const regions = await API.getRegions()
        const unit = await API.getUnit(match.params.unitId)
        if (unit.publicConfig) {
          setAlert(unit.publicConfig.alert)
        }
        setState({
          regions,
          healthInstitutions,
          medicalSpecialties,
          formSchema: unit.publicForms.withValidatePatient,
          isLoading: false,
          unit,
        })
      } catch (e) {
        const error =
          typeof e == `string`
            ? e
            : `Ha ocurrido un error al obtener la información`
        setState({
          ...state,
          isLoading: false,
          error,
        })
      }
    }
    getInitialData()
  }, [])

  function setRut(wholeRut) {
    if (wholeRut.length === 0) {
      setState({ wholeRut: `` })
    } else {
      setState({ wholeRut: clean(wholeRut) })
    }
  }

  async function validatePatient() {
    setState({ isLoading: true })
    const { communeId: defaultCommuneId } = state
    try {
      const [rut, dv] = format(wholeRut).split(`-`)
      const payload = {
        rut: parseInt(clean(rut), 10),
        dv,
        corporationId,
        validateAge: state.unit.hasDelivery
          ? state.unit.minAgeToSchedule != 0
          : true,
        unitId: match.params.unitId,
        hasDeliveryIntegrationFlow: state.unit.hasDeliveryIntegrationFlow,
        contactEmail: state.unit.contactEmail,
        isDelivery: true,
      }

      const { communeId, ...validPatient } = await API.validatePatient(payload)
      setState({
        patient: { ...validPatient, communeId: communeId || defaultCommuneId },
        isLoading: false,
        error: null,
      })
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `Lo sentimos, no encontramos un beneficiario asociado al rut ingresado, asegurese de que este correcto.`
      setState({
        ...state,
        isLoading: false,
        error,
      })
    }
  }

  function validateLocation(patient) {
    if (!patient.latitude || !patient.longitude) {
      setState({
        ...state,
        patient,
        canSubmit: true,
        locationConfirmationStep: true,
      })
    } else {
      generateDeliveryRequest(patient)
    }
  }

  async function generateDeliveryRequest(patient) {
    setState({ isLoading: true, error: null })
    try {
      const [rut, dv] = format(wholeRut).split(`-`)
      const payload = {
        unitId: match.params.unitId,
        validPatientId: patient.id,
        healthInstitutionId: patient.healthInstitutionId,
        medicalSpecialtyId: patient.medicalSpecialtyId,
        treatingDoctor: patient.treatingDoctor,
        hasDeliveryIntegrationFlow: state.unit.hasDeliveryIntegrationFlow,
        hasPrescriptionValidation:
          state.unit.pharmacyConfig &&
            state.unit.pharmacyConfig.hasPrescriptionValidation
            ? state.unit.pharmacyConfig.hasPrescriptionValidation
            : false,
        validatePatient: state.unit.validatePatient,
      }
      await API.verifyDelivery({
        rut: parseInt(clean(rut), 10),
        dv,
        unitId: match.params.unitId,
        medicalSpecialtyId: patient.medicalSpecialtyId,
        hasDeliveryIntegrationFlow: state.unit.hasDeliveryIntegrationFlow,
      })
      await API.updatePatient(patient)
      const data = await API.generateDeliveryRequest(payload)
      setState({
        success: true,
        locationConfirmationStep: false,
        isLoading: false,
        patient: { ...patient, googleAddress: null },
        error: null,
        data,
      })
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `Ha ocurrido un error, asegurese de rellenar todos los datos requeridos e intente nuevamente.`
      message.error(error)
      setState({
        ...state,
        isLoading: false,
        patient: { ...patient, googleAddress: null },
        error,
      })
    }
  }

  const canSubmit = rutValidator(wholeRut)

  useEffect(() => {
    async function getCommunes() {
      try {
        setState({ isLoading: true, error: null })
        const communes = await API.getCommunes(regionId)
        const belongsToCommunes = communes.some(
          commune => commune.id === communeId,
        )
        setState({
          communes,
          communeId: belongsToCommunes ? communeId : undefined,
          isLoading: false,
        })
      } catch (e) {
        setState({
          ...state,
          isLoading: false,
          error: `Ha ocurrido un error al obtener las comunas`,
        })
      }
    }
    getCommunes()
  }, [regionId])

  return success ? (
    <SuccessScreen
      history={history}
      patient={patient}
      unit={state.unit}
      data={data}
    />
  ) : locationConfirmationStep ? (
    <LocationConfirmationScreen
      history={history}
      patient={patient}
      canSubmit={canSubmit}
      defaultLocation={defaultLocation}
      handleSubmit={generateDeliveryRequest}
      isLoading={isLoading}
    />
  ) : (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="center"
      paddingX={4}
    >
      <Container width="100%" flexDirection="column" alignItems="flex-start">
        <Typography
          width="100%"
          color="primary.0"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
          marginBottom={{ _: 3, lg: 4 }}
        >
          Confirmación de datos personales
        </Typography>
      </Container>
      <Container
        width="100%"
        maxWidth="700px"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        {patient == null ? (
          <Container
            width="100%"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Input
              placeholder="RUN"
              borderRadius="5px"
              color="primary.0"
              name="wholeRut"
              width="100%"
              fontSize={[`1.1rem`, `1.1rem`, `1.5rem`, `1.5rem`, `1.5rem`]}
              padding="0.5em 1em"
              marginBottom="0.5em"
              fontWeight="500"
              backgroundColor="grey.4"
              borderColor="grey.2"
              value={wholeRut.length ? format(wholeRut) : ``}
              onChange={setRut}
              required
            />
          </Container>
        ) : (
          <Container
            width="100%"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              width="80%"
              color="primary.0"
              fontSize={{ _: 5, lg: 6 }}
              fontWeight="bold"
              marginBottom={{ _: 3, lg: 4 }}
            >
              {`Hola ${patient.firstName || ``} ${patient.lastName ||
                ``}, actualiza tus datos de ser necesario o confirma para generar tu solicitud`}
            </Typography>
            <Form
              formSchema={formSchema}
              selectedValues={patient}
              extraProps={{
                communes,
                regions,
                healthInstitutions,
                medicalSpecialties,
              }}
              defaultValues={defaultValues}
              okText="Solicitar entrega"
              onConfirm={validateLocation}
              isLoading={isLoading}
              sideEffects={[
                {
                  condition: `regionId`,
                  fn: regionId => setState({ regionId }),
                },
              ]}
            />
          </Container>
        )}
        {error && (
          <Typography
            color="error"
            fontWeight="500"
            lineHeight="20px"
            marginRight="1"
            fontSize="1"
            marginTop="2"
          >
            {error}
          </Typography>
        )}
        {!patient && (
          <Button
            width={{ _: `100%`, md: `50%` }}
            hoverProps={canSubmit ? { backgroundColor: `primary.2@0.9` } : null}
            borderRadius="5px"
            margin="0 auto"
            marginY="3"
            padding="3"
            withShadow
            backgroundColor={canSubmit ? `primary.2` : `grey.1`}
            onClick={canSubmit ? validatePatient : null}
          >
            {isLoading ? (
              <Icon icon="loader" spin margin="0 auto" fontSize="8" />
            ) : (
              <Typography
                textDecoration="underline"
                color={canSubmit ? `white` : `grey.3`}
                fontSize={{ _: 4, md: 6 }}
              >
                Buscar
              </Typography>
            )}
          </Button>
        )}
      </Container>
    </Container>
  )
}

export default DeliveryRequest
