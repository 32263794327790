import React, { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  LocationPicker,
  Button,
  Link,
  Icon,
} from 'components'
import { getGeocode } from 'use-places-autocomplete'

function LocationConfirmationScreen({
  history,
  patient,
  defaultLocation,
  canSubmit,
  handleSubmit,
  isLoading,
}) {
  const [location, setLocation] = useState(defaultLocation)

  const handleClick = async ({ lat, lng }) => {
    const matchAddress = await getGeocode({
      location: { lat, lng },
    })
    setLocation({ lat, lng, address: matchAddress[0].formatted_address })
  }

  useEffect(() => {
    if (defaultLocation !== null) {
      setLocation({
        lat: defaultLocation.lat,
        lng: defaultLocation.lng,
        address: defaultLocation.address,
      })
    }
  }, [defaultLocation])

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems={{ md: `center` }}
      paddingX={4}
    >
      <Container
        width={{ _: `100%`, md: `35%` }}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Container width="100%" flexDirection="column">
          <Typography
            color="primary.2"
            fontSize="6"
            fontWeight="bold"
            fontStyle="italic"
            marginY="10px"
          >
            Por favor, necesitamos que verifique su ubicación exacta para poder
            ingresar su solicitud.
          </Typography>
          <LocationPicker
            id="map"
            center={location}
            defaultCenter={defaultLocation}
            defaultZoom={15}
            apiKey="AIzaSyApxhYkZmGn46PVevQ7DKEKvfzWp9bXsNo"
            handleClick={handleClick}
          />
        </Container>
        <Button
          width={{ _: `100%`, md: `50%` }}
          hoverProps={
            canSubmit && !isLoading
              ? { backgroundColor: `primary.2@0.9` }
              : null
          }
          disabled={!canSubmit && isLoading}
          margin="0 auto"
          marginY="3"
          padding="3"
          withShadow
          backgroundColor={canSubmit && !isLoading ? `primary.2` : `grey.1`}
          onClick={() =>
            canSubmit && !isLoading
              ? handleSubmit({
                  ...patient,
                  latitude: location.lat,
                  longitude: location.lng,
                  communeString: location.address.split(`, `),
                  street: location.address.split(`, `)[0],
                })
              : null
          }
        >
          {isLoading ? (
            <Icon icon="loader" spin color="white" fontSize="6" />
          ) : (
            <Typography
              textDecoration="underline"
              color={canSubmit ? `white` : `grey.3`}
              fontSize="4"
            >
              Enviar solicitud.
            </Typography>
          )}
        </Button>
        <Link
          onClick={() => {
            history.push(`/`)
          }}
          color="primary.0"
          hoverProps={{ color: `secundary.0` }}
          fontSize={{ _: 1, lg: 4 }}
          fontStyle="italic"
          justifyContent="center"
          width="100%"
        >
          <Typography>Volver al menú principal</Typography>
        </Link>
      </Container>
    </Container>
  )
}

export default LocationConfirmationScreen
