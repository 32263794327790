import React, { useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import { Container, Typography, Icon } from 'components'
import { useLocation } from 'react-router-dom'
import API from 'config/apiAgenda'
import { notification } from 'antd'
import { useStateWithMerge } from 'hooks'
import SpecialistCalendar from './SpecialistCalendar'

const initialState = {
  lines: [],
  unit: {},
  isLoading: true,
}

function ReservationsPickerByList() {
  const [state, setState] = useStateWithMerge(initialState)
  const { lines, unit, isLoading } = state
  const location = useLocation()
  const path = location.pathname.split(`/`)
  const unitId = path[2]
  const lineCategoryId = path[3]

  useEffect(() => {
    async function getUnit() {
      try {
        const unit = await API.getUnit(unitId)
        let { lines } = await API.getLines(unitId, {
          lineCategoryId,
          withCurrentTicket: false,
          isPublic: true,
        })
        const availableDays = await Promise.all(
          lines.map(async line => {
            const payload = await API.getAvailableReservationDays({
              lineId: line.id,
            })
            // Se registra visita a Reservas
            API.newVisit(line.id).catch(Sentry.captureException)
            return payload
          }),
        )
        lines = lines.map((line, index) => ({
          ...line,
          ...availableDays[index],
        }))
        setState({ unit, lines, isLoading: false })
      } catch (e) {
        setState({ isLoading: false })
        notification.error({ message: e })
      }
    }
    getUnit()
  }, [])

  const orderedLines = useMemo(
    () => lines.sort((a, b) => b.availableDays.length - a.availableDays.length),
    [lines],
  )

  return (
    <Container
      marginX={{ sm: 0, md: 4, lg: 6 }}
      marginY={{ sm: `0.5em`, md: 2, lg: 5 }}
      paddingX={{ _: 5, sm: 2, lg: 8 }}
      paddingY="1em"
      width="100%"
      withShadow
      borderRadius="4px"
    >
      {isLoading ? (
        <Icon
          icon="loader"
          spin
          margin="0 auto"
          fontSize="8"
          color="primary.2"
        />
      ) : (
        <>
          <Typography
            paddingX={{ _: 5, sm: 2, lg: 8 }}
            paddingY={{ _: 5, sm: 2, lg: 8 }}
            fontSize={{ lg: 4, md: 4, sm: 2 }}
            fontWeight="semi-bold"
          >
            {unit.name}
          </Typography>
          {orderedLines.map(line => (
            <SpecialistCalendar key={line.id} {...line} unit={unit} />
          ))}
        </>
      )}
    </Container>
  )
}

export default ReservationsPickerByList
