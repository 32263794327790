import React, { useEffect, useMemo } from 'react'
import { Container, Typography, Input, Button } from 'components'
import { useLocation, useHistory } from 'react-router-dom'
import API from 'config/apiAgenda'
import { baseURL } from 'config/constants'
import { useStateWithMerge } from 'hooks'
import notification from 'antd/es/notification'

const inputProps = {
  marginBottom: `3`,
  width: `47%`,
}

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

// eslint-disable-next-line max-lines-per-function
function PatientUpdate() {
  const queryParams = useQuery()
  const history = useHistory()
  const rutParam = queryParams.get(`rut`)
  const nameParam = queryParams.get(`nombres`)
  const lastNameParam = queryParams.get(`apellidos`)
  const token = queryParams.get(`token`)
  const error = queryParams.get(`error`)
  const [state, setState] = useStateWithMerge({
    firstName: nameParam || ``,
    lastName: lastNameParam || ``,
    rut: rutParam || ``,
    email: ``,
    phone: ``,
    address: ``,
  })
  const { firstName, lastName, rut, email, phone, address } = state

  const unformattedRut = rut?.split(`-`)
  const newRut = unformattedRut[0].replaceAll(`.`, ``)
  const dv = unformattedRut[1]

  useEffect(() => {
    if (error || !token || !rut) {
      const formattedUrl = baseURL.replace(`/api/v1`, ``)
      window.location.href = `${formattedUrl}/servicios/home`
    }

    async function getPatient() {
      try {
        const patient = await API.getPatientWithRut({ rut: newRut, dv, token })
        const { email, phone, address } = patient
        setState({ email, phone, address })
        window.location.href = `https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout`
      } catch (e) {
        console.log(e)
      }
    }
    getPatient()
  }, [])

  async function handleSubmit() {
    const payload = {
      firstName,
      lastName,
      rut: newRut,
      dv,
      email,
      phone,
      address,
    }
    if (
      firstName != `` &&
      lastName != `` &&
      rut != `` &&
      email != `` &&
      phone != `` &&
      address != ``
    ) {
      try {
        await API.upsertPatient(payload, token)
        notification.success({ message: `Datos actualizados exitosamente` })
        return history.push({
          pathname: `/servicios/home`,
          state: { fromUpdate: true },
        })
      } catch (e) {
        notification.error({ message: e })
      }
    } else {
      notification.error({ message: `Todos los campos son obligatorios` })
    }
  }

  return (
    <Container
      width="100%"
      padding={[`4`, `4`, `0`, `0`]}
      paddingTop="0 !important"
      alignItems={[`flex-start`, `flex-start`, `center`, `center`]}
      flexDirection="column"
      backgroundColor="#F5F5F5"
    >
      <Container
        flexDirection="column"
        width="60%"
        paddingY="5"
        paddingX="6"
        marginY="5"
        backgroundColor="white"
        withSadow
      >
        <Typography fontSize="6" marginBottom="3">
          Actualización de datos
        </Typography>
        <Container width="100%" justifyContent="space-between" paddingY="2">
          <Input
            {...inputProps}
            value={firstName}
            name="firstName"
            label="Nombres:"
            onChange={e => setState({ firstName: e })}
            disabled
          />
          <Input
            {...inputProps}
            value={lastName}
            name="lastName"
            label="Apellidos:"
            onChange={e => setState({ lastName: e })}
            disabled
          />
        </Container>
        <Container width="100%" justifyContent="space-between" paddingY="2">
          <Input
            {...inputProps}
            value={rut}
            name="rut"
            label="Rut:"
            onChange={e => setState({ rut: e })}
            disabled
          />
          <Input
            {...inputProps}
            value={email}
            name="email"
            label="Mail:"
            onChange={e => setState({ email: e })}
          />
        </Container>
        <Container width="100%" justifyContent="space-between" paddingY="2">
          <Input
            {...inputProps}
            value={phone}
            name="phone"
            label="Teléfono:"
            onChange={e => setState({ phone: e })}
          />
          <Input
            {...inputProps}
            value={address}
            name="address"
            label="Dirección:"
            onChange={e => setState({ address: e })}
          />
        </Container>
        <Container width="100%" justifyContent="flex-end">
          <Button
            backgroundColor="info"
            paddingY="2"
            paddingX="4"
            borderRadius="2px"
            onClick={handleSubmit}
          >
            Aceptar
          </Button>
        </Container>
      </Container>
    </Container>
  )
}

export default PatientUpdate
