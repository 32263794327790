import React from 'react'
import { Container, Typography, Form } from 'components'
import API from 'config/apiAgenda'
import { isValid, formatRut } from 'utils'
import notification from 'antd/es/notification'
import 'antd/es/notification/style/index.css'
import moment from 'moment-timezone'
import { useCorporation } from 'context'

const formSchema = {
  fields: [`rut`],
  rut: {
    placeholder: `RUT`,
    isRequired: true,
    name: `rut`,
    type: `text`,
    isActive: true,
    error: `Ingrese rut válido`,
  },
}
function ValidateReservation({ unitId, setState, ...state }) {
  const { id: corporationId } = useCorporation()
  const { isLoading } = state

  async function searchReservations({ rut }) {
    setState({ isLoading: true })

    try {
      if (!isValid(rut)) {
        throw `Ingrese un rut válido`
      }
      const formattedRut = formatRut(rut)
      const reservations = await API.findReservations({
        rut: formattedRut,
        corporationId,
        unitId,
        statuses: [2, 3],
        startDate: moment()
          .startOf(`day`)
          .format(`YYYY-MM-DD HH:mm`),
        serialize: false,
      })

      if (reservations.length === 0) {
        notification.error({
          message: `No se encontraron horas futuras asociadas a este rut.`,
        })
      } else {
        setState({
          rut: formattedRut,
          reservations,
        })
      }
    } catch (e) {
      const error = typeof e == `string` ? e : `Ha ocurrido un error, reintente`
      notification.error({ message: error })
    } finally {
      setState({ isLoading: false })
    }
  }

  return (
    <Container
      width="100%"
      paddingX={4}
      isLoading={isLoading}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingY={6}
    >
      <Container
        width="100%"
        paddingBottom="2"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          color="primary.1"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
        >
          Validar hora agendada
        </Typography>
        <Typography
          color="primary.1"
          fontSize={{ _: 5, lg: 8 }}
          marginBottom={{ _: 3, lg: 4 }}
        >
          Ingrese su rut
        </Typography>
      </Container>
      <Container width="100%" maxWidth="700px">
        <Form
          formSchema={formSchema}
          okText="Continuar"
          onConfirm={searchReservations}
        />
      </Container>
    </Container>
  )
}

export default ValidateReservation
