import React, { useEffect, useState } from 'react'
import { Container, Typography, Form } from 'components'
import API from 'config/apiAgenda'
import {
  clean as cleanRut,
  format as formatRut,
  validate as validateRut,
} from 'rut.js'
import notification from 'antd/es/notification'
import 'antd/es/notification/style/index.css'
import message from 'antd/es/message'

const formSchema = {
  fields: [`rut`, `email`],
  rut: {
    placeholder: `RUT`,
    isRequired: true,
    name: `rut`,
    type: `text`,
    isActive: true,
    error: `Ingrese rut válido`,
  },
  email: {
    placeholder: `Correo`,
    isRequired: true,
    name: `email`,
    type: `text`,
    isActive: true,
    error: `Ingrese un correo válido`,
  },
}

const getFormSchema = line => {
  if (line?.withoutRutValidation) {
    delete formSchema.rut
    formSchema.fields = formSchema.fields.filter(item => item !== `rut`)
  }
  return formSchema
}

function Ticket({ history, match }) {
  const [isLoading, setLoading] = useState(false)
  const [line, setLine] = useState(false)

  useEffect(() => {
    const getLine = async () => {
      try {
        setLoading(true)
        const line = await API.getLine(match.params.lineId)
        setLine(line)
        setLoading(false)
      } catch (e) {
        const error =
          typeof e == `string`
            ? e
            : `Ha ocurrido un error al obtener la información`
        message.error(error)
        setLoading(false)
      }
    }
    getLine()
  }, [])

  async function onConfirm({ rut, email }) {
    setLoading(true)
    const cleanedRut = cleanRut(rut)
    try {
      if (!line?.withoutRutValidation && !validateRut(cleanedRut)) {
        throw `Ingrese un rut valido`
      }
      const [patientRut, patientRutDv] = formatRut(cleanedRut).split(`-`)
      const result = await API.generateTicket({
        patientRut: parseInt(cleanRut(patientRut), 10),
        patientRutDv,
        lineId: match.params.lineId,
        patientMail: email,
      })
      const { identifier, ticketNumber } = result.ticket
      const ticketIdentifier = `${identifier}${ticketNumber}`
      notification.success({
        message: `¡Se ha solicitado su ticket!`,
        description: `${ticketIdentifier}`,
      })
      history.push({
        pathname: `/fila/${match.params.lineId}/ticket/${ticketIdentifier}`,
        state: result,
      })
    } catch (e) {
      const error = typeof e == `string` ? e : `Ha ocurrido un error, reintente`
      notification.error({ message: error })
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return `Cargando...`
  }
  if (!line) {
    return null
  }
  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingX={4}
    >
      <Container
        width="100%"
        paddingBottom="6"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography
          width="100%"
          color="primary.0"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
          marginBottom={{ _: 3, lg: 4 }}
        >
          Confirmación de datos personales
        </Typography>
      </Container>
      <Container width="100%" maxWidth="700px">
        <Form
          formSchema={getFormSchema(line)}
          okText="Pedir ticket"
          onConfirm={onConfirm}
        />
      </Container>
    </Container>
  )
}

export default Ticket
