import styled from 'styled-components'
import {
  compose,
  space,
  color,
  flexbox,
  layout,
  border,
  position,
  typography,
} from 'styled-system'
import { colorsWithOpacity } from '../utils'

const ButtonWrapper = styled.button`
  ${compose(space, color, layout, border, position, flexbox, typography)}
  ${({ withShadow = false, background = null, cursor = `pointer` }) => `
    ${
      withShadow
        ? `box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3) !important;`
        : ``
    }
    ${background ? `background: ${background} !important` : ``}
    ${cursor ? `cursor: ${cursor}` : ``}
  `}
  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
  }
`

ButtonWrapper.defaultProps = {
  color: `white`,
  border: `none`,
  maxWidth: `100%`,
}

export default ButtonWrapper
