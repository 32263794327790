import styled from 'styled-components'
import {
  compose,
  background,
  space,
  color,
  border,
  layout,
  typography,
} from 'styled-system'
import Input from 'antd/es/input'
import Select from 'antd/es/select'
import Radio from 'antd/es/radio'
import AntdInputNumber from 'antd/es/input-number'
import AntdCheckbox from 'antd/es/checkbox'
import AntdDatePicker from 'antd/es/date-picker'
import 'antd/es/input/style/index.css'
import 'antd/es/radio/style/index.css'
import 'antd/es/select/style/index.css'
import 'antd/es/input-number/style/index.css'
import 'antd/es/checkbox/style/index.css'
import 'antd/es/date-picker/style/index.css'

export const DatePicker = styled(AntdDatePicker)`
  & .ant-calendar-picker-icon {
    top: 40% !important;
  }
  &.ant-calendar-picker {
    width: 100% !important;
    & .ant-calendar-picker-input.ant-input {
      ${compose(space, layout, border, color)}
      &::placeholder {
        ${color}
      }
    }
  }
`
DatePicker.defaultProps = {
  color: `primary.2`,
  size: `large`,
}

export const CustomSelect = styled(Select)`
  width: 100% !important;
  height: auto;
  border-radius: 0;
  line-height: 1;
  & .ant-select-selection.ant-select-selection--single,
  & .ant-select-selection.ant-select-selection--multiple {
    width: 100%;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    & .ant-select-selection__placeholder {
      ${color}
    }
  }
  & .ant-select-selection__rendered {
    margin-left: 0;
  }
`
export const CustomInput = styled(Input)`
  &.ant-input {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

export const InputNumber = styled(AntdInputNumber)`
  &.ant-input-group-wrapper {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder, & input {
      ${compose(color, typography)}
    }
  }
`

export const InputPassword = styled(Input.Password)`
  &.ant-input-password .ant-input {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

export const TextAreaInput = styled(Input.TextArea)`
  &.ant-input-password .ant-input {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

export const CustomRadio = styled(Radio)`
  &.ant-radio-group {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

export const Checkbox = styled(AntdCheckbox)`
  margin-left: 10px !important;
  margin-top: 3px !important;

  & .ant-checkbox-checked.ant-checkbox-inner {
    ${color}
  }
`

Checkbox.defaultProps = {
  backgroundColor: `primary.2`,
}
