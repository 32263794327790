import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Typography, Icon, Button, Input } from 'components'
import { useStateWithMerge } from 'hooks'
import API from 'config/apiAgenda'
import { set } from 'lodash'
import Line from './Line'

function getService({ serviceKey, ...options }) {
  const [service, serviceId] = serviceKey.split(`-`)
  const requestByServices = {
    unit: [API.getLines, API.getUnit],
  }
  if (!Object.keys(requestByServices).includes(service)) {
    // Si el servicio solicitado no está sorportado se genera un error
    throw `Servicio no soportado`
  }
  return requestByServices[service].map(request => request(serviceId, options))
}

const initialState = {
  lines: [],
  linesFilter: [],
  linesSort: [],
  isLoading: true,
  error: null,
  service: null,
  selectedLine: null,
}

// eslint-disable-next-line max-lines-per-function
function Visor(props) {
  const [lastTicket, setLastTicket] = useState(`-`)
  const { serviceKey } = useParams()
  const [state, setState] = useStateWithMerge(initialState)

  const { linesFilter, linesSort, selectedLine } = state

  const { isLoading, error, lines, service } = state
  useEffect(() => {
    async function getLines() {
      try {
        const [{ lines }, service] = await Promise.all(
          getService({ serviceKey, withCurrentTicket: true }),
        )
        setState({ lines, service, isLoading: false, error: null })
      } catch (error) {
        console.log(error)
      }
    }
    getLines()
  }, [])

  // Orden alfabético por nombre de filas
  function onFocusSort() {
    const sortedLines = lines.sort((line1, line2) =>
      line1.name.localeCompare(line2.name),
    )
    setState({ linesSort: sortedLines })
  }

  // Filtro de filas por nombre
  function onChangeFilter(value) {
    const filterElements = lines.filter(element => element.name === value)
    setState({ linesFilter: filterElements, selectedLine: value })
  }

  function onClickShowAll() {
    setState({ linesFilter: [] })
  }

  return (
    <Container
      flexDirection="column"
      flexWrap="nowrap"
      height="100vh"
      width="100%"
      isLoading={isLoading}
      alignItems="center"
      justifyContent="space-between"
    >
      <Container width="100%" justifyContent="center">
        {/**
         * Se oculta el header del visor hasta el rediseño
         
         */}
        {/* <Container
          backgroundColor="primary.2"
          width="100%"
          justifyContent="center"
          padding={{ sm: 3, md: 4 }}
        >
          <Typography
            color="grey.4"
            fontWeight="500"
            fontSize={{ _: 1, lg: 4 }}
            letterSpacing={1.67}
          >
            VISOR DE TURNOS
          </Typography>
        </Container> */}
        <Container
          backgroundColor="grey.3"
          width="100%"
          justifyContent="center"
          padding={{ sm: 2, md: 3 }}
        >
          <Typography
            color="grey.0"
            fontWeight={500}
            fontSize={{ _: 1, sm: 16 }}
          >
            {service ? service.name : null}
          </Typography>
        </Container>
        <Container
          padding="4"
          paddingLeft={5}
          paddingRight={5}
          width="fill-available"
          flexDirection="column"
        >
          <Container
            backgroundColor="tertiary.0"
            width="100%"
            padding="3"
            justifyContent="center"
            color="grey.4"
            borderRadius={6}
            withShadow
          >
            <Typography fontSize={{ _: 1, sm: 21 }}>Último llamado</Typography>
            <Typography
              fontSize={{ _: 1, sm: 24 }}
              fontWeight="bold"
              marginLeft={3}
            >
              {lastTicket}
            </Typography>
          </Container>
          <Container
            justifyContent="center"
            margin="auto"
            marginTop={5}
            width={{ sm: `100%`, md: `60%` }}
            flexWrap="nowrap"
          >
            <Input
              color="grey.0"
              paddingLeft={3}
              width={{ sm: `100%`, md: `60%` }}
              showSearch
              value={selectedLine}
              type="select"
              placeholder="Filtrar por fila"
              onChange={onChangeFilter}
              onFocus={onFocusSort}
              options={linesSort.map(line => ({
                value: line.name,
                label: line.name,
                key: line.id,
              }))}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />

            {linesFilter.length > 0 ? (
              <Button
                borderRadius={4}
                backgroundColor="tertiary.1"
                marginLeft={3}
                padding={2}
                onClick={onClickShowAll}
              >
                <Typography>Mostrar todo</Typography>
              </Button>
            ) : null}
          </Container>

          <Container margin="auto" alignSelf="center">
            <Typography
              paddingTop="5"
              paddingBottom="3"
              color="tertiary.0"
              fontWeight="bold"
              fontSize={{ _: 1, sm: 16 }}
            >
              Llamados por fila
            </Typography>
          </Container>

          <Container
            width="100%"
            maxHeight={{ _: `45vh`, md: `40vh` }}
            overflow="auto"
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(49%, 1fr))"
            gridGap={3}
          >
            {linesFilter.length > 0
              ? linesFilter.map(line => (
                  <Line
                    key={line.id}
                    {...line}
                    setLastTicket={setLastTicket}
                    width="60%"
                  />
                ))
              : lines.map(line => (
                  <Line key={line.id} {...line} setLastTicket={setLastTicket} />
                ))}
          </Container>
          <Container
            backgroundColor="primary.2@0.2"
            width="100%"
            border="1px solid"
            borderColor="primary.2@0.5"
            marginTop="5"
            padding="3"
            alignItems="center"
            justifyContent="center"
            color="primary.2"
            borderRadius={6}
          >
            <Icon icon="walking" fontSize="40px" marginRight={4} />
            <Typography
              lineHeight={1.3}
              fontSize={{ sm: 2, md: 7 }}
              width={{ sm: `60%`, md: `45%` }}
            >
              Acércate al mesón cuando sea tu turno
            </Typography>
          </Container>
          {/**  oculto hasta que se encuentre lista la vista del ticket */}
          <Container width="100%" justifyContent="center">
            <Button
              backgroundColor="tertiary.1"
              borderBottom="3px solid"
              borderColor="tertiary.2"
              width={{ _: `100%`, md: `45%`, lg: `35%` }}
              marginTop="5"
              padding="4"
              borderRadius={6}
              alignItems="center"
              display="none"
            >
              <Typography fontSize={2} fontWeight="500" letterSpacing={0.5}>
                Ver mi ticket de atención
              </Typography>
            </Button>
          </Container>
        </Container>
      </Container>
      <Container width="100%" position="relative" bottom="0">
        <Container
          width="100%"
          backgroundColor="info@0.1"
          padding={{ _: `5%`, md: `3%` }}
          marginTop="6"
          fontSize={1}
          lineHeight={1.3}
          justifyContent="center"
          textAlign="center"
          fontStyle="italic"
          color="info"
        >
          <Typography fontSize="2" fontWeight="medium">
            Recuerda que para atenderte, puedes pedir un número de atención
            desde tu teléfono celular o hacer una reserva de hora
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}

export default Visor
