/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useCallback, useState } from 'react'
import { useForm } from 'hooks'
import { format } from 'rut.js'
import { useDropzone } from 'react-dropzone'
import Container from './Container'
import Input from './Input'
import Typography from './Typography'
import Button from './Button'
import Icon from './Icon'

function getInitialState({ fields, defaultValues, selectedValues }) {
  const values = {}
  fields.forEach(field => {
    values[field] = null
  })
  const formattedDefaultValues = {}
  Object.entries(defaultValues).forEach(([key, value]) => {
    formattedDefaultValues[key] = value
  })
  const formattedSelectedValues = {}
  Object.entries(selectedValues).forEach(([key, value]) => {
    formattedSelectedValues[key] = value
  })

  return {
    ...values,
    ...formattedDefaultValues,
    ...formattedSelectedValues,
  }
}

function Form({
  formSchema,
  onConfirm = () => null,
  defaultValues = {},
  extraProps,
  selectedValues = {},
  okText = `OK`,
  warningText,
  icon,
  isLoading,
  sideEffects,
  disabledInputs,
  ...props
}) {
  const { fields = [], ...fieldsById } = formSchema

  const initialState = getInitialState({
    fields,
    defaultValues,
    selectedValues,
  })

  const {
    values,
    errors,
    canSubmit: isValidState,
    handleChange,
    handleSubmit,
    addressSuggestions,
    clearSuggestions,
    addressRef,
    addressSelect,
  } = useForm({
    stateSchema: initialState,
    validationSchema: fieldsById,
    callback: onConfirm,
    sideEffects,
    extraProps,
  })

  const onDrop = useCallback(files => {
    const [file] = files
    handleChange(file, `file`)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: `image/*, application/pdf`,
    maxFiles: 1,
  })

  const formatRut = rut => (rut ? format(rut) : ``)

  function formatValue(value, name) {
    if ([`rut`, `patientRut`, `aditionalRut`].includes(name)) {
      return formatRut(value)
    }

    return value
  }

  const optionsByType = ({ type, source, mode }) => {
    if (type === `select`) {
      if (mode === `tags`) {
        return undefined
      }
      return extraProps[source]
    }

    if (type === `address`) {
      return addressSuggestions
    }

    return null
  }

  const canSubmit = isValidState && !isLoading

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      {...props}
    >
      {fields.map(fieldId => {
        const {
          placeholder,
          isRequired,
          name,
          type,
          source,
          isActive,
          label,
          hasLink,
          linkUrl,
          imgUrl,
          ...otherInputProps
        } = fieldsById[fieldId]
        const noInstitutions =
          fieldId == `healthInstitutionId` &&
          (values.communeId == null ||
            extraProps.healthInstitutions.length == 0)

        if (!isActive || noInstitutions) {
          return null
        }

        return (
          <Input
            // Se habilita el input si esta está vacio al principio
            // y si es requerido por schema
            {...otherInputProps}
            format="DD-MM-YYYY"
            disabled={disabledInputs && isRequired && selectedValues[name]}
            addressRef={type === `address` && addressRef}
            onClickOutside={type === `address` ? clearSuggestions : null}
            key={fieldId}
            type={type}
            placeholder={placeholder}
            color="primary.0"
            name={name}
            label={label ? label : null}
            width="100%"
            padding="0.5em 1em"
            fontSize={[`1.1rem`, `1.1rem`, `1.5rem`, `1.5rem`, `1.5rem`]}
            fontWeight="500"
            backgroundColor="grey.4"
            borderColor="grey.2"
            borderRadius="5px"
            marginBottom="0.5em"
            value={values[name] ? formatValue(values[name], name) : null}
            onChange={handleChange}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            addressSelect={addressSelect}
            required={isRequired}
            error={errors[name]}
            hasLink={hasLink}
            url={imgUrl || linkUrl}
            labelKey="name"
            valueKey="id"
            options={optionsByType({
              type,
              source,
              mode: otherInputProps.mode,
            })}
          />
        )
      })}
      {warningText && (
        <Typography
          color="primary.2"
          fontSize={0}
          fontStyle="italic"
          paddingY={2}
        >
          {warningText}
        </Typography>
      )}
      <Button
        width={{ _: `100%`, md: `50%` }}
        hoverProps={canSubmit ? { backgroundColor: `primary.2@0.9` } : null}
        margin="0 auto"
        marginY="3"
        padding="3"
        withShadow
        backgroundColor={canSubmit ? `primary.2` : `grey.1`}
        onClick={canSubmit ? handleSubmit : null}
      >
        {isLoading ? (
          <Icon icon="loader" spin margin="0 auto" fontSize="8" />
        ) : (
          <Typography
            textDecoration="underline"
            color={canSubmit ? `white` : `grey.3`}
            fontSize="4"
          >
            {okText}
            {icon && <Icon icon={icon} marginLeft="2" />}
          </Typography>
        )}
      </Button>
    </Container>
  )
}

export default Form
