import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  color,
  typography,
  border,
  textStyle,
  system,
  flexbox,
} from 'styled-system'

const Typography = styled.h1`
  margin: 0;
  ${compose(
    space,
    layout,
    color,
    typography,
    border,
    textStyle,
    flexbox,
    system({ textDecoration: true }),
  )}
`

Typography.defaultProps = {
  maxWidth: `100%`,
  backgroundColor: `transparent !important`,
}

export { Typography }
