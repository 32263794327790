import React, { useState } from 'react'
import { Container, Modal, Typography, Link, Input } from 'components'
import API from 'config/apiAgenda'
import { useCorporation } from 'context'
import { useHistory } from 'react-router-dom'
import message from 'antd/es/message'
import { isValid, formatRut } from '../utils'

function formatReservations({ rawAppointments, rawReservations }) {
  const { reservations, reservationsById } = rawReservations
  const { appointments, appointmentsById } = rawAppointments
  const items = [...appointments, ...reservations]
  const itemsById = {}
  appointments.forEach(appointmentId => {
    const {
      reservationStart,
      scheduleUser: { user, scheduleUnit },
      patientFirstName,
      patientLastName,
      patientMail,
      appointmentStatusId,
    } = appointmentsById[appointmentId]
    itemsById[appointmentId] = {
      reservationDate: reservationStart,
      address: `${scheduleUnit.division.name} ${scheduleUnit.division.address}`,
      attention: `${scheduleUnit.name} | ${user.firstName} ${user.lastName}`,
      patientFirstName,
      patientLastName,
      patientMail,
      status: appointmentStatusId,
      isReservation: false,
    }
  })
  reservations.forEach(reservationId => {
    const {
      line: {
        name: lineName,
        unit: {
          name: unitName,
          division: { name: divisionName, address },
        },
      },
      patientFirstName,
      patientLastName,
      patientMail,
      reservationDate,
      status,
    } = reservationsById[reservationId]
    itemsById[reservationId] = {
      reservationDate,
      address: `${divisionName} ${address}`,
      attention: `${unitName} | ${lineName}`,
      patientFirstName,
      patientLastName,
      patientMail,
      status,
      isReservation: true,
    }
  })
  return {
    appointments: items,
    appointmentsById: itemsById,
  }
}

function SearchByRut({ isVisible, onClose }) {
  const history = useHistory()
  const [rut, setRut] = useState(``)
  const { id: corporationId } = useCorporation()

  async function onConfirm() {
    if (rut) {
      try {
        const rawAppointments = await API.findAppointment({
          patientRut: rut,
          corporationId,
        })
        const rawReservations = await API.findReservations({
          rut,
          corporationId,
        })
        const data = formatReservations({
          rawAppointments,
          rawReservations,
        })
        history.push({
          pathname: `/atenciones`,
          state: { data, rut },
        })
      } catch (e) {
        const error = typeof e == `string` ? e : `No se encontraron antenciones`
        message.error(error)
      }
    }
  }

  const canSubmit = isValid(rut)
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      width="80%"
      color="primary.0"
    >
      <Container flexDirection="column" padding={{ sm: 0, md: `5%` }}>
        <Container
          width="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            color="primary.0"
            width="100%"
            fontSize={{ _: 5, lg: 8, sm: 3 }}
            fontWeight="bold"
            margin={{ _: 0, sm: `1rem 0` }}
          >
            Buscador de atenciones:
          </Typography>
          <Container width="100%" margin={{ md: `2rem 0`, sm: `0` }}>
            <Typography fontSize="3" color="grey.0" marginBottom="1">
              Ingrese su rut:
            </Typography>
            <Input
              name="rut"
              value={rut}
              width="100%"
              padding="1.3rem"
              borderRadius="5px"
              fontSize="2"
              margin="1% 0"
              onChange={value => setRut(formatRut(value))}
              marginBottom="1"
            />
            <Typography
              color="primary.2"
              fontSize="0"
              fontStyle="italic"
              marginBottom="2rem"
            >
              * Escriba rut con el que agendó hora
            </Typography>
          </Container>
          <Link
            padding={3}
            borderRadius="5px"
            width={{ _: `90%`, lg: `50%` }}
            onClick={onConfirm}
            disabled={!canSubmit}
            backgroundColor={canSubmit ? `primary.2` : `primary.2@0.5`}
            hoverProps={canSubmit ? { backgroundColor: `secundary.0` } : null}
            withShadow={canSubmit}
          >
            <Typography
              fontSize="3"
              color={canSubmit ? `white` : `grey.4`}
              textDecoration="underline"
            >
              Aceptar y Buscar
            </Typography>
          </Link>
        </Container>
      </Container>
    </Modal>
  )
}

export default SearchByRut
