import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { Container, Typography, Icon, Form, Button } from 'components'
import HourPicker from 'components/HourPicker'
import API from 'config/apiAgenda'
import { useStateWithMerge, useQuery } from 'hooks'
import { useAlert, useCorporation } from 'context'
import { message } from 'antd'
import { validationByCoporation } from 'utils'

const hourButtonStyles = {
  active: {
    color: `secundary.1`,
    backgroundColor: `white`,
    border: `1px solid`,
    borderColor: `secundary.1`,
  },
  disabled: {
    backgroundColor: `grey.3`,
    color: `grey.1`,
    border: `1px solid`,
    borderColor: `white`,
    cursor: `not-allowed`,
  },
  selected: {
    backgroundColor: `secundary.2`,
    color: `white`,
    border: `1px solid`,
    borderColor: `transparent`,
    withShadow: true,
  },
}

function mapDataForPicker({ appointments, appointmentsById }) {
  return appointments.map(appointment => {
    const { id, reservationDate: hour, statusCount } = appointmentsById[
      appointment
    ]

    const active = statusCount.available > 0

    return {
      id,
      hour,
      statusCount,
      active,
      styles: active ? hourButtonStyles.active : hourButtonStyles.disabled,
    }
  })
}

function AppointmentPicker({
  scheduleUnitId,
  scheduleUnit,
  scheduleUser,
  groupByProfessional,
  patient = {},
  isLoading,
}) {
  const [state, setState] = useStateWithMerge({
    appointments: [],
    appointmentsById: [],
    nextAvailableDate: null,
  })
  const query = useQuery()
  const { appointments, appointmentsById, nextAvailableDate } = state
  useEffect(() => {
    async function getInitialData() {
      const payload = {
        date: moment().format(`YYYY-MM-DD`),
        scheduleUnitId,
        onlyPublic: true,
        filter: query.get(`filter`),
      }
      if (scheduleUser) {
        payload.scheduleUserId = scheduleUser.id
      }

      const { appointment } = await API.getNextAvailable(payload)

      setState({
        nextAvailableDate: appointment
          ? moment(appointment.reservationStart)
          : null,
      })
    }
    getInitialData()
  }, [scheduleUser])

  function getPath(selectedAppointment) {
    // El picker le pasa en selectedAppointment el id de appointment
    // Se busca la appointment que tenga ese id para generar el pathname
    // con la hora de reserva
    const reservationDate = appointments.find(
      appointmentDate =>
        appointmentsById[appointmentDate].id === selectedAppointment,
    )
    return {
      pathname: `/agenda/${scheduleUnitId}/agendar/${reservationDate}`,
      state: {
        scheduleUnitId,
        scheduleUnit,
        reservationDate,
        scheduleUser: groupByProfessional ? scheduleUser : null,
        groupByProfessional,
        patient,
      },
    }
  }

  function getScheduleAppointments({ date = moment(), isForward = true }) {
    return new Promise(async (resolve, reject) => {
      try {
        let nextAvailableAppointment

        if (isForward) {
          const payload = {
            date: moment(date)
              .add(1, `days`)
              .format(`YYYY-MM-DD`),
            scheduleUnitId,
            onlyPublic: true,
            filter: query.get(`filter`),
          }

          if (scheduleUser) {
            payload.scheduleUserId = scheduleUser.id
          }

          nextAvailableAppointment = await API.getNextAvailable(payload)
        }

        const nextDate = isForward
          ? nextAvailableAppointment.appointment.reservationStart
          : date
        const {
          appointments = [],
          appointmentsById = {},
        } = await API.getAppointments({
          scheduleUnitId,
          date: moment(nextDate).format(`YYYY-MM-DD`),
          onlyPublic: true,
          appointmentStatuses: [1, 3, 8], // disponible, agendada, confirmada
          groupHours: true,
          scheduleUserId: scheduleUser ? scheduleUser.id : null,
        })

        const payload = {
          nextAvailableDate: moment(nextDate),
          appointments,
          appointmentsById,
        }

        setState(payload)
        resolve(payload)
      } catch (e) {
        const error = `Lo sentimos, no hay horas disponibles para agendar.`
        message.error(error)
      }
    })
  }

  const hours = mapDataForPicker({ appointments, appointmentsById })

  return nextAvailableDate != null ? (
    <HourPicker
      hours={hours}
      isLoading={isLoading}
      getPath={getPath}
      nextAvailableDate={nextAvailableDate}
      request={getScheduleAppointments}
      dependencies={[scheduleUser]}
      styles={hourButtonStyles}
    />
  ) : (
    <Typography>Lo sentimos, no hay horas disponibles</Typography>
  )
}

function ProfessionalSchedules({ selectProfessional, scheduleUnitId }) {
  const [state, setState] = useStateWithMerge({
    scheduleUsers: [],
    scheduleUsersById: {},
  })

  useEffect(() => {
    async function getScheduleUsers() {
      const { scheduleUsers, scheduleUsersById } = await API.getProfessionals(
        scheduleUnitId,
      )
      setState({ scheduleUsers, scheduleUsersById })
    }

    getScheduleUsers()
  }, [])

  const { scheduleUsers, scheduleUsersById } = state

  return (
    <Container width="50%" flexDirection="column" alignItems="center">
      {scheduleUsers.map(id => {
        const scheduleUser = scheduleUsersById[id]
        const professionalName = `${scheduleUser.user.firstName} ${scheduleUser.user.lastName}`
        return (
          <Button
            key={scheduleUser.user.id}
            name={scheduleUser.user.id}
            onClick={() => selectProfessional(scheduleUser)}
            width={[`100%`, `100%`, `80%`, `80%`]}
            padding={[`3`, `3`, `3`, `3`]}
            withShadow
            backgroundColor="primary.2"
            borderRadius="5px"
            marginBottom={[`3`, `3`, `3`, `3`]}
            hoverProps={{ backgroundColor: `primary.2@0.9` }}
          >
            <Typography
              fontSize={[`3`, `3`, `5`, `5`]}
              color="white"
              textDecoration="none"
            >
              {professionalName}
            </Typography>
          </Button>
        )
      })}
    </Container>
  )
}

function PatientValidation({ onConfirm, isLoading }) {
  const formSchema = {
    fields: [`rut`],
    rut: {
      title: `Rut`,
      name: `rut`,
      type: `text`,
      label: `Rut`,
      isActive: true,
      isRequired: true,
      error: `Ingrese un rut válido`,
      placeholder: `Ejemplo: 11.111.111-1`,
    },
  }

  return isLoading ? (
    <Container
      width="100%"
      height="20vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        marginBottom="1"
        icon="loader"
        color="primary.0"
        fontSize="10"
        spin
      />
      <Typography color="primary.0">Cargando...</Typography>
    </Container>
  ) : (
    <Container
      width="100%"
      alignContent="flex-start"
      height="100vh"
      paddingX={4}
    >
      <Typography
        width="100%"
        color="primary.0"
        fontSize={{ _: 5, lg: 8 }}
        fontWeight="bold"
        marginY={{ _: 3, lg: 4 }}
      >
        Validación de vecino:
      </Typography>
      <Container flexDirection="column" width="100%">
        <Container
          flexDirection="column"
          width={{ _: `90%`, lg: `50%` }}
          margin="0 auto"
        >
          <Form
            formSchema={formSchema}
            onConfirm={values => onConfirm(values.rut)}
            okText="Validar"
            warningText="(*) Debes ingresar digito verificador"
          />
        </Container>
      </Container>
    </Container>
  )
}

const initialState = ({ state }) => {
  const scheduleUnitId = state ? state.scheduleUnitId : {}
  return {
    isLoading: true,
    error: null,
    scheduleUnitId,
    patient: null,
    publicConfig: {},
    patientValidationType: null,
    isValidated: false,
    patientRut: ``,
    selectedProfessional: null,
  }
}

/*
    - Flujo

            - AGRUPADAS POR PROFESIONAL
               - MUESTRA OFERTA DE PROFESIONALES
                - MUESTRA VALIDACION
                  - VALIDE BIEN Y MUESTRE EL PICKER
                  - VALIDE MAL Y MUESTRE ERROR
                - MUESTRA EL PICKER (SIN VALIDAR)
            - SIN AGRUPAR POR PROFESIONAL
              - MUESTRA VALIDACION
                - VALIDE BIEN Y MUESTRE EL PICKER
                - VALIDE MAL Y MUESTRE ERROR
              - MUESTRA EL PICKER (SIN VALIDAR)
  */

function getMessage(corporationId) {
  switch (corporationId) {
    case 13:
      return `Lo sentimos, no hay horas disponibles en este momento. Le sugerimos intentar el día de mañana (Nuevos cupos se habilitan automáticamente cada día)`
    default:
      return `Lo sentimos, no hay horas disponibles en este momento.`
  }
}

function ScheduleAppointment({ match, location }) {
  const { setAlert } = useAlert()
  const { id: corporationId } = useCorporation()
  const [state, setState] = useStateWithMerge(initialState(location))
  const {
    isLoading,
    publicConfig,
    isValidated,
    selectedProfessional,
    patient,
    patientValidationType,
  } = state

  const scheduleUnitId = match.params.scheduleUnitId
  const scheduleUnit = location.state ? location.state.scheduleUnit : null

  async function validateNeighbor(rut) {
    try {
      setState({ isLoading: true })
      const patient = await validationByCoporation({
        corporationId,
        wholeRut: rut,
        unitId: scheduleUnitId,
        raw: true,
        isReservation: false,
      })
      setState({
        isLoading: false,
        isValidated: true,
        patient: {
          ...patient,
          patientRut: rut,
        },
      })
    } catch (error) {
      setState({ isLoading: false })
      message.error(error)
    }
  }

  function selectProfessional(selectedProfessional) {
    setState({ selectedProfessional })
  }

  function setLoading(isLoading) {
    setLoading({ isLoading })
  }

  useEffect(() => {
    async function getInitialData() {
      try {
        const { publicConfig, patientValidationType } = location.state
          ? location.state
          : await API.getScheduleUnit(scheduleUnitId)
        if (publicConfig) {
          setAlert(publicConfig.alert)
        }
        return setState({
          publicConfig,
          patientValidationType,
          isLoading: false,
          error: null,
        })
      } catch (e) {
        const error = typeof e == `string` ? e : getMessage(corporationId)
        return setState({
          isLoading: false,
          error,
        })
      }
    }

    getInitialData()
  }, [])
  return (
    <>
      <Container
        width="100%"
        flexDirection="column"
        alignItems="center"
        paddingX={4}
      >
        <Typography
          width="100%"
          color="primary.0"
          fontWeight="bold"
          marginBottom={{ _: 3, lg: 4 }}
          textAlign="center"
          fontSize={[`2`, `3`, `4`, `4`]}
          marginTop="1rem"
          position="relative"
        >
          Programe su atención:
        </Typography>
        {publicConfig &&
        publicConfig.groupByProfessional &&
        !selectedProfessional ? (
          <ProfessionalSchedules
            scheduleUnitId={scheduleUnitId}
            selectProfessional={selectProfessional}
            setLoading={setLoading}
            isLoading={isLoading}
          />
        ) : !isValidated && patientValidationType !== 0 ? (
          <PatientValidation
            isLoading={isLoading}
            setLoading={setLoading}
            onConfirm={validateNeighbor}
          />
        ) : (
          <AppointmentPicker
            scheduleUnitId={scheduleUnitId}
            scheduleUnit={scheduleUnit}
            patient={patient}
            scheduleUser={selectedProfessional}
            groupByProfessional={
              publicConfig.groupByProfessional
                ? publicConfig.groupByProfessional
                : null
            }
            setLoading={setLoading}
            isLoading={isLoading}
          />
        )}
      </Container>
    </>
  )
}

export default ScheduleAppointment
