import React, { useState } from 'react'
import { Container, Typography, Button, Icon } from 'components'
import { format, validate, clean } from 'rut.js'
import Input from '.././components/Input'

function ReservationRequestByPatient({ patientRutQuery, isLoading }) {
  const [rut, setRut] = useState(``)
  function validateRut() {
    const cleanedRut = clean(rut)
    const isRutValid = validate(cleanedRut)
    return isRutValid
  }

  return (
    <Container
      width="100%"
      maxWidth="700px"
      flexDirection="column"
      alignItems="center"
    >
      <Input
        placeholder="Ingrese su RUT"
        color="primary.0"
        name="Rut"
        label="RUT"
        width="100%"
        padding="0.5em 1em"
        fontSize={[`1.1rem`, `1.1rem`, `1.5rem`, `1.5rem`, `1.5rem`]}
        fontWeight="500"
        backgroundColor="grey.4"
        borderColor="grey.2"
        borderRadius="5px"
        marginBottom="0.5em"
        value={rut ? format(rut) : ``}
        onChange={rut => setRut(rut)}
      />
      <Button
        width={{ _: `100%`, md: `50%` }}
        margin="0 auto"
        marginY="3"
        padding="3"
        withShadow
        backgroundColor={validateRut(rut) ? `primary.2` : `grey.1`}
        onClick={() => patientRutQuery(rut)}
        fontSize="4"
        hoverProps={
          validateRut(rut) ? { backgroundColor: `primary.2@0.9` } : null
        }
        disabled={!validateRut(rut)}
      >
        {isLoading ? (
          <Icon icon="loader" spin margin="0 auto" fontSize="8" />
        ) : (
          <Typography
            textDecoration="underline"
            color={rut ? `white` : `grey.3`}
            fontSize="4"
          >
            Siguiente
          </Typography>
        )}
      </Button>
    </Container>
  )
}

export default ReservationRequestByPatient
