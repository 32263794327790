import {
  faAt as at,
  faMapMarkerAlt as location,
  faBackspace as backspace,
  faBell as bell,
  faCalendar as calendar,
  faCaretDown as caretDown,
  faCaretLeft as caretLeft,
  faCaretRight as caretRight,
  faCaretUp as caretUp,
  faCheck as check,
  faCheckCircle as checkCircle,
  faChevronLeft as chevronLeft,
  faChevronRight as chevronRight,
  faCircle as circle,
  faClock as clock,
  faInfoCircle as info,
  faCircleNotch as loader,
  faPhone as phone,
  faPlusCircle as plusCircle,
  faSearch as search,
  faSpinner as spinner,
  faTimesCircle as timesCircle,
  faUser as user,
  faUsers as users,
  faWalking as walking,
  faSmileWink as wink,
  faAngleLeft as angleLeft,
  faExclamationTriangle as warning,
  faArrowUp as arrowUp,
  faTimes as close,
  faPrint as print,
  faExclamation as exclamation,
} from '@fortawesome/free-solid-svg-icons'

import {
  faThumbsUp as thumbsUp,
  faCircleCheck as regularCircleCheck,
  faClock as regularClock,
} from '@fortawesome/free-regular-svg-icons'

import {
  border,
  color,
  compose,
  position,
  space,
  typography,
} from 'styled-system'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const icons = {
  user,
  users,
  bell,
  clock,
  circle,
  spinner,
  phone,
  location,
  at,
  chevronLeft,
  chevronRight,
  plusCircle,
  caretDown,
  caretUp,
  caretLeft,
  caretRight,
  backspace,
  timesCircle,
  checkCircle,
  loader,
  search,
  calendar,
  info,
  wink,
  walking,
  check,
  angleLeft,
  warning,
  arrowUp,
  close,
  print,
  exclamation,
  thumbsUp,
  regularCircleCheck,
  regularClock,
}

const Icon = styled(FontAwesomeIcon)`
  ${compose(color, typography, space, position, border)}
  ${({ onClick = null }) => (onClick == null ? `` : `  cursor: pointer`)}
`

function IconWrapper({ icon, ...props }) {
  return <Icon icon={icons[icon]} {...props} />
}

export default IconWrapper
