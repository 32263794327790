import React from 'react'
import { Typography, Container, Icon, Link } from 'components'
import moment from 'moment-timezone'

function SelectReservation({
  onClose,
  reservations,
  reservationsById,
  lineId,
}) {
  function getPath(selectedReservation) {
    // El picker le pasa en selectedReservation el id de reserva
    // Se busca la reservation que tenga ese id para generar el pathname
    // con la hora de reserva
    return {
      pathname: `/fila/${lineId}/reserva/${selectedReservation}`,
    }
  }

  return (
    <Container
      onClose={true}
      position="absolute"
      backgroundColor="white"
      top="3vh"
      width="350px"
      withShadow
      maxHeight="400px"
      height="auto"
      flexDirection="column"
      zIndex="1000"
      paddingX="4"
      borderRadius="4px"
    >
      <Container justifyContent="space-between" width="100%" paddingY="4">
        <Typography fontSize="1">Seleccione una hora:</Typography>
        <Icon
          icon="close"
          fontSize="6"
          color="grey.0"
          onClick={() => onClose()}
        />
      </Container>
      <Container
        width="100%"
        alignItems="center"
        paddingX="50px"
        maxHeight="300px"
        justifyContent="space-evenly"
        marginBottom="3"
        overflowY="scroll"
      >
        {reservations.map(reservation => {
          return (
            <Link
              to={getPath(reservation)}
              key={reservationsById[reservation]}
              backgroundColor="primary.2"
              width="80px"
              borderRadius="4px"
              height="30px"
              marginBottom="2"
            >
              <Typography
                fontSize="2"
                color="white"
                textDecoration="none !important"
              >
                {moment(reservation).format(`HH:mm`)}
              </Typography>
            </Link>
          )
        })}
      </Container>
    </Container>
  )
}

export default SelectReservation
