/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react'
import { Container, Typography, Link } from 'components'

function SuccessScreen({ history, email }) {
  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems={{ md: `center` }}
      paddingX={4}
    >
      <Container
        width={{ _: `100%`, md: `35%` }}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Container width="100%" flexDirection="column">
          <Typography
            color="primary.2"
            fontSize="6"
            fontWeight="bold"
            fontStyle="italic"
            marginY="10px"
          >
            ¡Solicitud generada con exito!
          </Typography>
          <Typography
            color="primary.2"
            fontSize="4"
            fontStyle="italic"
            marginY="10px"
          >
            {`Información enviada al correo registrado: ${email}. Revise periodicamente el correo, las actualizaciones del estado de su solicitud se informaran por esa vía.`}
          </Typography>
        </Container>
        <Link
          onClick={() => {
            history.push(`/`)
          }}
          color="primary.0"
          hoverProps={{ color: `secundary.0` }}
          fontSize={{ _: 1, lg: 4 }}
          fontStyle="italic"
          justifyContent="center"
          width="100%"
        >
          <Typography>Volver al menú principal</Typography>
        </Link>
      </Container>
    </Container>
  )
}

export default SuccessScreen
