import React, { useState, useEffect } from 'react'
import HTMLParser from 'html-react-parser'
import { useAlert } from 'context'
import Container from './Container'
import RichTextWrapper from './RichTextWrapper'
import Icon from './Icon'

function Alert(props) {
  const { alert } = useAlert()
  const { text, color, isClosable, isActive } = alert
  const [visible, setVisible] = useState(true)
  if (visible && isActive) {
    return (
      <Container
        position="relative"
        padding="3"
        backgroundColor={color}
        width="100%"
        color="white"
        borderRadius="4px 4px 0 0"
        withShadow
        {...props}
      >
        {isClosable && (
          <Icon
            onClick={() => setVisible(false)}
            position="absolute"
            top="1"
            right="1"
            icon="timesCircle"
            color="white"
          />
        )}
        <RichTextWrapper>{HTMLParser(text)}</RichTextWrapper>
      </Container>
    )
  }
  return null
}

export default Alert
