import React from 'react'
import { Container, Typography, Icon, Button } from 'components'
import API from 'config/apiAgenda'
import { capitalizeFirstLetter } from 'utils'
import notification from 'antd/es/notification'
// eslint-disable-next-line max-lines-per-function
function ListOfReservations({ reservations, isLoading, setState }) {
  async function generateTicket({ reservationId }) {
    setState({ isLoading: true })
    try {
      const ticket = await API.generateTicketWithRut({
        reservationId,
      })
      if (!ticket) {
        notification.error({
          message: `Hubo un error al generar ticket.`,
        })
      } else {
        setState({ ticket })
      }
    } catch (e) {
      const error = typeof e == `string` ? e : `Ha ocurrido un error, reintente`
      notification.error({ message: error })
      setState({ isLoading: false })
    } finally {
      setState({ isLoading: false })
    }
  }

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingX={4}
      paddingY={8}
    >
      <Typography fontWeight="bold" fontSize="5" color="primary.1">
        Validar hora agendada
      </Typography>
      <Typography paddingBottom="4" fontSize="5" color="primary.1">
        Confirme sus datos
      </Typography>

      {reservations.map(reservation => {
        const {
          line: { name: lineName },
          reservationDate,
          reservationHour,
          showValidateReservation,
          defaultEarlyMinutes,
          id: reservationId,
        } = reservation

        return (
          <Container
            borderRadius={6}
            key={reservation}
            withShadow
            marginBottom="4"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            paddingBottom="3"
            paddingTop="4"
            paddingX="3"
            width="50vh"
            isLoading={isLoading}
          >
            <Typography
              paddingBottom="4"
              fontWeight="bold"
              fontSize="5"
              color="primary.1"
            >
              {lineName}
            </Typography>
            <Container
              flexDirection="row"
              justifyContent="space-around"
              width="100%"
              paddingBottom="3"
            >
              <Container
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Icon icon="calendar" color="primary.1" fontSize="5" />
                <Typography marginLeft="2">
                  {capitalizeFirstLetter(reservationDate)}
                </Typography>
              </Container>
              <Container
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Icon icon="clock" color="primary.1" fontSize="5" />
                <Typography marginLeft="2">{reservationHour}</Typography>
              </Container>
            </Container>
            {showValidateReservation ? (
              <Button
                width="100%"
                backgroundColor="primary.1"
                borderBottom="5px solid"
                hoverProps={{ backgroundColor: `primary.2@0.9` }}
                padding="3"
                borderRadius={6}
                withShadow
                disabled={isLoading}
                fontSize="4"
                onClick={() =>
                  generateTicket({
                    reservationId,
                  })
                }
              >
                Validar hora
              </Button>
            ) : null}
            <Typography
              marginTop="2"
              textAlign="center"
              color={showValidateReservation ? `` : `red`}
            >
              {showValidateReservation
                ? `Al validar se emitirá tu número de atención`
                : `Esta hora se podrá validar desde ${defaultEarlyMinutes} minutos antes de la cita`}
            </Typography>
          </Container>
        )
      })}
    </Container>
  )
}

export default ListOfReservations
