/* eslint-disable no-console */
import React, { useContext, useState, useEffect } from 'react'
import FacebookMessenger from 'react-messenger-customer-chat'
import API from 'config/apiAgenda'
import { Container } from 'components'
import { ThemeProvider } from './ThemeContext'

const CorporationContext = React.createContext()

const initialState = {
  id: null,
}

function CorporationProvider({ children }) {
  const [corporation, setCorporation] = useState(initialState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getCorporation() {
      try {
        const corporation = await API.getCorporation(window.location.host)
        document.title = `${corporation.name} - Portal de atención a Público`
        setCorporation(corporation)
        setLoading(false)
      } catch (e) {
        //Ver acá cual es el comportamiento para cuando no encuentra corporation
        console.log(e)
      }
    }
    getCorporation()
  }, [])
  return (
    <CorporationContext.Provider value={corporation}>
      {loading ? (
        <Container
          isLoading={loading}
          loaderColor="#005483"
          loaderSize="60px"
          height={loading ? `100vh` : null}
        />
      ) : (
        <ThemeProvider corporationTheme={corporation}>
          {children}
          {corporation.facebookPageId ? (
            <FacebookMessenger
              pageId={corporation.facebookPageId}
              htmlref={window.location.pathname}
              language="es_LA"
            />
          ) : null}
        </ThemeProvider>
      )}
    </CorporationContext.Provider>
  )
}

function useCorporation() {
  const context = useContext(CorporationContext)
  if (!context) {
    throw new Error(`useCorporation must be used within a CorporationProvider`)
  }
  return context
}

export { CorporationProvider, useCorporation }
