import { useLocation, useHistory } from 'react-router-dom'
import { navigateToUrl } from 'utils'

function useBack() {
  const location = useLocation()
  const history = useHistory()

  function goBack() {
    if (location.state && location.state.from && !location.state.isFromUrl) {
      const { from } = location.state
      history.replace({
        pathname: from,
        state: true,
      })
    } else if (history.length > 2) {
      history.goBack()
    } else {
      navigateToUrl(`/`)
    }
  }
  return goBack
}

export default useBack
