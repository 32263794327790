import React from 'react'
import { Container, Typography, Input, Icon, Button } from 'components'
import { Upload, message } from 'antd'
import API from 'config/apiAgenda'
const { Dragger } = Upload

const SUPPORTED_FILES = [
  `.png`,
  `.jpeg`,
  `.jpg`,
  `image/png`,
  `image/jpeg`,
  `image/jpg`,
]

function AttachRecipe({
  prescriptions,
  validPatientId,
  withoutPrescription,
  setPatientField,
  setState,
  hasPrescriptions,
}) {
  function validateFileType(file) {
    const isImage = SUPPORTED_FILES.includes(file.type)
    if (!isImage) {
      message.error(
        `Formato de archivo ${file.type} no permitido, Por favor, adjunte una imagen.`,
      )
    }
    return isImage
  }

  function onDraggerChange({ info, index }) {
    const { status, name, response } = info.file
    if (status === `done`) {
      const nextPrescriptions = [...prescriptions]
      nextPrescriptions[index] = {
        ...nextPrescriptions[index],
        imageUrl: response.data,
        isUploaded: true,
      }
      setState({
        isLoading: false,
        prescriptions: nextPrescriptions,
      })
      message.success(`${name} subido correctamente`)
    }
    if (status === `error`) {
      setState({ isLoading: false })
      message.error(`No se ha podido subir la receta, reintente`)
    }
    if (status === `uploading`) {
      setState({ isLoading: true })
    }
  }
  function addPrescription() {
    setState({ prescriptions: [...prescriptions, { imageUrl: null }] })
  }

  function removePrescription(index) {
    const nextPrescriptions = [...prescriptions]
    nextPrescriptions.splice(index, 1)
    setState({ prescriptions: nextPrescriptions })
  }

  return (
    <Container
      marginTop="2"
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
    >
      {!hasPrescriptions ? (
        <Input
          margin="10px 0"
          name="withoutPrescription"
          type="checkbox"
          label="Enviar sin receta adjunta:"
          value={withoutPrescription}
          onChange={setPatientField}
        />
      ) : null}
      <Container
        display="grid"
        width="100%"
        gridTemplateColumns="repeat(auto-fit, minmax(20rem, 1fr))"
        gridGap="2"
        marginBottom="3"
      >
        {prescriptions.map((prescription, index) => (
          <Prescription
            key={index}
            index={index}
            {...prescription}
            onDraggerChange={onDraggerChange}
            validPatientId={validPatientId}
            removePrescription={removePrescription}
            prescriptionsLength={prescriptions.length}
            hasPrescriptions={hasPrescriptions}
            beforeUpload={validateFileType}
          />
        ))}
        {!hasPrescriptions ? (
          <Button
            backgroundColor="transparent"
            color="primary.2"
            onClick={addPrescription}
            marginTop="5"
            borderRadius="3px"
            width="100%"
          >
            <Typography textDecoration="underline" alignSelf="flex-start">
              Añadir receta
            </Typography>
          </Button>
        ) : null}
      </Container>
    </Container>
  )
}

function Prescription({
  hasPrescriptions,
  index,
  identifier,
  validPatientId,
  onDraggerChange,
  removePrescription,
  prescriptionsLength,
  isUploaded,
  beforeUpload,
}) {
  return (
    <Container
      border="2px solid"
      borderColor="primary.2@0.8"
      borderRadius="10px"
      key={index}
      flexDirection="column"
      alignItems="center"
      minHeight="11rem"
      position="relative"
    >
      {isUploaded ? (
        <Container
          backgroundColor="success"
          width="4"
          height="4"
          position="absolute"
          padding="2"
          borderRadius="50%"
          right={-8}
          top={-8}
        >
          <Icon icon="arrowUp" color="white" />
        </Container>
      ) : null}
      <Container
        marginTop="3"
        marginBottom="2"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        <Typography color="primary.2" fontWeight="3" fontSize="3">
          ADJUNTE IMAGEN DE RECETA (.JPG, .JPEG, .PNG)
        </Typography>
        {identifier ? (
          <Typography
            color="primary.2"
            fontWeight="1"
            fontSize="2"
            marginTop="1"
          >
            {identifier}
          </Typography>
        ) : null}
      </Container>
      <Dragger
        width="100%"
        name="prescriptionImg"
        accept=".png, .jpeg, .jpg"
        multiple={false}
        disabled={isUploaded}
        action={API.uploadPrescription(validPatientId)}
        onChange={info => onDraggerChange({ info, index })}
        beforeUpload={beforeUpload}
      >
        <Icon icon="plusCircle" fontSize="6" color="gray.2" />
        <Typography>Haz click o arrastra la receta aqui</Typography>
      </Dragger>
      {prescriptionsLength > 1 && !hasPrescriptions ? (
        <Button
          marginY="1"
          flexDirection="column"
          color="primary.2"
          onClick={() => removePrescription(index)}
        >
          <Typography textDecoration="underline" width="100%">
            Eliminar esta receta
          </Typography>
        </Button>
      ) : null}
    </Container>
  )
}

export default AttachRecipe
