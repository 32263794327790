import React from 'react'
import { LOGO_SALVADOR as LOGO } from 'assets'
import { Container, Img } from 'components'
import { useTheme } from 'context'
function Header() {
  const {
    theme: { whiteLogoUrl },
  } = useTheme()
  return (
    <Container
      width="100%"
      background="0"
      alignItems="center"
      paddingY={0}
      paddingX="3%"
      height={{ _: `4rem`, md: `5rem` }}
      position="relative"
    >
      <Container
        width={{ _: `100%`, md: `100%` }}
        justifyContent="center"
        alignItems="center"
      >
        <Img
          maxHeight="4rem"
          src={whiteLogoUrl || LOGO}
          maxWidth={{ _: `80px`, md: `20%` }}
          paddingBottom={0}
          paddingTop={0}
          position="absolute"
          left={{ _: `5px`, md: `2rem` }}
        />
      </Container>
    </Container>
  )
}

export default Header
