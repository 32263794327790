import React from 'react'

import { Container, Typography, Divider, Button } from 'components'
import Question from './Question'
import CustomComment from './CustomComment'
import { dataByStatus } from './utils'
import SurveyMessage from './SurveyMessage'

const Survey = ({
  questions,
  answers,
  answerQuestion,
  customComment,
  surveyStatus,
  setCustomComment,
  showCustomQuestion,
  submitSurvey,
  npsSuccessMessagesConfig,
}) => {
  if (Object.keys(dataByStatus).includes(surveyStatus)) {
    return (
      <SurveyMessage
        surveyStatus={surveyStatus}
        npsSuccessMessagesConfig={npsSuccessMessagesConfig}
      />
    )
  }

  return (
    <>
      <Container padding="5">
        <Typography>
          Seleccione la opción que más represente su experiencia con nosotros,
          donde 1 es poco probable y 10 es altamente probable.
        </Typography>
        <Divider border="1px solid" borderColor="grey.7" margin="24px 0" />
        {questions.map((question, index) => (
          <Question
            key={`question-${index}`}
            question={question}
            index={index + 1}
            currentAnswer={answers[index + 1]}
            answerQuestion={answerQuestion}
          />
        ))}
        {showCustomQuestion && (
          <CustomComment
            customComment={customComment}
            setCustomComment={setCustomComment}
          />
        )}
      </Container>
      <Container width="100%" justifyContent="center" marginBottom="50px">
        <Button
          width="171px"
          height="47px"
          backgroundColor="tertiary.4"
          borderRadius="5px"
          onClick={submitSurvey}
        >
          <Typography fontSize="6" color="primary.3">
            Enviar
          </Typography>
        </Button>
      </Container>
    </>
  )
}

export default Survey
