/* eslint-disable max-lines-per-function */
import React from 'react'
import { useStateWithMerge } from 'hooks'
import { Container, Typography, Calendar, Icon } from 'components'
import { Avatar, notification } from 'antd'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import API from 'config/apiAgenda'
import ModalToSelectReservation from './ModalToSelectReservation'

const initialState = {
  isModalOpen: false,
  selectedDate: null,
  reservations: [],
  reservationsById: {},
}

function SpecialistCalendar({
  id,
  name,
  availableDays,
  profilePictureUrl,
  unit,
  reservationsVisibilityConfig,
}) {
  const [state, setState] = useStateWithMerge(initialState)
  const { isModalOpen, selectedDate, reservationsById, reservations } = state

  async function getReservations({ startTime, endTime }) {
    try {
      const {
        reservations = [],
        reservationsById = {},
      } = await API.getAvailableReservations({
        lineId: id,
        startTime,
        endTime,
        statuses: [1],
        groupHours: true,
        reservationsVisibilityConfig,
      })

      return setState({
        reservations,
        reservationsById,
      })
    } catch (e) {
      const error = typeof e == `string` ? e : `sin horas disponibles`
      return notification.error({ message: error })
    }
  }

  function onModalClose() {
    setState({ isModalOpen: false })
  }

  function onSelectedDate(newSelectedDate) {
    const startTime = moment(newSelectedDate)
      .startOf(`day`)
      .format()
    const endTime = moment(newSelectedDate)
      .endOf(`day`)
      .format()

    getReservations({ startTime, endTime, isForward: false })
    return setState({
      selectedDate: newSelectedDate,
      isModalOpen: true,
    })
  }

  function shouldDisableTile({ date }) {
    const formattedDate = moment(date).format(`YYYY-MM-DD`)
    return !availableDays.includes(formattedDate)
  }

  const activeStartDate = new Date(availableDays[0])

  return (
    <Container
      flexDirection={{ sm: `column`, md: `row`, lg: `row` }}
      width="100%"
      marginLeft={{ _: 3, sm: 1, lg: 8 }}
      marginRight={{ _: 3, sm: 1, lg: 8 }}
      borderTop={`1px solid #979797`}
      paddingX={{ sm: `2`, md: `8`, lg: `6` }}
    >
      <Container
        alignItems="center"
        width={{ sm: `100%`, md: `50%`, lg: `50%` }}
        paddingX="3"
        paddingY={{ sm: `3`, md: `3`, lg: `3` }}
      >
        <Avatar src={profilePictureUrl} icon={<Icon icon="user" />} size={75} />
        <Container flexDirection="column" paddingLeft="2em">
          <Typography
            fontSize={{ sm: `1`, md: `2`, lg: `2` }}
            fontWeight="semi-bold"
          >
            {name}
          </Typography>
        </Container>
      </Container>
      <Container
        width={{ sm: `100%`, md: `50%`, lg: `50%` }}
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        {availableDays.length > 0 ? (
          <>
            <Calendar
              defaultActiveStartDate={activeStartDate}
              hoverProps={{ backgroundColor: `primary.2@0.9` }}
              onChange={onSelectedDate}
              value={selectedDate}
              tileDisabled={shouldDisableTile}
              view="month"
            />
            <Container
              flexDirection="row"
              width="340px"
              justifyContent="space-between"
              paddingBottom="3"
            >
              <Container alignItems="center">
                <Container
                  width="2vh"
                  height="2vh"
                  borderRadius="50px"
                  backgroundColor="primary.2"
                />
                <Typography fontSize="0" marginLeft="1">
                  Horas disponibles
                </Typography>
              </Container>
              <Container alignItems="center">
                <Container
                  width="2vh"
                  height="2vh"
                  borderRadius="50px"
                  backgroundColor="grey.3"
                />
                <Typography fontSize="0" marginLeft="1">
                  Horas no disponibles
                </Typography>
              </Container>
            </Container>
            {isModalOpen ? (
              <ModalToSelectReservation
                lineId={id}
                reservations={reservations}
                reservationsById={reservationsById}
                isVisible={isModalOpen}
                onClose={onModalClose}
              />
            ) : null}
          </>
        ) : (
          <Container
            justifyContent="center"
            alignItems="center"
            paddingY={{ sm: `2`, md: `5`, lg: `5` }}
            width="100%"
          >
            <Typography
              color="primary.0"
              fontSize="1"
              fontWeight="bold"
              textAlign="center"
            >
              {unit?.publicConfig?.hoursMessage ||
                `No encontramos ninguna hora disponible actualmente. Por favor,
              intente más tarde.`}
            </Typography>
          </Container>
        )}
      </Container>
    </Container>
  )
}

export default SpecialistCalendar
