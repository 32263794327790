/* eslint-disable max-lines-per-function */
import React from 'react'
import moment from 'moment-timezone'
import message from 'antd/es/message'
import { Container, Typography, Button, Icon } from 'components'
import API from 'config/apiAgenda'

const reservationStatuses = {
  2: `Tomada`,
  3: `Confirmada`,
  4: `Cancelada`,
  5: `Ejecutada`,
}
const appointmentStatuses = {
  3: `Agendada`,
  4: `Atendida`,
  5: `Ausente`,
  6: `Arribada`,
  7: `Iniciada`,
}
function ReservationsValidator({ reservations, reservationsById, setState }) {
  async function executeReservation(reservation) {
    try {
      const { ticket } = await API.generateTicket({
        ...reservation,
        reservationId: reservation.id,
      })

      setState(prevState => {
        const nextReservationsById = { ...prevState.reservationsById }
        nextReservationsById[reservation.id] = {
          ...nextReservationsById[reservation.id],
          ticket,
          status: 5,
        }
        return {
          reservationsById: nextReservationsById,
          ticket: nextReservationsById[reservation.id],
        }
      })
    } catch (e) {
      const error = typeof e == `string` ? e : `No se pudo ejecutar la reserva`
      message.error(error)
    }
  }
  return reservations.map(reservationId => {
    const reservation = reservationsById[reservationId]
    const {
      reservationDate,
      address,
      attention,
      patientFirstName,
      patientLastName,
      patientMail,
      status,
      isReservation,
      rut,
      ticket = null,
    } = reservation
    return (
      <Container
        key={reservationId}
        width={{ _: 1, lg: `70%` }}
        border="1px solid"
        borderColor="primary.2"
        withShadow
        marginBottom={3}
        padding={5}
        borderRadius={5}
      >
        <Container flexDirection="column" flex="0.9">
          <Typography
            color="primary.2"
            fontWeight="bold"
            marginBottom={3}
            fontSize={{ _: 1, lg: 4 }}
          >
            <Icon icon="calendar" marginRight={1} />
            {moment(reservationDate).format(`LL`)}
          </Typography>
          <Typography
            color="primary.2"
            fontWeight="bold"
            fontSize={{ _: 1, lg: 4 }}
          >
            <Icon icon="clock" marginRight={1} />
            {moment(reservationDate).format(`LT`)}
            hrs.
          </Typography>
        </Container>
        <Container
          height="100%"
          width="2px"
          backgroundColor="primary.2"
          marginLeft={5}
          marginRight={5}
        ></Container>
        <Container flexDirection="column" flex="2">
          <Typography
            color="primary.2"
            fontWeight="bold"
            marginBottom={3}
            fontSize={{ _: 1, lg: 4 }}
          >
            {attention}
          </Typography>
          <Typography
            marginBottom={3}
            color="grey.0"
            fontWeight="100"
            fontSize={{ _: 1, lg: 4 }}
          >
            {address}
          </Typography>
          <Typography
            marginBottom={3}
            color="grey.0"
            fontWeight="100"
            fontSize={{ _: 1, lg: 4 }}
          >
            {`Hora agendada para: ${patientFirstName} ${patientLastName}, RUT: ${rut}`}
          </Typography>
          <Typography
            color="grey.0"
            fontWeight="100"
            fontSize={{ _: 1, lg: 4 }}
          >
            {patientMail === `tarjetasincorreo@lascondes.cl`
              ? null
              : `Registro enviado al correo: ${patientMail}`}
          </Typography>
          <Typography
            color="grey.0"
            fontWeight="100"
            fontSize={{ _: 1, lg: 4 }}
            marginY={3}
          >
            Estado de la agenda:{` `}
            {isReservation
              ? reservationStatuses[status]
              : appointmentStatuses[status]}
          </Typography>
        </Container>

        {[2, 3].includes(status) ? (
          <Button
            padding={3}
            borderRadius="5px"
            width="100%"
            backgroundColor={`primary.2`}
            hoverProps={{ backgroundColor: `secundary.0` }}
            onClick={() => executeReservation(reservation)}
            withShadow
          >
            <Typography fontSize="3" color={`white`} textDecoration="underline">
              Ejecutar reserva
            </Typography>
          </Button>
        ) : null}
        {[5].includes(status) && ticket != null ? (
          <Button
            padding={3}
            borderRadius="5px"
            width="100%"
            backgroundColor={`primary.2`}
            hoverProps={{ backgroundColor: `secundary.0` }}
            onClick={() => setState({ ticket: reservation })}
            withShadow
          >
            <Typography fontSize="3" color={`white`} textDecoration="underline">
              Ver ticket
            </Typography>
          </Button>
        ) : null}
      </Container>
    )
  })
}
export default ReservationsValidator
