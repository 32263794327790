import React, { useState, useEffect } from 'react'
import { Container, Typography, Icon } from 'components'
import { useParams } from 'react-router-dom'
import API from 'config/apiAgenda'
import { useQuery } from 'hooks'

const initialState = {
  isReservation: false,
  successfulCancellation: false,
  reservation: null,
  appointment: null,
  loading: true,
  error: null,
}

const appointmentStatuses = {
  confirmar: 8,
  cancelar: 1,
}

function Confirmation() {
  const [state, setState] = useState(initialState)
  const { operation, token } = useParams()
  const query = useQuery()
  const params = query.get(`reserva`)
  const appointmentStatusId = appointmentStatuses[operation]

  const {
    appointment,
    isReservation,
    reservation,
    successfulCancellation,
    loading,
    error,
  } = state

  useEffect(() => {
    async function cancelReservation() {
      try {
        const reservation = await API.cancelReservation({
          token,
          isCancellation: true,
        })
        setState({
          loading: false,
          isReservation: true,
          successfulCancellation: true,
          reservation,
        })
      } catch (e) {
        const error = typeof e === `string` ? e : `No existe`
        setState({
          loading: false,
          isReservation: true,
          error,
        })
      }
    }

    async function confirmAppointment() {
      try {
        const appointment = await API.confirmAppointment({
          token,
          appointmentStatusId,
        })
        setState({
          appointment,
          loading: false,
          error: null,
        })
      } catch (e) {
        const error = typeof e === `string` ? e : `No existe`
        setState({
          ...state,
          loading: false,
          error,
        })
      }
    }
    if (params) {
      cancelReservation()
    } else {
      confirmAppointment()
    }
  }, [])

  return (
    <Container width="100%" justifyContent="center">
      {loading ? (
        <Icon icon="spinner" color="primary.2" fontSize="9" spin />
      ) : (
        <Container padding="4" width="100%">
          <Container width="100%" justifyContent="center">
            <Container
              marginTop="6"
              width="60%"
              backgroundColor="primary.0@0.2"
              border="1px solid"
              borderColor="primary.0"
              borderRadius="6px"
              justifyContent="center"
              alignItems="center"
              padding="4"
              fontSize="4"
              color="primary.0"
            >
              {!isReservation ? (
                <AppointmentDetails
                  error={error}
                  appointmentStatusId={appointmentStatusId}
                  appointment={appointment}
                />
              ) : (
                <ReservationDetails
                  error={error}
                  reservation={reservation}
                  successfulCancellation={successfulCancellation}
                />
              )}
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  )
}

function AppointmentDetails({ appointment, error, appointmentStatusId }) {
  if (error) {
    return (
      <>
        <Icon icon="info" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La sesión ha expirado o ya se ha confirmado</Typography>
      </>
    )
  }
  if (appointmentStatusId === 1 && appointment) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha anulado correctamente</Typography>
      </>
    )
  }
  if (appointmentStatusId === 8 && appointment) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha confirmado correctamente</Typography>
      </>
    )
  }
  return null
}

function ReservationDetails({ reservation, successfulCancellation, error }) {
  if (error) {
    return (
      <>
        <Icon icon="info" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>{error}</Typography>
      </>
    )
  }
  if (reservation || successfulCancellation) {
    return (
      <>
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
        <Typography>La hora se ha anulado correctamente</Typography>
      </>
    )
  }
}

export default Confirmation
