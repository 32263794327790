export const questionCaptionColor = `grey.5`

export const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const defaultAnswer = `Sin Responder`

export const surveyStatusByError = {
  'Encuesta ya fue respondida': `done`,
  'No se encotró token de autenticación': `expired`,
}

export const getDefaultAnswersObject = (questions = []) => {
  const defaultAnswers = {}

  questions.forEach((_, index) => (defaultAnswers[index + 1] = defaultAnswer))

  return defaultAnswers
}

export const dataByStatus = {
  done: {
    icon: `regularCircleCheck`,
    iconColor: `#52C41A`,
    message: `Encuesta enviada`,
    secondMessage: `exitosamente`,
    thirdMessage: `¡Muchas gracias!`,
  },
  error: {
    icon: `exclamation`,
    iconColor: `#EF5350`,
    message: `Ha ocurrido un error al`,
    secondMessage: `cargar su encuesta.`,
  },
  expired: {
    message: `Estimado(a) paciente:`,
    secondMessage: `El tiempo de respuesta ha finalizado.`,
    thirdMessage: `¡Muchas gracias!`,
  },
}
