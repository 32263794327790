import { useEffect } from 'react'
import io from 'socket.io-client'

const url = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL.split(`//`)[1].slice(0, -7)}`
  : `http://localhost:4000`

function useIO({
  emitEvent = `socketConnection`,
  emitPayload,
  subscribeEvent,
  subscribeEventHandler,
  aditionalFunction = () => null,
}) {
  useEffect(() => {
    const socket = io.connect(url, {
      transports: [`websocket`],
    })
    socket.on(`connect`, () => {
      socket.emit(emitEvent, emitPayload)
    })

    socket.on(subscribeEvent, data => {
      subscribeEventHandler(data)
    })
    aditionalFunction()
    return () => socket.disconnect()
  }, [])
}

export default useIO
