import React from 'react'

import { Container, Typography, Icon, Anchor } from 'components'
import { dataByStatus } from './utils'

const SurveyMessage = ({ surveyStatus, npsSuccessMessagesConfig }) => {
  const {
    icon,
    iconColor,
    message,
    secondMessage,
    thirdMessage,
  } = dataByStatus[surveyStatus]

  const showSuccessInfo = surveyStatus === `done`
  const showExpiredInfo = surveyStatus === `expired`

  const corporationSuccessMessage =
    npsSuccessMessagesConfig?.corporationSuccessMessage || thirdMessage

  const corporationLink = npsSuccessMessagesConfig?.corporationLink

  return (
    <Container padding="6" justifyContent="center">
      {icon && (
        <Container
          width="100%"
          justifyContent="center"
          color={iconColor}
          marginBottom="4"
        >
          <Icon icon={icon} fontSize="50px" />
        </Container>
      )}
      <Typography fontSize="6" width="100%" textAlign="center">
        {message}
      </Typography>
      <Typography fontSize="6" width="100%" textAlign="center">
        {secondMessage}
      </Typography>
      {showExpiredInfo && thirdMessage && (
        <Typography
          fontSize="6"
          width={{ _: `100%`, md: `80%` }}
          textAlign="center"
          marginTop="4"
        >
          {thirdMessage}
        </Typography>
      )}
      {showSuccessInfo && corporationSuccessMessage && (
        <Typography
          fontSize="6"
          width={{ _: `100%`, md: `80%` }}
          textAlign="center"
          marginTop="4"
        >
          {corporationSuccessMessage}
        </Typography>
      )}
      {showSuccessInfo && corporationLink && (
        <Typography marginTop="4" width="100%" textAlign="center">
          <Anchor
            fontSize="3"
            color="primary.2"
            textDecoration="underline"
            href={`https://${corporationLink}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            marginTop="1"
          >
            {corporationLink}
          </Anchor>
        </Typography>
      )}
    </Container>
  )
}

export default SurveyMessage
