import React from 'react'
import styled from 'styled-components'
import Container from './Container'

const StyledRichText = styled(Container)`
  h1 {
    font-size: 24px !important;
    display: block;
    width: 100%;
  }
  h2 {
    font-size: 20px !important;
    display: block;
    width: 100%;
  }
  h3 {
    font-size: 18px !important;
    display: block;
    width: 100%;
  }
  strong {
    font-weight: 800 !important;
  }
  em {
    font-style: italic !important;
  }
  u {
    text-decoration: underline !important;
  }
  p {
    display: block;
    width: 100%;
  }
  word-wrap: break-word;
`

function RichTextWrapper({ children }) {
  return <StyledRichText>{children}</StyledRichText>
}

export default RichTextWrapper
