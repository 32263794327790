import React, { useEffect, useState } from 'react'
import { Container, Typography, Icon } from 'components'
import { useParams } from 'react-router-dom'
import API from 'config/apiAgenda'
import { notification } from 'antd'

const status = {
  CONFIRMED: `CONFIRMED`,
  REJECTED: `REJECTED`,
}

function Confirmation() {
  const [error, setError] = useState(null)
  const {
    response,
    scheduledAppointmentId,
    token,
    programProtocolId,
  } = useParams()

  async function mailResponse() {
    try {
      await API.receivedResponse({
        response: response == 1 ? status.CONFIRMED : status.REJECTED,
        scheduledAppointmentId,
        token,
        programProtocolId,
      })
      return Promise.resolve()
    } catch (error) {
      setError(error)
      return notification.error({ message: error })
    }
  }

  useEffect(() => {
    mailResponse()
  }, [])
  return (
    <Container width="100%" justifyContent="center">
      <Container padding="4" width="100%">
        <Container width="100%" justifyContent="center">
          <Container
            marginTop="6"
            width="100%"
            backgroundColor="primary.0@0.2"
            border="1px solid"
            borderColor="primary.0"
            borderRadius="6px"
            justifyContent="center"
            alignItems="center"
            padding="4"
            fontSize="4"
            color="primary.0"
            textAlign="center"
          >
            <AppointmentDetails response={response} error={error} />
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

function AppointmentDetails({ response, error }) {
  return (
    <>
      {error == null ? (
        <Icon icon="check" color="primary.0" fontSize="9" marginRight="2" />
      ) : null}
      <Typography>
        {error == null
          ? `La hora se ha ${
              response == 1 ? `confirmado` : `cancelado`
            } correctamente`
          : `No fue posible realizar la acción.`}
      </Typography>
    </>
  )
}

export default Confirmation
