import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {
  compose,
  background,
  space,
  layout,
  color,
  border,
  typography,
} from 'styled-system'
import styled from 'styled-components'

export const ReactCalendar = styled(Calendar)`
  & .react-calendar {
    border: none;
  }
`
const CalendarContainer = styled.div`
  & .react-calendar {
    width: 350px;
    height: auto;
    border: none;
    ${compose(background, layout, space, color, border, typography)}
    background-color: transparent !important;
  }
  & .react-calendar__month-view__days {
    width: 400px;
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 7px;
  }
  & .react-calendar__tile:disabled {
    background-color: transparent !important;
  }
  & .react-calendar button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 42px;
    height: 30px;
    border-radius: 4px;
  }
  & .react-calendar button:enabled {
    ${compose(background, layout, space, color, border, typography)}
    color: white;
    &:hover,
    &:focus {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3) !important;
    }
  }
  &
    .react-calendar__navigation__label__labelText
    .react-calendar__navigation__label__labelText--from {
    color: white;
  }
  & .react-calendar__navigation {
    margin: 0;
  }
  & .react-calendar__navigation button:enabled {
    background-color: transparent;
    color: black;
  }
  & .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: white !important;
  }
`

CalendarContainer.defaultProps = {
  backgroundColor: `primary.2`,
}

export { CalendarContainer }
