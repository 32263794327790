import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
import App from './App'
import { CorporationProvider, AlertProvider } from './context'
import 'moment/locale/es'
import 'antd/es/message/style/index.css'
import 'antd/es/upload/style/index.css'
import 'antd/es/notification/style/index.css'

Sentry.init({
  dsn: `https://4152f194e846401d9e9343e40e678b94@o381419.ingest.sentry.io/5277564`,
  environment: process.env.REACT_APP_SENTRY_ENV || `local`,
})
moment.tz.setDefault(`America/Santiago`)
ReactGA.initialize(`UA-161206615-1`)
ReactGA.pageview(window.location.pathname + window.location.search)
moment.locale(`es`)
ReactDOM.render(
  <CorporationProvider>
    <AlertProvider>
      <App />
    </AlertProvider>
  </CorporationProvider>,
  document.getElementById(`root`),
)
