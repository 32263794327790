import React from 'react'
import { Container, Typography, Button, Icon } from 'components'
import { useBack } from 'hooks'

function BackButton() {
  const goBack = useBack()
  return (
    <Container
      width="100%"
      backgroundColor="grey.4"
      height={{ _: `5vh`, md: `4vh` }}
      alignContent="center"
      alignItems="center"
      paddingLeft={{ _: 2, md: 5 }}
      fontStyle="italic"
      textDecoration="underline"
    >
      <Icon icon="angleLeft" fontSize={{ _: 0, md: 1 }} color="grey.0" />
      <Button backgroundColor="transparent" fontStyle="italic" onClick={goBack}>
        <Typography fontSize={{ _: `11px`, md: 2 }} color="grey.0">
          volver atrás
        </Typography>
      </Button>
    </Container>
  )
}

export default BackButton
