import React from 'react'
import moment from 'moment-timezone'
import { Container, Typography, Modal } from 'components'

function TicketModal({
  onClose,
  reservationDate,
  ticket,
  line: { name: lineName },
}) {
  const ticketIdentifier = `${ticket.identifier}${ticket.ticketNumber}`
  return (
    <Modal isVisible={true} onClose={onClose} width="80%" color="primary.0">
      <Container
        width="100%"
        padding="4"
        flexDirection="column"
        alignItems="center"
      >
        <Container
          width={{ _: `100%`, md: `35%` }}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="3"
        >
          <Container padding="2" width="100%" justifyContent="center">
            <Typography
              width="100%"
              textAlign="center"
              color="primary.2"
              fontSize="4"
              fontWeight="bold"
              paddingBottom="2"
            >
              {`NÚMERO DE ATENCIÓN`}
            </Typography>
            <Typography
              width="100%"
              textAlign="center"
              color="primary.2"
              fontSize="10"
              fontWeight="bold"
              paddingBottom="4"
            >
              {ticketIdentifier}
            </Typography>
            <Container
              flexDirection="column"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="grey.0"
              width="100%"
              paddingY="4"
            >
              <Container justifyContent="flex-start">
                <Typography fontWeight="bold" fontSize="1" marginRight="2">
                  {`Servicio:`}
                </Typography>

                <Typography fontSize="1">{`${lineName}`}</Typography>
              </Container>
              <Container justifyContent="flex-start">
                <Typography
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="1"
                  marginRight="2"
                >
                  Fecha:
                </Typography>

                <Typography>{moment().format(`LL`)}</Typography>
              </Container>
              <Container justifyContent="flex-start">
                <Typography
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="1"
                  marginRight="2"
                >
                  Fecha reserva:
                </Typography>
                <Typography>{moment(reservationDate).format(`LT`)}</Typography>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}

export default TicketModal
