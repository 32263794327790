import React from 'react'
import { Container } from 'components'
import { useStateWithMerge } from 'hooks'
import ReservationsValidator from './ReservationsValidator'
import ReservationsFinder from './ReservationsFinder'
import TicketModal from './TicketModal'

function getInitialState() {
  return {
    reservations: [],
    reservationsById: {},
    rut: ``,
    ticket: null,
  }
}

function ValidateReservation({
  match: {
    params: { unitId },
  },
}) {
  const [state, setState] = useStateWithMerge(getInitialState)

  return (
    <Container
      flexDirection="column"
      width="100%"
      alignItems="center"
      paddingY="1em"
    >
      {state.reservations.length > 0 ? (
        <ReservationsValidator {...state} setState={setState} />
      ) : (
        <ReservationsFinder {...state} setState={setState} unitId={unitId} />
      )}
      {state.ticket != null ? (
        <TicketModal
          {...state.ticket}
          onClose={() => setState({ ticket: null })}
        />
      ) : null}
    </Container>
  )
}

export default ValidateReservation
