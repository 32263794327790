/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useMemo } from 'react'
import { Container, Typography, Link, Button, Icon, Img } from 'components'
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import API from 'config/apiAgenda'
import { baseURL } from 'config/constants'
import { useStateWithMerge, useQuery } from 'hooks'
import { useCorporation, useTheme, useAlert } from 'context'
import { navigateToUrl } from 'utils'
import { LOGO_CLAVE_UNICA } from 'assets'
import SearchByRut from './SearchByRutModal'
const initialState = {
  isLoading: true,
  services: [],
  path: [],
  searchModal: false,
  isFromUrl: false,
  isUnit: false,
  unitId: false,
  hasSchedule: false,
  showOptions: false,
}

const cuButton = {
  logoSize: `24px`,
  bgColor: `#0F69C4`,
  bgHover: `#0C549C`,
}

function useQuery2() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function Option({ selectOption, texts, children, ...option }) {
  const {
    theme: {
      colors: { buttonText: buttonTextColor },
    },
  } = useTheme()

  const history = useHistory()
  const location = useLocation()

  const {
    id,
    unitId,
    name,
    hasDelivery,
    isSchedule,
    isPublic,
    isFromUrl,
    layoutForPublicScheduleId,
  } = option
  if (isPublic === false && !isFromUrl) {
    return null
  }

  function handleLayoutView() {
    let includesGroup = false
    let includesLine = false
    children.forEach(({ key }) => {
      if (key.includes(`line`)) {
        includesLine = true
      }
      if (key.includes(`group`)) {
        includesGroup = true
      }
    })

    if (option.hasDelivery) {
      selectOption()
      history.push(option.path)
    } else {
      const hasGroup = option.path.includes(`group`)
      const lineCategoryId = hasGroup ? option.path.split(`-`)[1] : 0
      if (includesGroup && includesLine) {
        selectOption()
        history.push(option.path)
      } else if (
        includesGroup &&
        !includesLine &&
        layoutForPublicScheduleId == 2
      ) {
        selectOption()
        history.push(option.path)
      } else if (!includesGroup && layoutForPublicScheduleId == 2) {
        const unit = unitId == null ? option.id : unitId
        return history.push({
          pathname: `/unidad/${unit}/${lineCategoryId}`,
        })
      } else {
        selectOption()
        return history.push(option.path)
      }
    }
  }

  if (children != null) {
    return (
      <Button
        onClick={() => handleLayoutView()}
        width={[`100%`, `100%`, `80%`, `80%`]}
        padding={[`3`, `3`, `3`, `3`]}
        withShadow
        backgroundColor="primary.2"
        borderRadius="5px"
        marginBottom={[`3`, `3`, `3`, `3`]}
        hoverProps={{ backgroundColor: `primary.2@0.9` }}
      >
        <Typography
          fontSize={[`3`, `3`, `5`, `5`]}
          color={buttonTextColor || `white`}
          textDecoration="none"
        >
          {name}
        </Typography>
      </Button>
    )
  }

  let goTo

  if (hasDelivery) {
    goTo = {
      pathname: `${
        option.validatePatient ? `/entrega` : `/despachar`
      }/${unitId}`,
      state: { unit: option, from: location.pathname, isFromUrl },
    }
  } else if (isSchedule) {
    goTo = {
      pathname: `/agenda/${unitId}/agendar`,
      state: {
        scheduleUnitId: unitId,
        scheduleUnit: option,
        publicConfig: option.publicConfig,
        patientValidationType: option.patientValidationType,
        from: location.pathname,
        isFromUrl,
      },
    }
  } else {
    let path = `/fila/${id}`

    if (!option.mobile) {
      path += `/reserva`
    }

    goTo = {
      pathname: path,
      state: { line: option, from: location.pathname, isFromUrl },
    }
  }

  return (
    <Link
      to={goTo}
      key={id}
      width={[`100%`, `100%`, `80%`, `80%`]}
      padding={[`3`, `3`, `3`, `3`]}
      withShadow
      backgroundColor="primary.2"
      borderRadius="5px"
      marginBottom={[`3`, `3`, `3`, `3`]}
      hoverProps={{ backgroundColor: `primary.2@0.9` }}
    >
      <Typography
        fontSize={[`2`, `3`, `5`, `5`]}
        color={buttonTextColor || `white`}
        textDecoration="none !important"
      >
        {hasDelivery && texts ? texts.requestDelivery : name}
      </Typography>
    </Link>
  )
}
function Options({
  options,
  texts,
  path,
  setState,
  isFromUrl,
  handleCUClick,
  hasCUIntegration,
}) {
  const [isLoading, setLoading] = useState(true)
  const { serviceKey } = useParams()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    let path = null
    options.forEach((option, index) => {
      const pathFound = findPath({ option, index, key: serviceKey })
      if (pathFound) {
        path = pathFound
      }
      if (option.hasSchedule) {
        setState({ hasSchedule: true })
      }
    })
    if (!isAvailable(serviceKey)) {
      return history.push(`/servicios`)
    }
    if (path) {
      setState({ path, isFromUrl: !location.state })
    }

    setLoading(false)
  }, [])

  const hasAvailable =
    isFromUrl || options.some(option => option.isPublic || option.hasDelivery)

  useEffect(() => {
    // Se verifica si la vista actual es una unidad
    const [service, unitId] = serviceKey.split(`-`)
    const isUnit = service === `unit`

    setState({
      isUnit,
      unitId: isUnit ? unitId : false,
      serviceKey,
    })
  }, [serviceKey])

  return (
    <Container
      isLoading={isLoading}
      width="100%"
      maxWidth="700px"
      margin="0"
      justifyContent="center"
    >
      {hasAvailable ? (
        options.map((option, index) => {
          return (
            <Container width="100%" alignItems="center" flexDirection="column">
              <Option
                {...option}
                path={option.key}
                key={option.key}
                texts={texts}
                isFromUrl={isFromUrl}
                serviceKey={serviceKey}
                selectOption={() => setState({ path: [...path, index] })}
              />
            </Container>
          )
        })
      ) : (
        <Typography>No hay servicios</Typography>
      )}
      {location.pathname === `/servicios/home` && hasCUIntegration === true ? (
        <Container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Typography
            color="primary.0"
            fontSize="2"
            boxSizing="borderbox"
            position="relative"
          >
            Actualización de datos:
          </Typography>
          <Button
            onClick={handleCUClick}
            width={[`100%`, `100%`, `80%`, `80%`]}
            padding="3"
            withShadow
            backgroundColor={cuButton.bgColor}
            marginBottom="3"
            hoverProps={{ backgroundColor: cuButton.bgHover }}
          >
            <Container alignItems="center" justifyContent="center">
              <Img
                width={cuButton.logoSize}
                height={cuButton.logoSize}
                src={LOGO_CLAVE_UNICA}
                marginRight="1"
              />
              <Typography fontSize="2" color="white" textDecoration="none">
                ClaveÚnica
              </Typography>
            </Container>
          </Button>
        </Container>
      ) : null}
    </Container>
  )
}

function Services() {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const params = query.get(`search`)
  const { url } = useRouteMatch()
  const [state, setState] = useStateWithMerge(initialState)
  const {
    id: corporationId,
    hasCUIntegration,
    publicConfig = {},
  } = useCorporation()
  const { fromUpdate } = history?.location?.state ?? {}

  const {
    theme: { texts },
  } = useTheme()
  const { setAlert } = useAlert()
  useEffect(() => {
    async function getLines() {
      try {
        const services = await API.getServices(corporationId)
        setState({ services, isLoading: false })
      } catch (e) {
        console.log(e)
      }
    }
    getLines()
    if (hasCUIntegration === false) {
      setState({ showOptions: true })
    }
  }, [])
  const {
    services,
    path,
    isLoading,
    searchModal,
    isFromUrl,
    isUnit,
    serviceKey,
    unitId,
    hasSchedule,
    showOptions,
  } = state

  const queryParams = useQuery2()

  const errorURL = queryParams.get(`error`)

  let alertProps = path.length === 0 && publicConfig ? publicConfig.alert : {}
  let hasValidateReservation = false
  const options = path.reduce((prev, current) => {
    hasValidateReservation = prev[current].hasValidateReservation
    alertProps = prev[current].publicConfig
      ? prev[current].publicConfig.alert
      : {}
    return prev[current].children
  }, services)

  useEffect(() => {
    setAlert(alertProps || {})
  }, [options])
  useEffect(() => {
    /*
     * Como el navegador de volver atrás está de forma global
     * es necesario verificar si la acción que se hace sobre la ruta
     * es volver atrás. En ese caso, se elimina una posición del path
     * para mostrar el menú correctamente */
    if (history.action === `POP`) {
      setState({ path: [...path].slice(0, -1) })
    }
  }, [location.pathname])
  useEffect(() => {
    if (fromUpdate) {
      setState({ showOptions: true })
    }
  }, [])

  function handleCUClick() {
    const formattedUrl = baseURL.replace(`/api/v1`, ``)
    window.location.href = `${formattedUrl}/login/claveunica?corporationId=${corporationId}`
  }

  return (
    <Container
      isLoading={isLoading}
      width="100%"
      padding={[`4`, `4`, `0`, `0`]}
      paddingTop="0 !important"
      alignItems={[`flex-start`, `flex-start`, `center`, `center`]}
      flexDirection="column"
    >
      <Typography color="primary.0" fontSize={{ _: 6, md: 8 }} marginTop="3">
        {texts ? texts.title : `Agenda en línea`}
      </Typography>

      {hasCUIntegration && !showOptions ? (
        <>
          <Typography
            color="primary.0"
            fontSize={[`2`, `3`, `4`, `4`]}
            fontWeight="bold"
            marginTop="4"
            boxSizing="borderbox"
            position="relative"
            marginBottom="2"
          >
            Actualiza tus datos de forma rapida
          </Typography>
          <Typography
            color="primary.0"
            fontSize="2"
            boxSizing="borderbox"
            position="relative"
          >
            Actualizar datos:
          </Typography>
          <Button
            onClick={handleCUClick}
            width={[`100%`, `100%`, `40%`, `40%`]}
            padding="3"
            withShadow
            backgroundColor={cuButton.bgColor}
            marginBottom="3"
            hoverProps={{ backgroundColor: cuButton.bgHover }}
          >
            <Container alignItems="center" justifyContent="center">
              <Img
                width={cuButton.logoSize}
                height={cuButton.logoSize}
                src={LOGO_CLAVE_UNICA}
                marginRight="1"
              />
              <Typography fontSize="2" color="white" textDecoration="none">
                ClaveÚnica
              </Typography>
            </Container>
          </Button>
          <Button
            onClick={() => setState({ showOptions: true })}
            width={[`100%`, `100%`, `40%`, `40%`]}
            padding="3"
            withShadow
            backgroundColor="white"
            border="1px solid"
            borderColor="primary.2"
            borderRadius="5px"
            marginBottom="3"
          >
            <Typography
              fontSize={[`3`, `3`, `5`, `5`]}
              color="primary.2"
              textDecoration="none"
            >
              Reserva de horas
            </Typography>
          </Button>
        </>
      ) : null}

      {showOptions ? (
        options.length > 0 ? (
          <>
            <Typography
              color="primary.0"
              fontSize={[`2`, `3`, `4`, `4`]}
              fontWeight="bold"
              marginTop="2"
              boxSizing="borderbox"
              position="relative"
              marginBottom={path.length > 0 ? `4` : `4`}
            >
              Seleccione una opción:
            </Typography>
            <Switch>
              <Route path={`${url}/:serviceKey`}>
                <Options
                  isFromUrl={isFromUrl}
                  path={path}
                  setState={setState}
                  texts={texts}
                  options={options}
                  handleCUClick={handleCUClick}
                  hasCUIntegration={hasCUIntegration}
                />
              </Route>
              {params ? null : <Redirect to={`${url}/home`} />}
            </Switch>
          </>
        ) : (
          <Container width="80%" margin="0 auto" justifyContent="center">
            <Typography
              color="primary.0"
              fontSize="9"
              fontWeight="bold"
              marginBottom="4"
              textAlign="center"
            >
              Lo sentimos, no encontramos ningun servicio disponible
              actualmente. Intente más tarde.
            </Typography>
            <Typography
              color="primary.0"
              fontSize="2"
              boxSizing="borderbox"
              position="relative"
            >
              Actualización de datos:
            </Typography>
            <Button
              onClick={handleCUClick}
              width={[`100%`, `100%`, `58%`, `58%`]}
              padding="3"
              withShadow
              backgroundColor={cuButton.bgColor}
              marginBottom="3"
              hoverProps={{ backgroundColor: cuButton.bgHover }}
            >
              <Container alignItems="center" justifyContent="center">
                <Img
                  width={cuButton.logoSize}
                  height={cuButton.logoSize}
                  src={LOGO_CLAVE_UNICA}
                  marginRight="1"
                />
                <Typography fontSize="2" color="white" textDecoration="none">
                  ClaveÚnica
                </Typography>
              </Container>
            </Button>
          </Container>
        )
      ) : null}
      {isUnit && hasSchedule && hasValidateReservation ? (
        <Container
          isLoading={isLoading}
          width="100%"
          maxWidth="700px"
          margin="0"
          justifyContent="center"
        >
          <Button
            width={[`100%`, `100%`, `80%`, `80%`]}
            padding={[`3`, `3`, `3`, `3`]}
            withShadow
            backgroundColor="#6BC9AA"
            borderRadius="5px"
            marginBottom={[`3`, `3`, `3`, `3`]}
            hoverProps={{ backgroundColor: `#8cceb8` }}
            onClick={() => navigateToUrl(`/reservas/${unitId}/obtener-ticket`)}
            fontSize={[`3`, `3`, `5`, `5`]}
            color={`white`}
            textDecoration="none"
          >
            Validar reserva
          </Button>
        </Container>
      ) : null}
      <Container justifyContent="center" paddingY={4} width="100%">
        <Link
          width="100%"
          hoverProps={{ color: `secundary.0` }}
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="search" fontSize="4" marginRight="1%" color="primary.0" />
          <Typography
            fontSize={{ _: 2, lg: 4 }}
            fontWeight="500"
            color="primary.0"
            textDecoration="underline"
            onClick={() => setState({ searchModal: true })}
          >
            Consulta sobre tu reserva realizada
          </Typography>
        </Link>
      </Container>
      {isUnit ? (
        <Button
          backgroundColor="tertiary.1"
          borderBottom="5px solid"
          borderColor="tertiary.2"
          width={{ _: `100%`, md: `30%`, lg: `20%` }}
          hoverProps={{ backgroundColor: `tertiary.1@0.9` }}
          padding="3"
          borderRadius={6}
          onClick={() => navigateToUrl(`${serviceKey}/visor`)}
          withShadow
        >
          <Typography fontSize={2} fontWeight="500" letterSpacing={0.5}>
            Ver las filas de espera
          </Typography>
        </Button>
      ) : null}
      <SearchByRut
        isVisible={params ? params : searchModal}
        onClose={() => setState({ searchModal: false })}
      />
    </Container>
  )
}

function findPath({ option, key, index }) {
  if (option.key === key) {
    return [index]
  } else if (option.children) {
    let path = null
    option.children.forEach((childrenOption, childrenIndex) => {
      const childrenPath = findPath({
        option: childrenOption,
        index: childrenIndex,
        key,
      })
      if (childrenPath) {
        path = [index, ...childrenPath]
      }
    })
    return path
  }
  return null
}
const unitsToRedirect = [103]
function isAvailable(serviceKey) {
  const [service, key] = serviceKey.split(`-`)
  if (service == `unit` && unitsToRedirect.includes(parseInt(key, 10))) {
    return false
  }
  return true
}

export default Services
